import React, { useState, useEffect } from 'react';

const DeclineReasonModal = ({ isOpen, onClose, id }) => {
  const [declineReason, setDeclineReason] = useState('');

  useEffect(() => {
    if (isOpen && id) {
      // Fetch the decline reason from the API endpoint
      fetch(`${process.env.REACT_APP_URL}/v1/content-head/get/decline/reason/${id}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setDeclineReason(data.reason);
        })
        .catch(error => {
          console.error('There was an error fetching the decline reason:', error);
        });
    }
  }, [isOpen, id]);

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Decline Reason</h2>
          <button
            className="text-gray-600 hover:text-gray-900"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <p>{declineReason}</p>
      </div>
    </div>
  );
};

export default DeclineReasonModal;
