import React, { useState, useEffect } from 'react'
import BrandImageAndIdInput from './BrandImageAndIdInput'
const BrandBanner = () => {
    useEffect(() => {
        document.title = 'Add Brand Banner'
        window.scrollTo(0, 0)
    }, [])



    return (
        <div className='mt-5'>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Hero Section Banner</h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='hero-section-banner'
                    // brandList={brandList}
                    />

                </div>
            </section>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Hero Mobile Banner</h1>
                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='hero-mobile-banner'
                    // brandList={brandList}
                    />

                </div>
            </section>
        </div>
    )
}

export default BrandBanner