// AddSellerModal.js
import React, { useState, useEffect } from "react";
import { getToken } from "../../hook/getToken";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddCWModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const token = getToken()
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      userName: "",
     
      password: "",
      confirmPassword: "",
      roles: "CW",
    });
    


const [designations, setDesignations] = useState([]);
// Function to handle form input changes
const handleInputChange = (e) => {
    const { name, value } = e.target;
  
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // Function to handle form submission
const handleSubmit = async (e) => {
    e.preventDefault();
   
    // Perform any validation or submit data to the server here
    console.log("Form Data Submitted:", formData);
  
      // Check if the username contains spaces
      if (formData.userName.includes(" ")) {
        toast.error("Username cannot contain spaces.");
        return; // Prevent further execution of the function
      }
  
    const response = await fetch(
      `${process.env.REACT_APP_URL}/v1/category-manager/signup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );
  
    const data = await response.json();
    if (response.ok) {
      navigate('/content-head-dashboard')
      toast.success(data.message);
      setFormData({
        name: "",
        email: "",
        phone : "",
        userName: "",
      
        password: "",
        confirmPassword:"",
        roles: "",
      });
     
    } else {
      toast.error(data.message);
    }
    // Reset the form after submission if needed
  
  };
  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <form className="" onSubmit={handleSubmit}>
            
            <h1 className="text-xl font-bold px-4 py-4">Add Content Writer</h1>
          <div className="grid grid-cols-1 gap-6">
            <div className="p-4">
            <div className=" flex flex-col">
              <label className="block text-sm font-medium text-gray-700">
                <span>Name:</span>
              </label>

              <input
                className=" mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700">
                <span>Email:</span>
              </label>
              <input
                className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex flex-col  ">
              <label className=" block text-sm font-medium text-gray-700">
                <span>Phone:</span>
              </label>
              <input
                className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                type="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="flex flex-col">
              <label className=" block text-sm font-medium text-gray-700">
                <span>Username:</span>
              </label>
              <input
                className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md "
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700">
                <span>Password:</span>
              </label>
              <input
                className=" mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md "
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700">
                <span>Confirm Password:</span>
              </label>
              <input
                className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
            </div>
            </div>
     <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default AddCWModal;
