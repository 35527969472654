import React, { useEffect, useState } from "react";
import { getToken } from "../../hook/getToken";
import Select from "react-select";
const CouponForm = (props) => {
  const { handleClose, id } = props;
  const [message, setMessage] = useState("");
  // Initialize state for form fields
  const [formData, setFormData] = useState({
    coupon_code: "",
    applicable_entity: "",
    applied_id: [],
    discount_type: "",
    discount: "",
    quantity_type: "",
    quantity: "",
    start_date: "",
    end_date: "",
    description: "",
    min_purchase: "",
  });

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (formData.quantity_type === "unlimited") {
      setFormData((preValue) => ({
        ...preValue,
        quantity: "",
      }));
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  

  async function AddCoupon(url) {
    console.log("url", url);
    const access_token = getToken();
    try {
      const requestBody = {
        coupon_code: formData.coupon_code.toUpperCase(),
        applicable_entity: formData.applicable_entity,
        applied_id: formData.applied_id,
        discount_type: formData.discount_type,
        discount: formData.discount,
        quantity_type: formData.quantity_type,
        quantity: formData.quantity,
        start_date: formData.start_date,
        end_date: formData.end_date,
        description: formData.description,
        min_purchase: formData.min_purchase,
      };

      if (requestBody.applicable_entity === "whole_website") {
        delete requestBody.applied_id;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(requestBody),
      };
      console.log(JSON.stringify(requestBody));

      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setMessage(data.message);
      console.log("response data", data);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the applicable entity
    if (
      formData.applicable_entity === "product" &&
      formData.applied_id.length === 0
    ) {
      setMessage("Please select at least one product.");
      return; // Prevent submission
    }

    if (
      formData.applicable_entity === "brand" &&
      formData.applied_id.length === 0
    ) {
      setMessage("Please select at least one brand.");
      return; // Prevent submission
    }

    if (id) {
      AddCoupon(
        `${process.env.REACT_APP_URL}/v1/coupon/update-coupon/admin/${id}`
      ).then((res) => {
        if (res.ok) {
          setMessage("");
          alert("Coupon Updated Successfully");
          handleClose();
        }
      });
    } else {
      AddCoupon(`${process.env.REACT_APP_URL}/v1/coupon/add-coupon/admin`).then(
        (res) => {
          if (res.ok) {
            setMessage("");
            alert("Coupon Added Successfully");
            handleClose();
          }
        }
      );
    }
    console.log(formData);
  };
  // geting select category list
  const [brandList, setBrandList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleProductSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevState) => ({
      ...prevState,
      applied_id: selectedValues, // Store selected product IDs
    }));
    setSelectedProducts(selectedOptions); // Store selected product objects
  };

  // Handle brand selection
  const handleBrandSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevState) => ({
      ...prevState,
      applied_id: selectedValues, // Store selected brand IDs
    }));
    setSelectedBrands(selectedOptions); // Store selected brand objects
  };

  async function fetchBrand() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/brand-registration/all-active-brand`
      );
      if (!response.ok) {
        throw new Error(response);
      }
      const data = await response.json();
      setBrandList(data.brands);
      console.log("Data:", data.brands);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const brandOptions = brandList.map((brand) => ({
    value: brand._id,
    label: brand.brand_name,
  }));

  async function fetchProduct() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/coupon/get-product-list`
      );
      if (!response.ok) {
        throw new Error(response);
      }
      const data = await response.json();
      setProductList(data.productList);
      console.log("Data:", data.productList);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const productOptions = productList.map((product) => ({
    value: product.session_id,
    label: product.item_name,
  }));

  useEffect(() => {
    if (formData.applicable_entity === "brand") {
      fetchBrand();
    } else if (formData.applicable_entity === "product") {
      fetchProduct();
    }
  }, [formData.applicable_entity]);

  useEffect(() => {
    if (id) {
      try {
        fetch(`${process.env.REACT_APP_URL}/v1/coupon/get-coupon/${id}`)
          .then((res) => res.json())
          .then((data) => {
            setFormData(data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log("An error occurred:", error);
      }
    }
  }, [id]);

  useEffect(() => {
    // Ensure formData.applicable_entity is either "product" or "brand", and the corresponding options exist
    if (
      id &&
      formData.applicable_entity === "product" &&
      productOptions.length > 0
    ) {
      // Ensure formData.applied_id is defined and is an array
      if (Array.isArray(formData.applied_id)) {
        const previouslySelected = productOptions.filter((item) =>
          formData.applied_id.includes(item.value)
        );
        setSelectedProducts(previouslySelected);
      } else {
        // Clear selected products if there's no applied_id (applicable_entity might be "whole_website")
        setSelectedProducts([]);
      }
    }

    if (formData.applicable_entity === "brand" && brandOptions.length > 0) {
      // Ensure formData.applied_id is defined and is an array
      if (Array.isArray(formData.applied_id)) {
        const previouslySelected = brandOptions.filter((item) =>
          formData.applied_id.includes(item.value)
        );
        setSelectedBrands(previouslySelected);
      } else {
        // Clear selected brands if there's no applied_id (applicable_entity might be "whole_website")
        setSelectedBrands([]);
      }
    }

    // If "whole_website" is selected, clear both selected products and brands
    if (formData.applicable_entity === "whole_website") {
      setSelectedProducts([]);
      setSelectedBrands([]);
    }
  }, [
    formData.applicable_entity,
    formData.applied_id,
    productOptions,
    brandOptions,
    id,
  ]);

  
  return (
    <div className="pr-6 py-5 text-xs">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              Code *
            </label>
            <input
              required
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
              type="text"
              name="coupon_code"
              value={formData.coupon_code}
              onChange={handleChange}
            />
          </div>
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              Allow Product Type *
            </label>

            <select
              required
              name="applicable_entity"
              value={formData.applicable_entity}
              onChange={handleChange}
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
            >
              <option value="">--Select--</option>
              <option value="product">Product</option>
              <option value="brand">Brand </option>
              <option value="whole_website">Whole website </option>
            </select>
          </div>
          {/* Conditional Rendering for brand or product */}
          {formData.applicable_entity ===
          "whole_website" ? null : formData.applicable_entity === "brand" ? (
            <div className="w-5/6 flex items-center gap-4 my-2">
              <label className="w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                Brand *
              </label>
              <Select
                isMulti
                options={brandOptions}
                onChange={handleBrandSelect}
                value={selectedBrands}
                className="grow"
              />
            </div>
          ) : (
            <div className="w-5/6 flex flex-col gap-4 my-2">
              <div className="flex items-center gap-4">
                <label className="w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                  Product *
                </label>
                <Select
                  isMulti
                  options={productOptions}
                  onChange={handleProductSelect}
                  value={selectedProducts}
                  className="grow"
                />
              </div>
            </div>
          )}

          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              Type *
            </label>
            <select
              required
              name="discount_type"
              value={formData.discount_type}
              onChange={handleChange}
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
            >
              <option value="">--Select--</option>
              <option value="amount">By Amount</option>
              <option value="percentage">By Percentage</option>
            </select>
          </div>
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              {`${
                formData.discount_type === "percentage"
                  ? "Percentage *"
                  : "Amount *"
              }`}{" "}
            </label>

            <input
              required
              className="py-1 px-3 border border-solid border-gray-200 rounded-md"
              type="text"
              name="discount"
              value={formData.discount}
              onChange={handleChange}
            />
            {`${formData.discount_type === "percentage" ? "%" : ""}`}
          </div>
          {/* // Minimum amount */}
          {formData.discount_type === "amount" ? (
            <div className=" w-5/6 flex items-center gap-4 my-2">
              <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                Minimum Purchase Amount{" "}
              </label>

              <input
                required
                className="py-1 px-3 border border-solid border-gray-200 rounded-md"
                type="text"
                name="min_purchase"
                value={formData.min_purchase}
                onChange={handleChange}
              />
            </div>
          ) : null}
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              Quantity *
            </label>
            <select
              required
              name="quantity_type"
              value={formData.quantity_type}
              onChange={handleChange}
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
            >
              <option value="">--Select--</option>
              <option value="limited">Limited</option>
              <option value="unlimited">Unlimited</option>
            </select>
          </div>
          {/* // dynamic field */}
          {formData.quantity_type === "limited" ? (
            <div className=" w-5/6 flex items-center gap-4 my-2">
              <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold "></label>
              <input
                required
                className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
                type="text"
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
              />
            </div>
          ) : null}
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              Start Date *
            </label>
            <input
              required
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
              type="date"
              name="start_date"
              value={formData.start_date}
              onChange={handleChange}
            />
          </div>
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              End Date *
            </label>
            <input
              required
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
              type="date"
              name="end_date"
              value={formData.end_date}
              onChange={handleChange}
            />
          </div>
          <div className=" w-5/6 flex items-center gap-4 my-2">
            <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
              Description *
            </label>
            <textarea
              className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
              required
              name="description"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="flex gap-x-4 justify-center items-center py-5">
          <button
            onClick={() => handleClose()}
            type="button"
            className="py-1 px-6 text-red-600 "
          >
            Cancel
          </button>
          <button type="Submit" className="py-1 px-14 bg-blue-900 text-white">
            Save
          </button>
        </div>
        {message ? <p className="text-red-500">{message}</p> : null}
      </form>
    </div>
  );
};

export default CouponForm;
