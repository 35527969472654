'use client'
import React from 'react'
import { useCms } from '../../context/CmsProvider'
const UpArrow = ({ index }) => {
    const { moveUp } = useCms()
    const handleUp = () => {
        moveUp(index)
    }
    return (
        <button
            type='button'
            onClick={() => handleUp()}
            disabled={index === 0}
            className='border p-2'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
            </svg>

        </button>
    )
}

export default UpArrow