// BrandTableRow.js

import React from "react";

const BrandCard = ({ brand }) => {
  return (
    <div className="flex flex-col border border-gray-300 rounded p-4 mb-4">
      <img
        src={brand.brand_logo_url}
        alt={brand.brand_name}
        className="h-16 self-center mb-2" // Adjust image dimensions here
      />
      <div className="text-center">
        <p className="font-semibold">Seller:{brand.seller.fullname}</p>
        <p>CM:{brand.cm_data.name}</p>
      </div>
    </div>
  );
};

const BrandTableRow = ({ paginatedBrandData }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
      {paginatedBrandData.map((brand, index) => (
        <BrandCard key={brand._id} brand={brand} />
      ))}
    </div>
  );
};

export default BrandTableRow;
