import React, { useState } from 'react'
import AdditionalCouponForm from './AdditionalCouponForm'
import AdditionalCouponList from './AdditionalCouponList'
import { Link } from 'react-router-dom'
import { User } from '../../user/User'
const AdditionalCoupon = () => {
    const [isClicked, setIsClicked] = useState(false)
    const [couponId, setCouponId] = useState('')
    const handleNewClick = (id) => {
        console.log(id);
        setCouponId(id)
        setIsClicked(true)
    }
    const handleClose = () => {
        setIsClicked(false)
    }


    return (
        <main>
            <div className='pr-6'>
                <section>
                    <div className='w-full flex justify-between py-5'>
                        <h2 to='/dashboard' className='text-3xl text-gray-900 font-semibold'>{`${isClicked ? 'Additional Coupons' : 'Additional Coupons List'}`}</h2>
                        <div className='flex gap-x-10'>

                            <User />
                        </div>
                    </div>
                </section>
                {
                    isClicked ?
                        <AdditionalCouponForm
                            handleClose={handleClose}
                            id={couponId}
                        /> :
                        <AdditionalCouponList
                            handleClick={handleNewClick}
                        />
                }


            </div>
        </main>
    )
}
export default AdditionalCoupon