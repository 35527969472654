import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import { ContentWriter } from "../../../constant";
import { getToken } from "../../../hook/getToken";
import { Link } from "react-router-dom";

const CompletedTask = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const limit = 10; // Number of tasks per page
  const accessToken = getToken(); // Replace with your access token

  useEffect(() => {
    fetchBlogs();
  }, [currentPage]); // Update fetchBlogs when currentPage changes

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/content-head/get/blogs?status=active&age=1&limit=${limit}&page=${currentPage}&isApproved=true`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Replace YOUR_ACCESS_TOKEN with the actual access token
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setBlogs(data.data);
        setTotalPages(Math.ceil(data.metadata.totalCount / limit)); // Calculate total pages
      } else {
        console.error("Failed to fetch blogs:", data.message);
      }
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle pagination
  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <main className="max-w-full flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      {/* <div className="">
        <h1 className="text-xl font-semibold text-left">Completed Tasks</h1>
      </div> */}
      <div className="w-full p-2 pt-8 text-xs">
        <div className="w-full">
          <table className="w-full  border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2">Sl. No</th>
                <th className="px-4 py-2">Blog Type</th>
                <th className="px-4 py-2">Title</th>
                <th className="px-4 py-2">Word Count</th>
                <th className="px-4 py-2">Payment status</th>
                <th className="px-4 py-2">Payment Receipt</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                blogs.map((task, index) => (
                  <tr key={task._id} className="hover:bg-gray-100">
                    <td className="border-t px-4 py-2">{index + 1 + (currentPage - 1) * limit}</td>
                    <td className="border-t px-4 py-2">{task.blog_category}</td>
                    <td className="border-t px-4 py-2">{task.title}</td>
                    <td className="border-t px-4 py-2">{task.description_word_count}</td>
                    <td className="border-t px-4 py-2">{task.payment_status}</td>
                    <td className="border-t px-4 py-2">
                      {task.payment_receipt?.payment_receipt ? (
                        <a
                          href={task.payment_receipt.payment_receipt}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          View Receipt
                        </a>
                      ) : (
                        "No Receipt"
                      )}
                    </td>
                    <td className="border-t px-4 py-2">{task.status}</td>
                    <td className="border-t px-4 py-2">
                      <Link
                        key={task._id}
                        to={{
                          pathname: `/content-writer-dashboard/blog-post/${task._id}`,
                          search: `?blogCategoryId=${task.blog_categories_id}&blogContentId=${task.blog_content_id}`,
                        }}
                        className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePagination(page)}
                className={`mx-1 px-3 py-1 rounded-md ${
                  currentPage === page
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {page}
              </button>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default CompletedTask;
