import React, { useState, useEffect } from "react";
import ContactListRow from "./ContactListRow";
import { Link } from "react-router-dom";

const ContactList = () => {
  const [childModal, setChildModal] = useState(false);
  const [childCategories, setChildCategories] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('access_token');

    fetch(
      `${process.env.REACT_APP_URL}/v1/contactUs/get`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setChildCategories(data.data);
      });
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="py-10 text-xs font-semibold pr-5">

      <div>
        <div className="flex items-center py-3">
          <Link to="/dashboard">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Link>
          <p className="text-4xl">Contact List</p>
        </div>
      </div>
      <section>
        <div className="overflow-hidden rounded-t-3xl my-5">
          <table className="table min-w-full border border-solid">
            <thead className="bg-[#00388C]">
              <tr>
                <th scope="col" className="px-6 py-2 text-left text-xs font-normal text-white">
                  Sl. No.
                </th>
                <th scope="col" className="px-6 py-2 text-left text-xs font-normal text-white">
                  Name
                </th>
                <th scope="col" className="px-6 py-2  text-xs font-normal text-white">
                  Phone
                </th>
                <th scope="col" className="px-6 py-2  text-xs font-normal text-white">
                  Email
                </th>
                <th scope="col" className="px-6 py-2  text-xs font-normal text-white">
                  Subject
                </th>
                <th scope="col" className="px-6 py-2 text-xs font-normal text-white">
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {childCategories?.length ? (
                childCategories?.map((data, index) => (
                  <ContactListRow key={index} index={index} data={data} />
                )))
                : <h1 className="text-3xl mt-5">No Contact person</h1>
              }
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ContactList;
