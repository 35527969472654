import React, { useState } from 'react';
import { getToken } from '../../hook/getToken';
import toast from 'react-hot-toast';

const UploadRecieptModal = ({ visible, onClose, blogid }) => {
    console.log("Blog Id >>>>",blogid)
    const [file, setFile] = useState(null);

    const token = getToken();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = async () => {
        if (!file) return;

        try {
            const reader = new FileReader();

            reader.onload = async () => {
                const dataUrl = reader.result;

                const requestBody = {
                    image : dataUrl
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/payment/${blogid}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify(requestBody)
                    });

                    const data = await response.json();
                    if(response.ok){
                        toast.success(data.message)
                        onClose()
                    }
                  
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            };

            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };

 

    const handleCloseModal = () => {
        onClose();
        setFile(null);
    };

    return (
        <>
            {visible && (
                <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-bold">Upload Payment Reciept</h2>
                            <button onClick={handleCloseModal} className="text-gray-500 hover:text-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <input
                            type="file"
                            accept="image/*, video/*"
                            onChange={handleFileChange}
                            className="block w-full border border-gray-300 p-2 rounded-md mb-4"
                        />
                        <div className="flex justify-end gap-x-2">
                            <button
                                onClick={handleCloseModal}
                                className="text-white bg-red-500 px-3 py-2 rounded hover:bg-red-600 focus:outline-none"
                            >
                                Close
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded focus:outline-none"
                            >
                                Upload
                            </button>
                        </div>

                    </div>
                </div>
            )}
        </>
    );
};

export default UploadRecieptModal;
