import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getToken } from '../../hook/getToken'
import getList from '../getList'
import SideBar from '../Sidebar'
import { ContentHead } from '../../constant'
import NavigateBack from '../NavigateBack'
import AssociateBlogTable from './AssociateBlogTable'
const AssociateBlogWithCw = () => {
    const { id,name } = useParams()
    const [list, setList] = useState([])
    const [cmName, setCmName] = useState('')

    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}/v1/content-head/cw/asingn/task/${id}`
        const token = getToken()
        getList(url, token).then(data => {
            setList(data?.data)
          
        })
    }, [id])
    return (
        <main className='w-full flex flex-row gap-x-5 '>
            <div className='sidebar bg-[#00388c] h-screen w-fit sticky top-0'>
                <SideBar
                    menu={ContentHead}
                />
            </div>
            <div className='flex-1 overflow-hidden mr-5'>

                <section className='flex flex-row justify-between py-5 items-center'>
                    <div className='flex space-x-2 items-center'>
                        <NavigateBack />
                        <h1 className=' text-2xl text-[#383E50] font-semibold leading-10 text-left'>Assigned Blogs To Content Writers</h1>

                    </div>
                    {/* <div className='flex flex-row gap-5'>
                        <input type='search' placeholder='Search' className='w-60 border border-solid border-[#EEE] rounded-xl p-2' />

                    </div> */}
                </section>
                <div className="">
                    <h1 className='text-xl text-left font-semibold'>Content Writer Name : {name}</h1>
                </div>
                <section className='w-full flex flex-col'>
                    <AssociateBlogTable
                        id={id}
                        list={list}
                        cmName={cmName}
                    />
                </section>
            </div>
        </main>
    )
}

export default AssociateBlogWithCw;