import React, { useEffect, useState } from "react";
import WfhBannerModal from "./WfhBannerModal";
import { getToken } from "../../../../hook/getToken";

const Wfh = () => {
  const token = getToken();
  const [showModal, setShowModal] = useState(false);
  const [banners, setBanners] = useState([]);
  const [selectedBannerTitle, setSelectedBannerTitle] = useState(""); // State to hold the selected banner title

  // Fetch banners on component mount
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/v1/app/get-all-shop-page-banner?page=wfh`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setBanners(data.response); // Accessing the "response" array from the data
        } else {
          console.error("Failed to fetch banners:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setSelectedBannerTitle(""); // Reset the selected banner title when closing the modal
  };

  const handleAddBanner = () => {
    setSelectedBannerTitle(""); // No title when adding a new banner
    setShowModal(true);
  };

  const handleEditBanner = (title) => {
    console.log("Hi")
    setSelectedBannerTitle(title); // Set the selected banner title for editing
    setShowModal(true);
  };

  return (
    <div>
      <div className="flex justify-between my-4">
        <h1 className="text-lg font-semibold">Application Wfh Banner</h1>
        <div className=""></div>
      </div>
      <div className="flex justify-between">
        <button className="text-sm font-semibold" onClick={handleAddBanner}>
          <span className="">+</span> Add Banner
        </button>
        {showModal && <WfhBannerModal onClose={handleClose} bannerTitle={selectedBannerTitle} />}
      </div>

      <div className="relative overflow-x-auto p-5">
        {/* Display all banners */}
        {banners.length > 0 ? (
          <div className="flex flex-col gap-4">
            {banners.map((banner) => (
              <div key={banner._id} className="">
                <div className="flex justify-between items-center">
                  <p className="text-start text-base font-semibold mb-2">{banner.title}</p>
                  <button
                    className="text-sm text-blue-500"
                    onClick={() => handleEditBanner(banner.title)} // Edit button
                  >
                    Edit
                  </button>
                </div>

                {/* Render for 'single' type */}
                {banner.type === "single" && (
                  <div className="">
                    <img
                      src={banner.image}
                      alt={banner.title}
                      className="w-1/5 h-60 object-fill rounded-sm border border-teal-700 border-dashed p-2"
                    />
                  </div>
                )}

                {/* Render for 'slider' type */}
                {banner.type === "slider" && (
                  <div className="flex space-x-2 overflow-x-auto">
                    {banner.slider_images.map((sliderImage) => (
                      <img
                        key={sliderImage._id}
                        src={sliderImage.image}
                        alt={banner.title}
                        className="w-1/5 h-60 object-cover rounded-sm border border-teal-700 border-dashed p-2"
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No banners found.</p>
        )}
      </div>
    </div>
  );
};

export default Wfh;
