import React, { useEffect, useState } from "react";
import { getToken } from "../../../hook/getToken";
const AdditionalCouponForm = ({ handleClose, id }) => {
    const [message, setMessage] = useState("");
    // Initialize state for form fields
    const [formData, setFormData] = useState({
        name: "",
        discount_per: "",
        start_date: "",
        end_date: "",
        min_purchase: ""
    });

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    useEffect(() => {
        if (id) {
            try {
                fetch(`${process.env.REACT_APP_URL}/v1/discount/discount-online-payment/${id}`)
                    .then((res) => res.json())
                    .then((data) => {
                        const { name, discount_per, start_date, end_date, min_purchase } = data.response;
                        setFormData({
                            name,
                            discount_per,
                            start_date,
                            end_date,
                            min_purchase
                        });
                        console.log('single coupont data', data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                console.log("An error occurred:", error);
            }
        }
    }, [id]);

    async function AddCoupon(url, type) {
        const method = type ? type : "POST"
        const access_token = getToken();
        try {

            const requestOptions = {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify(formData),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            setMessage(data.message);
            console.log("response data", data);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (id) {
            AddCoupon(
                `${process.env.REACT_APP_URL}/v1/discount/discount-online-payment?id=${id}`,
                "PUT"
            ).then((res) => {
                if (res.ok) {
                    setMessage("");
                    alert("Coupon Updated Successfully");
                    handleClose()
                }
            });
        } else {
            AddCoupon(`${process.env.REACT_APP_URL}/v1/discount/discount-online-payment`).then(
                (res) => {
                    if (res.ok) {
                        setMessage("");
                        alert("Coupon Added Successfully");
                        handleClose()
                    }
                }
            );
        }
        console.log(formData);
    };

    return (
        <div className="pr-6 py-5 text-xs">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col">
                    <div className=" w-5/6 flex items-center gap-4 my-2">
                        <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                            Name *
                        </label>
                        <input
                            required
                            className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" w-5/6 flex items-center gap-4 my-2">
                        <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                            Discount Percentage
                        </label>
                        <input
                            required
                            className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
                            type="number"
                            min={0}
                            max={100}
                            name="discount_per"
                            value={formData.discount_per}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" w-5/6 flex items-center gap-4 my-2">
                        <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                            Min Purchage
                        </label>
                        <input
                            required
                            className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
                            type="text"
                            name="min_purchase"
                            value={formData.min_purchase}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" w-5/6 flex items-center gap-4 my-2">
                        <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                            Start Date *
                        </label>
                        <input
                            required
                            className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
                            type="date"
                            name="start_date"
                            value={formData.start_date}
                            onChange={handleChange}
                        />
                    </div>
                    <div className=" w-5/6 flex items-center gap-4 my-2">
                        <label className=" w-1/3 flex justify-end text-xs text-indigo-900 font-bold ">
                            End Date *
                        </label>
                        <input
                            required
                            className="grow py-1 px-3 border border-solid border-gray-200 rounded-md"
                            type="date"
                            name="end_date"
                            value={formData.end_date}
                            onChange={handleChange}
                        />
                    </div>

                </div>
                <div className="flex gap-x-4 justify-center items-center py-5">
                    <button
                        onClick={() => handleClose()}
                        type="button"
                        className="py-1 px-6 text-red-600 "
                    >
                        Cancel
                    </button>
                    <button type="Submit" className="py-1 px-14 bg-blue-900 text-white">
                        Save
                    </button>
                </div>
                {message ? <p className="text-red-500">{message}</p> : null}
            </form>
        </div>
    );
};

export default AdditionalCouponForm;
