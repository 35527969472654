import React from 'react'
import { User } from '../user/User'
import { WalletOfferForm } from './WalletOfferForm'
const WalletOffer = () => {
    return (
        <main>
            <div className='pr-6'>
                <section>
                    <div className='w-full flex justify-between py-5'>
                        <h2 className='text-3xl text-gray-900 font-semibold'>Wallet Offer</h2>
                        <div className='flex gap-x-10'>

                            <User />
                        </div>
                    </div>
                </section>
                <WalletOfferForm />
            </div>
        </main>
    )
}

export default WalletOffer