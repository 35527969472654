import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getToken } from "../../../../hook/getToken";
import SliderImages from "./SliderTab";

const ShopBannerModal = ({ onClose, id, bannerTitle }) => {
  const token = getToken();
  const [selectedTab, setSelectedTab] = useState("single"); // Manage tabs (single/slider)
  const [images, setImages] = useState([]); // Store multiple images for slider
  const [image, setImage] = useState(""); // Store single base64 image for Single banner
  const [title, setTitle] = useState(""); // Title will change based on the tab
  const [selectedType, setSelectedType] = useState("product"); // Manage Product/Brand choice
  const [selectedItem, setSelectedItem] = useState(""); // Store the selected product or brand
  const [brands, setBrands] = useState([]); // Store fetched brands
  const [products, setProducts] = useState([]); // Store fetched products
  const [searchQuery, setSearchQuery] = useState(""); // Store search input for products
 // Fetch banner details based on the title
  useEffect(() => {
    if (bannerTitle) {
      const fetchBannerDetails = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/v1/app/get-shop-page-banner?title=${bannerTitle}`
          );
          if (response.ok) {
            const fetchedData = await response.json();
            const bannerData = fetchedData.response[0]; // Getting the first item from the response array
            console.log("Banner data??>>", bannerData);

            setTitle(bannerData.title || "");
            setSelectedType(bannerData.type || "product");
            setSelectedItem(bannerData.product_id || bannerData.brand_id || ""); // Assuming product_id or brand_id is being used

            if (bannerData.type === "single") {
              setSelectedTab("single");
              setImage(bannerData.image || ""); // Setting the single image URL
            } else if (bannerData.type === "slider") {
              setSelectedTab("slider");
              setImages(bannerData.slider_images || []); // Setting slider images
            }
          } else {
            console.error(
              "Failed to fetch banner details:",
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error fetching banner details:", error);
        }
      };

      fetchBannerDetails();
    }
  }, [bannerTitle]);

  // Fetch active brands
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/brand-registration/all-active-brand`
        );
        if (response.ok) {
          const data = await response.json();
          setBrands(data.brands);
        } else {
          console.error("Failed to fetch brands:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  // Fetch products based on search query
  useEffect(() => {
    if (searchQuery.length > 2) {
      const fetchProducts = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_URL}/v1/cms/get-products-list?search_query=${searchQuery}`
          );
          if (response.ok) {
            const data = await response.json();
            setProducts(data.response);
          } else {
            console.error("Failed to fetch products:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };

      fetchProducts();
    }
  }, [searchQuery]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result; // Get the base64-encoded image
        if (selectedTab === "single") {
          setImage(base64Image); // Set image for single tab
          console.log("Single Image:", base64Image);
        } else {
          // Append a new object to the images array
          const updatedImages = [
            ...images,
            { image: base64Image, product_id: "", brand_id: "" },
          ];
          setImages(updatedImages); // Update images array in state
          console.log("Updated Images Array:", updatedImages); // Log updated array
        }
      };
      reader.readAsDataURL(file);
    }
  };
  

  // Remove image from the slider
  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  // Handle tab switching (Single/Slider)
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleImageSelectionChange = (index, type, value) => {
    console.log("Index:", index, "Type:", type, "Value:", value);

    const updatedImages = [...images];
    updatedImages[index] = {
      ...updatedImages[index],
      [type]: value, // Dynamically set the property (brand_id or product_id)
    };

    console.log("Updated Images:", updatedImages);
    setImages(updatedImages);
  };

  // Handle radio change (Product/Brand)
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedItem(""); // Reset selected item when switching between product/brand
  };

  // Handle Save functionality
  const handleSave = async () => {
    let payload;

    if (selectedTab === "slider") {
      // Format payload for slider type
      console.log("Images>>??",images)
      payload = {
        title,
        type: selectedTab, // Set the type to 'slider'
        page: "shop",
        slider_images: images.map((img) => ({
          image: img.image, // Ensure you're accessing `img.image` correctly
          brand_id: img.brand_id, // Retrieve from the updated `images` array
          product_id: img.product_id, // Retrieve from the updated `images` array
        })),
      };
    } else {
      // Format payload for single type
      payload = {
        title,
        type: selectedTab, // Set the type to 'single'
        image: image, // Single image for the single banner
        brand_id: selectedType === "brand" ? selectedItem : undefined, // Include brand ID if brand is selected
        product_id: selectedType === "product" ? selectedItem : undefined, // Include product ID if product is selected
        page: "shop",
      };
    }
    console.log("Psyload?", payload);
  
    

    const endpoint = `${process.env.REACT_APP_URL}/v1/app/set-shop-page-banner`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(`${data?.message}`);
        onClose(); // Close the modal on success
      } else {
        console.error(
          `Failed to ${id ? "update" : "add"} splash screen:`,
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        `Error ${id ? "updating" : "adding"} splash screen:`,
        error
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/5">
        <div className="border">
          {/* Tabs for Single/Slider */}
          <div className="mb-4 flex justify-start">
            <button
              className={`py-1 px-3 ${
                selectedTab === "single"
                  ? "bg-blue-900 text-white text-xs"
                  : "bg-gray-200 text-gray-700 text-xs"
              } rounded-t`}
              onClick={() => handleTabChange("single")}
            >
              Single
            </button>
            <button
              className={`py-2 px-4 ${
                selectedTab === "slider"
                  ? "bg-blue-900 text-white text-xs"
                  : "bg-gray-200 text-gray-700 text-xs"
              } rounded-t`}
              onClick={() => handleTabChange("slider")}
            >
              Slider
            </button>
          </div>

          {/* Title Input */}
          <div className="mb-4">
            <label className="block text-left text-sm font-medium text-gray-700">
              {selectedTab === "single"
                ? "Single Banner Title"
                : "Slider Title"}
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-2 p-2 border border-gray-300 rounded-lg w-full text-xs"
              placeholder={`Enter ${
                selectedTab === "single" ? "Single" : "Slider"
              } Title`}
            />
          </div>

          {/* Image Upload and Preview */}
          <div className="mb-4">
            <label className="block text-sm text-left font-medium text-gray-700">
              Upload Image
            </label>
            <input
              type="file"
              accept="image/*"
              className="mt-2 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-gray-100 file:text-gray-700 hover:file:bg-gray-200"
              onChange={handleImageChange}
            />
          </div>

          {/* Image Preview and Remove Button */}
          {selectedTab === "single" && image && (
            <div className="mb-4">
              <label className="block text-sm text-left font-medium text-gray-700">
                Image Preview
              </label>
              <img
                src={image}
                alt="Preview"
                className="w-full h-32 object-cover mt-2 rounded-lg"
              />
            </div>
          )}

          {selectedTab === "slider" && images.length > 0 ? (
            <SliderImages
              products={products}
              brands={brands}
              setProducts={setProducts}
              selectedTab={selectedTab}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              handleTypeChange={handleTypeChange}
              handleImageChange={handleImageChange}
              handleRemoveImage={handleRemoveImage}
              images={images}
              setImages={setImages}
              handleImageSelectionChange={handleImageSelectionChange}
              handleTabChange={handleTabChange}
            />
          ) : null}

          {/* Product/Brand Radio Options */}
          {selectedTab === "single" ? (
            <>
              <div className="mb-4">
                <label className="block text-sm text-left font-medium text-gray-700">
                  Choose Type
                </label>
                <div className="flex mt-2 space-x-4">
                  <label>
                    <input
                      type="radio"
                      value="product"
                      checked={selectedType === "product"}
                      onChange={handleTypeChange}
                      className="mr-2"
                    />
                    Product
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="brand"
                      checked={selectedType === "brand"}
                      onChange={handleTypeChange}
                      className="mr-2"
                    />
                    Brand
                  </label>
                </div>
              </div>
              {/* Conditional Search Bar */}
              <div className="mb-4">
                {selectedType === "product" && (
                  <>
                    <label className="block text-left text-sm font-medium text-gray-700">
                      Search Product
                    </label>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="mt-2 p-2 border border-gray-300 rounded-lg w-full text-xs"
                      placeholder="Search for product"
                    />

                    {/* Display product list based on the search query */}
                    {products.length > 0 && (
                      <ul className="mt-2 bg-white border border-gray-300 rounded-lg max-h-40 overflow-y-auto">
                        {products.map((product) => (
                          <li
                            key={product._id}
                            onClick={() => {
                              setSelectedItem(product._id); // Set the selected product's ID
                              setSearchQuery(product.name); // Set the search input to the product's name
                              setProducts([]); // Hide the product list after selection
                            }}
                            className={`p-2 cursor-pointer ${
                              selectedItem === product._id ? "bg-blue-100" : ""
                            }`}
                          >
                            {product.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}

                {selectedType === "brand" && (
                  <>
                    <label className="block text-left text-sm font-medium text-gray-700">
                      Select Brand
                    </label>
                    <select
                      value={selectedItem}
                      onChange={(e) => setSelectedItem(e.target.value)}
                      className="mt-2 p-2 border border-gray-300 rounded-lg w-full text-xs"
                    >
                      <option value="">Choose brand</option>
                      {brands.map((brand) => (
                        <option key={brand._id} value={brand._id}>
                          {brand.brand_name}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </>
          ) : null}
          {/* Save Button */}
          <div className="mt-4 flex justify-end">
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-700"
              onClick={handleSave}
            >
              {id ? "Update" : "Save"}
            </button>
            <button
              className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopBannerModal;
