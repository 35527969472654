import React, { useState } from "react";

const ProductHistory = ({ recentView }) => {
  const [selectedDate, setSelectedDate] = useState("");

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString.split("-").reverse().join("-"));
    return date.toLocaleDateString("en-GB", options).replace(/(\d+)(?=\D)/g, "$1th");
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const filteredProducts = recentView?.productList?.filter((productDay) => {
    if (!selectedDate) return true;
    return productDay._id === selectedDate.split("-").reverse().join("-");
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-sm text-start font-semibold">Product History</h1>
      </div>
      <div className="max-w-lg h-[30rem] overflow-x-scroll no-scrollbar grow p-4 bg-white shadow-md rounded-md">
        <div className="flex justify-end mb-4">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="border border-gray-300 rounded-md p-1 text-sm"
          />
        </div>
        {filteredProducts?.length > 0 ? (
          filteredProducts.map((productDay) => (
            <div key={productDay._id} className="mb-6">
              <h3 className="text-lg text-start font-semibold mb-4">
                {formatDate(productDay._id)}
              </h3>
              <div className="flex space-x-4 overflow-scroll">
                {productDay.product_list.map((product) => (
                  <div key={product._id} className="flex flex-col w-24 shrink-0">
                    <img
                      src={product.main_img}
                      alt={product.item_name}
                      className="w-24 h-24 object-cover rounded-md"
                    />
                    <p className="text-xs text-start font-normal italic">
                      {product.item_name}
                    </p>
                    <p className="text-xs text-start font-semibold text-sky-700">
                      ₹{product.list_price}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-center text-gray-500 mt-16">
            There are no products to display.
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductHistory;
