import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import SliderInput from './SliderInput'
const UtilityTemplate1 = ({ index, initialValues, onDataChange }) => {
    const [utilityList, setUtilityList] = useState()
    const [formData, setFormData] = useState({
        ids: initialValues?.ids || [],
        heading: initialValues?.heading || '',
        sub_heading: initialValues?.sub_heading || '',

    });
    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };
    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}/v1/filter/get-all/utility?page=1&limit=500`
        fetch(url).then(res => res.json()).then(data => setUtilityList(data.data))
    }, [])
    return (
        <div className='w-full flex flex-col border'>
            <TopBar name='Utility Section' index={index} />
            <div>
                <div className='my-2 px-5'>
                    <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Heading</label>
                    <input
                        type="text"
                        id="left-image-with-text-headline"
                        className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                        placeholder="Enter headline text"
                        maxLength={100}
                        required
                        value={formData?.heading}
                        onChange={(e) => handleFieldChange('heading', e.target.value)}
                    />
                </div>
                <div className='my-2 px-5'>
                    <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Sub Heading</label>
                    <input
                        type="text"
                        id="left-image-with-text-headline"
                        className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                        placeholder="Enter headline text"
                        maxLength={100}
                        value={formData?.sub_heading}
                        onChange={(e) => handleFieldChange('sub_heading', e.target.value)}
                    />
                </div>
            </div>
            <SliderInput
                sliderIds={formData?.ids}
                name={`ids`}
                onChange={handleFieldChange}
                componentIndex={index}
                list={utilityList}
                componentType='Utility'
            />
        </div>
    )
}

export default UtilityTemplate1