import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../Sidebar";
import { ContentWriter } from "../../../constant";
import { getToken } from "../../../hook/getToken";
import Description from "../../../Description";

const PendingTask = () => {
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const limit = 10; // Number of tasks per page
  const accessToken = getToken(); // Replace with your access token

  useEffect(() => {
    fetchTasks();
  }, [currentPage]); // Fetch tasks when the page changes

  // Fetch tasks from API
  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/content-head/cw/asingn/task?status=Pending&page=${currentPage}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setTasks(data?.data);
      setTotalPages(Math.ceil(data?.totalCount / limit));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setLoading(false);
    }
  };

  // Handle pagination
  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  // Handle showing keywords in a popup
  const [keywordsPopup, setKeywordsPopup] = useState(null);

  const handleViewKeywords = (keywords) => {
    setKeywordsPopup(keywords);
  };

  // Handle copying keywords to clipboard
  const handleCopyKeywords = async (keyword) => {
    try {
      await navigator.clipboard.writeText(keyword);
      alert("Keyword copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy keyword:", error);
      alert("Failed to copy keyword.");
    }
  };

  return (
    <main className=" flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className="w-full p-2 pt-8 text-xs">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="w-full">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Sl. No</th>
                  <th className="px-4 py-2">Blog Type</th>
                  <th className="px-4 py-2">Blog Topic</th>
                  <th className="px-4 py-2">Brand Name</th>
                  <th className="px-4 py-2">Product Name</th>
                  <th className="px-4 py-2">Keywords</th>
                  <th className="px-4 py-2">Description</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tasks?.map((task, index) => (
                  <tr key={task._id} className="hover:bg-gray-100">
                    <td className="border-t px-4 py-2">{index + 1}</td>
                    <td className="border-t px-4 py-2">{task.blog_type}</td>
                    <td className="border-t px-4 py-2"><Description description={task.blog_topic} /></td>
                    <td className="border-t px-4 py-2">{task.brand_name}</td>
                    <td className="border-t px-4 py-2">{task.product_name}</td>
                    <td className="border-t px-4 py-2">
                      <button
                        className="text-blue-500 underline cursor-pointer"
                        onClick={() => handleViewKeywords(task.keywords)}
                      >
                        View Keywords
                      </button>
                    </td>
                    <td className="border-t px-4 py-2"><Description description={task.description} /></td>
                    <td className="border-t px-4 py-2">{task.status}</td>
                    <td className="border-t px-2 py-2">
                      <Link
                        key={task._id}
                        to={{
                          pathname: `/content-writer-dashboard/post/${task._id}`,
                          search: `?blogCategoryId=${task.blog_categories_id}&blogContentId=${task.blog_content_id}`,
                        }}
                        target="_blank" // Add this line
                        rel="noopener noreferrer" // Add this line for security
                        className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                      >
                        Write Blog
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePagination(page)}
                    className={`mx-1 px-3 py-1 rounded-md ${currentPage === page
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </div>
        )}
      </div>
      {keywordsPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-lg font-semibold mb-2">Keywords</h2>
            <div className="flex flex-wrap gap-x-2">
              {keywordsPopup.map((keyword, index) => (
                <div key={index} className="flex mb-2">
                  <code>{keyword}</code>
                  <button
                    className="text-black rounded-md"
                    onClick={() => handleCopyKeywords(keyword)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                    </svg>
                  </button>
                </div>
              ))}

            </div>
            <div className="flex justify-center mt-4">


              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
                onClick={() => setKeywordsPopup(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default PendingTask;
