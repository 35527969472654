import React, { useState } from 'react';
import { getToken } from '../../hook/getToken';
import toast from 'react-hot-toast';

const CancelModal = ({ visible, onClose, showCancelModal, orderId }) => {
    const token = getToken()
    const [reason, setReason] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    if (!visible) {
        return null;
    }

    const cancellationReasons = [
        "Found a better alternative",
        "Product did not meet expectations",
        "Difficult to use or navigate",
        "Lack of desired features or functionality",
        "Poor customer support or service",
        "Unresolved bugs or technical issues",
        "Price or cost-related concerns",
        "Product performance was unsatisfactory",
        "Incompatible with existing systems or software",
        "Security or privacy concerns"
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            user_order_id: orderId,
            reason: reason,
            description: description,
            order_cancel_by: "admin",
        };

        try {
            setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/orders/order-cancel-admin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.ok) {
                showCancelModal(result); // Call the provided function with the API response
                toast.success('Order cancelled successfully!');
            } else {
                console.error(result.message || 'Failed to cancel order');
                toast.error(result.message || 'Failed to cancel order');
            }
        } catch (error) {
            console.error('Error cancelling order:', error);
            alert('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false);
            setReason('');
            setDescription('');
            onClose(); // Close the modal
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
            <div className="w-1/2 mx-auto bg-white rounded py-5 px-7">
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <label className="font-normal text-gray-900 text-base">
                        Please select a reason for your cancel
                    </label>
                    <select
                        className="bg-gray-100 border border-solid border-gray-300 rounded my-4 py-2 px-3 font-normal text-gray-900 text-sm outline-0"
                        required
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    >
                        <option value="">Select a reason</option>
                        {cancellationReasons.map((reason, index) => (
                            <option key={index} value={reason}>
                                {reason}
                            </option>
                        ))}
                    </select>

                    <label className="font-normal text-gray-900 text-base">
                        Description
                    </label>
                    <textarea
                        className="bg-gray-100 border border-solid border-gray-300 rounded my-2 outline-0 p-3"
                        rows="4"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <div className="flex gap-x-5 justify-end my-5">
                        <button
                            type="button"
                            onClick={onClose}
                            className="py-2 px-4 bg-white text-red-600 border rounded-full"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="py-2 px-10 bg-gray-500 text-white rounded-full"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Processing...' : 'Continue'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CancelModal;
