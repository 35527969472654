import React from 'react';
import { useNavigate } from 'react-router-dom';
import ManageAppovalModal from './ManageAppovalModal';
const TableRow = ({ data, sessionId }) => {
    const { content_name, updatedAt, approval_status, _id, content_description } = data
    const navigate = useNavigate();

    const handlePreview = () => {
        const redirectUrl = `/home-cms/content/preview/${_id}`
        navigate(redirectUrl)
    }
    return (
        <tr className="border-b border-solid border-gray-200 bg-white text-[#222222]">
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">{content_name}</td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">{content_description}</td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                {updatedAt?.slice(0, 10)}
            </td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                <button type='button' className='py-2 px-4 rounded'>{approval_status}</button>
            </td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                <div className='flex space-x-2'>

                    <button
                        onClick={() => handlePreview()}
                        type='button' className='py-2 px-4 rounded text-white bg-teal-500'>
                        Preview
                    </button>
                </div>
            </td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                <ManageAppovalModal
                    status={approval_status}
                    id={_id}
                />
            </td>
        </tr>
    );
}

export default TableRow;