import React, { useState, useEffect } from 'react'
import { useInView } from "react-intersection-observer";
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from './ProductCard';
const categoryImg = [
    {
        id: '64b4d04e959d127070891af5',
        img: 'https://res.cloudinary.com/genx21/image/upload/v1728026337/category-section-cms-image/vzh1umhko30mu0zzqlzw.jpg',
    },
    {
        id: '657706484854651840a9f915',
        img: 'https://res.cloudinary.com/genx21/image/upload/v1728026358/category-section-cms-image/iphhhlnmsm6bnstggmcu.jpg',
    },
    {
        id: '659fb50c3f073aa9c092ba64',
        img: 'https://res.cloudinary.com/genx21/image/upload/v1728026359/category-section-cms-image/mochlj7dncvvsgmahgl0.jpg',
    },
    {
        id: '65cf5476d2992b052a326a33',
        img: 'https://res.cloudinary.com/genx21/image/upload/v1728026359/category-section-cms-image/xnfwthzjzrdvhajk0erj.jpg',
    },
    {
        id: '65d31adcd2992b052a328b1b',
        img: 'https://res.cloudinary.com/genx21/image/upload/v1728026359/category-section-cms-image/y4tjupje5l0fl7k7n3dt.jpg',
    },
];

const getImage = (id) => {
    const img = categoryImg.find((img) => img.id === id);
    return img ? img.img : null
}

const CategoryTemplate1 = ({ data }) => {
    const { heading, sub_heading, ids } = data;
    const [selectedId, setSelectedId] = useState(ids[0]?.id)
    const categoriesId = JSON.stringify(ids?.map(d => d.id))
    const [list, setList] = useState([])
    const [products, setProducts] = useState([])


    useEffect(() => {
        if (!data?.ids) return
        fetch(`${process.env.REACT_APP_URL}/v1/cms/get-filter-content?filter_type=parent-category&ids=${categoriesId}`).then(res => res.json()).then(data => setList(data.response.data))
    }, [data.ids])

    useEffect(() => {
        if (!selectedId) return
        fetch(`${process.env.REACT_APP_URL}/v1/products/get-all-products?filter[parent_category_id][$eq]=${selectedId}&page=1&limit=8&random=yes`).then(res => res.json()).then(data => setProducts(data.productList))

    }, [selectedId])

    const handleChange = (id) => {
        setSelectedId(id)
    }

    return (
        <div className='flex flex-col'>
            <Heading text={heading} />
            <SubHeading text={sub_heading} />
            <div className='home-heading w-full '>
                {
                    list?.map(c => (
                        <CategoryHeadingCard
                            data={c}
                            id={selectedId}
                            handleChange={handleChange}
                        />
                    ))
                }
            </div>
            <div className='grid grid-cols-2 gap-2 sm:grid-cols-3 md:mt-5 md:grid-cols-4 md:gap-5 lg:grid-cols-5'>
                {products && products.length !== 0 && (
                    <div className='h-52 w-full overflow-hidden md:h-60'>
                        <img
                            src={getImage(selectedId)}
                            alt='category-image'
                            className='h-full w-full object-contain'
                        />
                    </div>
                )}

                {products &&
                    products.length !== 0 &&
                    products.map((product, index) => {
                        return (
                            <ProductCard
                                key={product._id}
                                product={product}
                                style={'w-full h-52 md:h-60 '}
                                iconStyle={`w-5 h-5`}
                                textSize={`text-xs `}
                                quickViewWidth={24}
                                quickViewHeight={24}
                            />
                        );
                    })}
                {products && products.length !== 0 && (
                    <div className='h-52 w-full overflow-hidden md:h-60'>
                        <img
                            src='https://res.cloudinary.com/genx21/image/upload/v1728026359/category-section-cms-image/hvo7lsxy8aqyeingylpb.jpg'
                            alt='category-image-2'
                            className='h-full w-full object-contain'
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default CategoryTemplate1

const CategoryHeadingCard = ({ handleChange, id, data }) => {
    const { _id, name, image } = data;
    return (
        <div
            className={`shrink-0 w-36 md:48  xl:w-52 h-fit xl:h-fit hover:scale-100 rounded-lg  overflow-hidden ${id == _id ? 'ring-2 ring-gray-400 ' : 'grayscale filter'}`}
            onMouseOver={() => handleChange(_id)}
        >
            <div className='h-full w-full'>
                <img
                    loading='lazy'
                    width={100}
                    height={100}
                    src={image}
                    alt={name}
                    className='h-full w-full object-cover'
                />
            </div>
        </div>
    )
}