import React, { useState } from 'react';
import { getToken } from '../../hook/getToken';

const UploadModal = ({ visible, onClose }) => {
    const [file, setFile] = useState(null);
    const [responseLink, setResponseLink] = useState('');
    const token = getToken();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = async () => {
        if (!file) return;

        try {
            const reader = new FileReader();

            reader.onload = async () => {
                const dataUrl = reader.result;

                const requestBody = {
                    fileUris: [dataUrl]
                };

                try {
                    const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/upload`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify(requestBody)
                    });

                    const data = await response.json();

                    setResponseLink(data.fileUrls);
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            };

            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(responseLink);
    };

    const handleCloseModal = () => {
        onClose();
        setFile(null);
    };

    return (
        <>
            {visible && (
                <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-bold">Upload Image/Video</h2>
                            <button onClick={handleCloseModal} className="text-gray-500 hover:text-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <input
                            type="file"
                            accept="image/*, video/*"
                            onChange={handleFileChange}
                            className="block w-full border border-gray-300 p-2 rounded-md mb-4"
                        />
                        <div className="flex justify-end gap-x-2">
                            <button
                                onClick={handleCloseModal}
                                className="text-white bg-red-500 px-3 py-2 rounded hover:bg-red-600 focus:outline-none"
                            >
                                Close
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded focus:outline-none"
                            >
                                Upload
                            </button>
                        </div>
                        {responseLink && (
                            <div className="mt-4 flex items-center text-sm text-green-500">
                                <span className="text-black font-semibold">Uploaded file link: </span>
                                <span className="ml-2">{responseLink}</span>
                                <button onClick={handleCopyLink} className="ml-2 text-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default UploadModal;
