import { useState, useEffect } from "react";

const SliderImages = ({
  images: initialImages,
  setImages,
  brands,
  products,
  setProducts,
  setSelectedType,
  handleTypeChange,
  handleImageSelectionChange,
  handleRemoveImage,
  selectedType,
}) => {
  const [searchQueries, setSearchQueries] = useState({});
  const [showProductList, setShowProductList] = useState({});
console.log("Initial Images",initialImages)
  // Initialize or update the `images` state with `product_selected` or `brand_selected`
  useEffect(() => {
    const updatedImages = initialImages.map((img) => ({
      ...img,
      product_selected: !!img.product_id, // Set to true if product_id exists
      brand_selected: !!img.brand_id, // Set to true if brand_id exists
    }));
    setImages(updatedImages);
  }, [setImages]);

  // Fetch products based on search query for each image
  useEffect(() => {
    const fetchProducts = async (query, index) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/cms/get-products-list?search_query=${query}`
        );
        if (response.ok) {
          const data = await response.json();
          setProducts((prevProducts) => ({
            ...prevProducts,
            [index]: data.response,
          }));
        } else {
          console.error("Failed to fetch products:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    Object.keys(searchQueries).forEach((index) => {
      const query = searchQueries[index];
      if (query && query.length > 2) {
        fetchProducts(query, index);
      } else {
        setProducts((prevProducts) => ({
          ...prevProducts,
          [index]: [],
        }));
      }
    });
  }, [searchQueries, setProducts]);

  const handleSearchChange = (index, value) => {
    setSearchQueries((prev) => ({ ...prev, [index]: value }));
    setShowProductList((prev) => ({ ...prev, [index]: true }));
  };

  const handleRadioSelection = (index, type) => {
    const updatedImages = [...initialImages];
    updatedImages[index] = {
      ...updatedImages[index],
      product_selected: type === "product",
      brand_selected: type === "brand",
    };
    setImages(updatedImages);
  };

  const handleProductSelection = (index, type, product) => {
    handleImageSelectionChange(index, type, product._id);

    setSearchQueries((prev) => ({ ...prev, [index]: product.name }));
    setShowProductList((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <div className="h-56 overflow-y-auto">
      {initialImages.map((img, index) => (
        <div key={index} className="flex items-center gap-4 mt-2">
          <img
            src={img.image}
            alt="Slider Image Preview"
            className="h-24 w-24 object-cover"
          />
          <div className="flex flex-col gap-2">
            {/* Radio Button: Product/Brand */}
            <div className="flex gap-2">
              <label>
                <input
                  type="radio"
                  name={`selection-${index}`}
                  value="product"
                  checked={img.product_selected}
                  onChange={() => handleRadioSelection(index, "product")}
                />
                Product
              </label>
              <label>
                <input
                  type="radio"
                  name={`selection-${index}`}
                  value="brand"
                  checked={img.brand_selected}
                  onChange={() => handleRadioSelection(index, "brand")}
                />
                Brand
              </label>
            </div>

            {/* Search Input for Products */}
            {img.product_selected && (
              <div>
                <input
                  type="text"
                  className="p-2 border border-gray-300 rounded-lg text-xs w-full"
                  placeholder="Search Products"
                  value={searchQueries[index] || img.product_name || ""}
                  onChange={(e) => handleSearchChange(index, e.target.value)}
                />

                {/* Display filtered products */}
                {showProductList[index] && products[index]?.length > 0 && (
                  <ul className="mt-2 border border-gray-200 rounded-lg max-h-40 overflow-y-auto">
                    {products[index].map((product) => (
                      <li
                        key={product._id}
                        className="p-2 cursor-pointer hover:bg-gray-100 z-10"
                        onClick={() =>
                          handleProductSelection(index, "product_id", product)
                        }
                      >
                        {product.name}
                      </li>
                    ))}
                  </ul>
                )}

                {/* Show no products found message if applicable */}
                {searchQueries[index]?.length > 2 &&
                  products[index]?.length === 0 && (
                    <p className="text-xs text-gray-500 mt-2">
                      No products found.
                    </p>
                  )}
              </div>
            )}

            {/* Dropdown for Brands */}
            {img.brand_selected && (
              <select
                className="p-2 border border-gray-300 rounded-lg text-xs"
                value={img.brand_id || ""}
                onChange={(e) =>
                  handleImageSelectionChange(index, "brand_id", e.target.value)
                }
              >
                <option value="">Select Brand</option>
                {brands.map((brand) => (
                  <option key={brand._id} value={brand._id}>
                    {brand.brand_name}
                  </option>
                ))}
              </select>
            )}

            {/* Remove Image Button */}
            <button
              onClick={() => handleRemoveImage(index)}
              className="text-red-600 text-xs mt-2"
            >
              Remove Image
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SliderImages;
