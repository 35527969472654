import React, { useState, useEffect } from 'react';
import { User } from '../../admin-panel/user/User';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../hook/getToken';
import RenderHTML from './RenderHTML';
import SideBar from '../Sidebar';
import { ContentWriter } from '../../constant';
import toast from 'react-hot-toast';


const BlogPreview = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null); // State to store blog data
  const token = getToken();
  

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/get/blogs/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const data = await response.json();
     
        if (response.ok) {
          setBlogData(data.data); // Update state with fetched blog data
        } else {
          toast.error(data.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlogData(); // Fetch blog data when component mounts
  }, [id, token]);

  
  return (
    <main className='flex'>
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className='flex flex-col text-left pr-5 min-h-screen'>
        {blogData && (
          <section className='bg-white w-full h-fit z-50'>
            <div className='w-full flex justify-between items-center py-5'>
              <p className='text-2xl text-gray-900 font-semibold'>Blog Preview</p>
              <div className='flex gap-x-10'>
                <User />
              </div>
        
            </div>
            <div className="p-5 border shadow-xl ml-4">
              <div className="flex justify-between">
                <div className="">
                  <h2 className="text-xl font-semibold">{blogData.title}</h2>
                  <p className="text-gray-600">Published at: {new Date(blogData.createdAt).toLocaleString()}</p>
                </div>
                <Link
                  to={{
                    pathname: `/content-writer-dashboard/blog-post/${blogData._id}`,
                    search: `?blogCategoryId=${blogData.blog_category_id}&blogContentId=${blogData.blog_content_id}&type=draft`,
                  }}
                  className='text-blue-500 underline font-bold text-xl'
                >
                  Edit
                </Link>
              </div>
              <div className="text-center">
                <img src={blogData.image} alt={blogData.title} className="my-3 mx-auto max-w-full" style={{ maxWidth: '100%' }} />
              </div>
              <RenderHTML htmlContent={blogData.description} />
            </div>
         
          </section>
        )}
      </div>
    </main>
  );
};

export default BlogPreview;
