// BrandList.js

import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { getToken } from "../../hook/getToken";
import Pagination from "../../Pagination";
import Description from "../../Description";
import { categoryMenu } from "../../constant";
import SideBar from "../Sidebar";
import BrandTableRow from "./BrandTableRow";

const BrandList = () => {
  const [brandData, setBrandData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/category-head/get-brand?status=Approved&search=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const data = await response.json();
        setBrandData(data.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedBrandData = brandData.slice(startIndex, endIndex);

  return (
    <div className="flex">
      <div className="sidebar bg-blue-900 min-h-screen w-fit sticky top-0">
        <SideBar menu={categoryMenu} />
      </div>
      <div className="flex flex-col px-2 py-8 w-full rounded-lg shadow-lg relative">
        <div className="flex-grow flex flex-col">
          <div className=" mb-4 self-end">
            <input
              type="text"
              className="border border-gray-300 rounded px-4 py-2 w-full"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <table className=" w-full">
            <BrandTableRow paginatedBrandData={paginatedBrandData} />
          </table>
        </div>
        <Pagination
          setCurrentPage={setCurrentPage}
          totalItems={brandData.length}
          pageSize={pageSize}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default BrandList;
