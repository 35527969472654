import React, { useState } from 'react'
import PdfViewModal from './PdfViewModal';


const CwProfileDetailsModal = ({onClose,visible,profile}) => {
    const [profileDetails, setProfileDetails] = useState(null);
   
    const [pdfModal, setPdfModal] = useState(false);
    const [cvModal, setCvModal] = useState(false)

    const handleClosePdf = (e) => {
        setPdfModal(false);
        setCvModal(false)
    };
  return (
    <div id="container" className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
    <div className="w-1/2 bg-white p-5 rounded-xl ">
        <h2 className="text-xl font-bold mb-4">Content Writer Profile Details</h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Name :</p>
              <p>{profile.name}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Email :</p>
              <p>{profile.email}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Address :</p>
              <p>{profile.address}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Phone :</p>
              <p>{profile.phone}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Alternate Phone :</p>
              <p>{profile.alter_phone}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Aadhar Number :</p>
              <p>{profile.adhar_no}</p>
            </div>
            <div className='flex border-b gap-x-7'>
            <p className="font-semibold">Aadhar Doc :</p>
            <div className="w-full h-full p-2 cursor-pointer" onClick={() => setPdfModal(true)}>
                  <img
                    className="w-8 h-8 object-contain"
                    src='https://res.cloudinary.com/genx21/image/upload/v1694692016/zngnznqxvjtrpvn45jee.webp'
                    alt=""
                  />
                </div>
                {pdfModal && (
                  <PdfViewModal
                  visible={pdfModal}
                  CloseModal={handleClosePdf}
                  url={profile.adhar_doc}
                  />
                )}
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">CV Document :</p>
              <div className="w-full h-full p-2 cursor-pointer" onClick={() => setCvModal(true)}>
                  <img
                    className="w-8 h-8 object-contain"
                    src='https://res.cloudinary.com/genx21/image/upload/v1694692016/zngnznqxvjtrpvn45jee.webp'
                    alt=""
                  />
                </div>
                {cvModal && (
                  <PdfViewModal 
                  visible={cvModal}
                  CloseModal={handleClosePdf}
                  url={profile.cv_doc}
                  />
                )}
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Portfolio Link :</p>
              <p><a href={profile.portfolio_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{profile.portfolio_link}</a></p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Cost :</p>
              <p>{profile.cost}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">Payment Option :</p>
              <p>{profile.payment_option}</p>
            </div>
            <div className='flex border-b gap-x-7'>
              <p className="font-semibold">UPI ID :</p>
              <p>{profile.upi_id}</p>
            </div>
          </div>
        <div className="flex gap-x-4 justify-center items-center">
        <button
            className="bg-gray-500 text-white px-4 py-2 rounded mt-2"
            onClick={onClose}
        >
            Close
        </button>
        </div>
    </div>
</div>
  )
}

export default CwProfileDetailsModal