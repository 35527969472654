import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";

const ChildBannerUploadModal = ({ onClose, id, banner, mobilebanner }) => {
  const [desktopFile, setDesktopFile] = useState(null);
  const [desktopImageUri, setDesktopImageUri] = useState(banner || ""); // Set initial state with banner prop
  const [mobileFile, setMobileFile] = useState(null);
  const [mobileImageUri, setMobileImageUri] = useState(mobilebanner || ""); // Set initial state with mobilebanner prop

  useEffect(() => {
    // If banner or mobilebanner is passed, update the state
    if (banner) {
      setDesktopImageUri(banner);
    }
    if (mobilebanner) {
      setMobileImageUri(mobilebanner);
    }
  }, [banner, mobilebanner]);

  const handleFileChange = (event, isMobile = false) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width > img.height) {
          if (isMobile) {
            setMobileFile(selectedFile);
            setMobileImageUri(reader.result);
          } else {
            setDesktopFile(selectedFile);
            setDesktopImageUri(reader.result);
          }
        } else {
          alert("Please upload an image where the width is greater than the height.");
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!desktopImageUri || !mobileImageUri) {
      alert("Please select files for both desktop and mobile banners.");
      return;
    }

    const payload = {
      banner: desktopImageUri,
      mobile_banner: mobileImageUri,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/categories/banner-upload/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      toast.success("Images uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading images. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 z-10 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="mx-auto max-w-2xl bg-white rounded ">
        <div className="p-4 border-b bg-gray-100">
          <h1 className="text-sm font-semibold">Upload Banner</h1>
        </div>
        <form className="" onSubmit={handleSubmit}>
          {/* Desktop Banner Upload */}
          <div className="grid grid-cols-2 gap-4 py-2 px-10">
            <div className="flex flex-col">
              <label className="text-sm mb-1">Choose Desktop Banner</label>
              <div className="flex justify-center items-center">
                <input
                  className="border mt-2 p-1"
                  type="file"
                  onChange={(event) => handleFileChange(event)}
                />
              </div>
              {desktopImageUri && (
                <div className="flex justify-center mt-4">
                  <img
                    src={desktopImageUri}
                    alt="Desktop Banner Preview"
                    className="w-full h-48 object-cover rounded-md shadow-md"
                  />
                </div>
              )}
            </div>

            {/* Mobile Banner Upload */}
            <div className="flex flex-col ">
              <label className="text-sm mb-1">Choose Mobile Banner</label>
              <div className="flex justify-center items-center">
                <input
                  className="border mt-2 p-1"
                  type="file"
                  onChange={(event) => handleFileChange(event, true)}
                />
              </div>
              {mobileImageUri && (
                <div className="flex justify-center mt-4">
                  <img
                    src={mobileImageUri}
                    alt="Mobile Banner Preview"
                    className="w-full h-48 object-cover rounded-md shadow-md"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end my-2 gap-5 p-2 border-t">
            <div
              className="py-2 px-10 bg-gray-200 text-sm rounded-sm cursor-pointer"
              onClick={onClose}
            >
              Cancel
            </div>
            <div>
              <button
                className="py-2 px-10 bg-blue-700 text-white text-sm rounded-sm "
                type="submit"
              >
                Add Images
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChildBannerUploadModal;
