import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const AwardListModal = ({ visible, onClose, id, modalName }) => {
  const [fileInputState, setFileInputState] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [bannerInputState, setBannerInputState] = useState("");
  const [selectedBanner, setSelectedBanner] = useState();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedFile(reader.result);
      setFileInputState(e.target.value);
    };
  };

  const handleBannerInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedBanner(reader.result);
      setBannerInputState(e.target.value);
    };
  };

  const updateData = (url, requestBody) => {
    fetch(url, requestBody)
      .then((response) => response.json())
      .then((data) => {
        toast.success(data.message);
        onClose();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error(error.response.data.message);
      });
  };
  

  const [formData, setFormData] = useState({
    award_name: "",
    award_description: "",
    country: "",
    image: "",
    app_banner: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const token = localStorage.getItem("access_token");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      award_name: formData.award_name,
      award_description: formData.award_description,
      country: formData.country,
      image: selectedFile,
      app_banner: selectedBanner,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const url = modalName === "edit"
      ? `${process.env.REACT_APP_URL}/v1/award/update/${id}`
      : `${process.env.REACT_APP_URL}/v1/award/add`;

    updateData(url, requestOptions);

    setFormData({
      award_name: "",
      award_description: "",
      country: "",
      image: "",
      app_banner: "",
    });
    setSelectedFile(null);
    setSelectedBanner(null);
  };

  const [view, setView] = useState({});
  
  useEffect(() => {
    if (modalName === "view" || modalName === "edit") {
      fetch(`${process.env.REACT_APP_URL}/v1/award/single-award/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setView(data.result);
          setFormData({
            award_name: data.result.award_name,
            award_description: data.result.award_description,
            country: data.result.country,
            image: data.result.image,
            app_banner: data.result.app_banner,
          });
          setSelectedFile(data.result.image); // Set initial image preview
          setSelectedBanner(data.result.app_banner); // Set initial banner preview
        });
    }
  }, [modalName, id]);

  if (visible) {
    return (
      <div className="fixed z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="w-1/2 h-96 mx-auto bg-white rounded py-5 px-10 overflow-y-auto no-scrollbar">
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="flex gap-5">
              <div className="w-1/2 flex flex-col">
                <p className="w-fit text-xs text-gray-900 py-1 uppercase">
                  Award name
                </p>
                <input
                  autoComplete="off"
                  onChange={handleChange}
                  name="award_name"
                  disabled={modalName === "view"}
                  value={formData.award_name}
                  placeholder={view?.award_name || ""}
                  className="h-10 my-5 px-4 outline-0 border border-solid border-gray-200 rounded-md"
                  type="text"
                />
              </div>
              <div className="w-1/2 flex flex-col">
                <p className="w-fit text-xs text-gray-900 py-1 uppercase">
                  Logo
                </p>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  className="border mt-6 p-1"
                />
                {(selectedFile || view?.image) && (
                  <img
                    src={selectedFile || view.image}
                    alt="Award Logo Preview"
                    className="mt-2 h-20 w-20 object-cover rounded"
                  />
                )}
              </div>
            </div>

            <p className="w-fit text-xs text-gray-900 py-3 uppercase">Banner</p>
            <input
              onChange={handleBannerInputChange}
              disabled={modalName === "view"}
              type="file"
              className="border mt-2 p-1"
            />
            {(selectedBanner || view?.app_banner) && (
              <img
                src={selectedBanner || view.app_banner}
                alt="App Banner Preview"
                className="mt-2 h-20 w-full object-cover rounded"
              />
            )}

            <p className="w-fit text-xs text-gray-900 py-3 uppercase">
              Country Name
            </p>
            <textarea
              onChange={handleChange}
              name="country"
              value={formData.country}
              placeholder={view?.country || ""}
              disabled={modalName === "view"}
              className="h-10 w-1/2 my-5 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
              type="text"
            />

            <p className="w-fit text-xs text-gray-900 py-3 uppercase">
              Description
            </p>
            <textarea
              maxLength={160}
              onChange={handleChange}
              name="award_description"
              value={formData.award_description}
              placeholder={view?.award_description || ""}
              disabled={modalName === "view"}
              className="h-24 my-5 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
              type="text"
            />

            <div className="flex gap-x-5 justify-center my-5">
              <div
                onClick={onClose}
                className="py-2 px-4 bg-white text-red-600 rounded-sm cursor-pointer"
              >
                Cancel
              </div>
              {modalName !== "view" && (
                <button
                  className="py-2 px-10 bg-[#00388c] text-white rounded-lg uppercase"
                  type="submit"
                >
                  {modalName === "edit" ? "Update" : "Add New"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }

  return null;
};

export default AwardListModal;
