import React, { useEffect, useState } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ShowImage from './ShowImage'
const WFHTemplate1 = ({ data }) => {
    const { heading, sub_heading, image, mob_image, ids } = data;
    const categoriesId = JSON.stringify(ids?.map(d => d.id))
    const [list, setList] = useState([])
    useEffect(() => {
        if (!data.ids) return
        fetch(`${process.env.REACT_APP_URL}/v1/cms/get-filter-content?filter_type=category&ids=${categoriesId}`).then(res => res.json()).then(data => setList(data.response.data))
    }, [data.ids])
    return (
        <div className='flex flex-col'>
            <Heading text={heading} />
            <SubHeading text={sub_heading} />
            <ShowImage image={image} mobImage={mob_image} />
            <div className='my-5 flex flex-row flex-wrap justify-center gap-2 rounded-xl'>
                {
                    list?.map(c => (
                        <div
                            key={c._id}
                            className={`relative shrink-0 basis-[18%] cursor-pointer overflow-hidden rounded-xl`}
                        >
                            <div>
                                <img
                                    loading='lazy'
                                    src={c.image}
                                    alt={c.name}
                                    className='h-16 w-full rounded-md object-cover sm:h-24 md:h-28 lg:h-40'
                                />
                            </div>

                            <div className='absolute inset-x-0 bottom-0 hidden bg-black bg-opacity-10 backdrop-blur sm:block'>
                                <h3 className='text-center text-[10px] font-semibold text-white sm:text-xs lg:text-base lg:leading-10'>
                                    {c.name}
                                </h3>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default WFHTemplate1