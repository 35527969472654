import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getToken } from "../../hook/getToken";
import UploadRecieptModal from "./UploadRecieptModal";
import CwProfileDetailsModal from "./CwProfileDetailsModal";

const PaymentDetailsTable = () => {
  const [list, setList] = useState([]);
  const [profile, setProfile] = useState([]);
  const [error, setError] = useState("");
  const { id } = useParams();
  const token = getToken();
  const [paymentrecieptModal, setPaymentRecieptModal] = useState(false);
  const [profileDetailModal, setProfileDetailModal] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [currentBlogId, setCurrentBlogId] = useState(null); // New state for tracking the current blog ID

  const handlePaymentModal = (blogId) => {
    setCurrentBlogId(blogId);
    setPaymentRecieptModal(true);
  };

  const handleProfileModal = () => {
    setProfileDetailModal(true);
  };

  const handleClose = () => {
    setPaymentRecieptModal(false);
    setProfileDetailModal(false);
    setCurrentBlogId(null); // Reset the current blog ID when closing
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/v1/content-head/blog/payment/${id}`;
      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok && data.success) {
          setList(data.blogs);
          setProfile(data.profile);
        } else {
          setApiMessage(
            data.message || `HTTP error! status: ${response.status}`
          );
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [id, token]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="relative overflow-hidden">
      <div className="flex justify-between">
        <h4 className="text-left text-xl text-[#383E50] font-medium py-2">
          Content Writer Blog Details : {profile.name}
        </h4>
        <div className="flex justify-center items-center" onClick={handleProfileModal}>
          <p className="text-xs underline text-blue-900 cursor-pointer">See Profile Details</p>
        </div>
      </div>
      {profileDetailModal && (
        <CwProfileDetailsModal
          visible={profileDetailModal}
          onClose={handleClose}
          profile={profile}
        />
      )}
      {apiMessage && (
        <div className="flex justify-center items-center text-red-500 mb-4">
          {apiMessage}
        </div>
      )}
      {!apiMessage && (
        <table className="w-full text-left text-xs">
          <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
            <tr>
              <th scope="col" className="px-4 py-2">
                Sl. NO
              </th>
              <th scope="col" className="px-4 py-2">
                Blog Name
              </th>
              <th scope="col" className="px-4 py-2">
                Given Date
              </th>
              <th scope="col" className="px-4 py-2">
                Submit Date
              </th>
              <th scope="col" className="px-4 py-2">
                Approved
              </th>
              <th scope="col" className="px-4 py-2">
                Words
              </th>
              <th scope="col" className="px-4 py-2">
                Status
              </th>
              <th scope="col" className="px-4 py-2">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={item.id} className="border-b">
                <td className="px-4 py-2">{index + 1}</td>
                <td className="px-4 py-2">{item.title}</td>
                <td className="px-4 py-2">{formatDate(item.assing_date)}</td>
                <td className="px-4 py-2">{formatDate(item.submit_date)}</td>
                <td className="px-4 py-2">{formatDate(item.approved_date)}</td>
                <td className="px-4 py-2">{item.description_word_count}</td>
                <td className="px-4 py-2">{item.payment_status}</td>
                <td className="px-4 py-2">
                  <div
                    className="flex justify-center"
                    onClick={() => handlePaymentModal(item._id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {paymentrecieptModal && (
        <UploadRecieptModal
          blogid={currentBlogId} // Use the current blog ID here
          visible={paymentrecieptModal}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default PaymentDetailsTable;
