import React, { useState } from "react";
import { ParentModal } from "./ParentModal";
import Description from "../../Description";
import { getToken } from "../../hook/getToken";
import ParentBannerUploadModal from "./ParentBannerUploadModal";
import Zoom from 'react-medium-image-zoom'

const CategoriesRow = ({ srNo,img, parentName, categoriesId, description, id , status,banner,mobilebanner }) => {
  // State for view modal
  const [viewModal, setViewModal] = useState(false)
  // State for edit 
  const [editModal, setEditModal] = useState(false)
 
  const [selectedStatus, setSelectedStatus] = useState(status);

  const handleClose = () => {
    setViewModal(false)
    setEditModal(false)

  }

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;

    // Update the selected status state
    setSelectedStatus(selectedOption);
    const token = getToken()

    // Send a request to the API with the selected option
    fetch(`${process.env.REACT_APP_URL}/v1/categories/status-update/${id}`, {
      method: 'POST',
      body: JSON.stringify({ id, status: selectedOption }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Replace with your actual Bearer token
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(`Category ${selectedOption}d:`, data);
      // You can add additional logic here if needed
    })
    .catch(error => console.error(`Error ${selectedOption}ing category:`, error));
  };

  const bgColor = selectedStatus === 'active' ? 'bg-green-200' : 'bg-red-200';
  return (
    <tr className="overflow-hidden border-b-2 rounded-b-3xl text-left bg-white transition duration-300 ease-in-out p-2">

      <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
        {srNo}
      </td>
      <td className=" py-2 w-12 h-12 rounded-full ">
       <img className="w-2/5 h-full rounded-full object-fill ml-6 " src={img} alt="" />
      </td>
      <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
        {parentName}
      </td>
      <td className="w-12 h-12 rounded-full">
        <Zoom>
        <img
          className="w-3/5 h-full object-contain ml-4 cursor-pointer"
          src={banner}
          alt=""
         
        />
        </Zoom>
      </td>
      <td className="w-12 h-12 rounded-full">
        <Zoom>
        <img
          className="w-4/5 h-full object-contain ml-4 cursor-pointer"
          src={mobilebanner}
          alt=""
         
        />
        </Zoom>
      </td>
      <td className="px-4 py-2 text-xs font-light text-gray-900">
        <div className="flex items-center justify-center cursor-pointer" onClick={() => setViewModal(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="text-xs text-center">Upload</p>
        </div>
      </td>
      {viewModal && (
        <ParentBannerUploadModal 
        onClose={handleClose}
        id={id}
        banner={banner || ''} 
        mobileBanner={mobilebanner || ''} 
        />
      )}
     
    </tr>
  );
};

export default CategoriesRow;
