import React, { useEffect, useState } from "react";
import { User } from "../user/User";
import EditBlogModal from "./EditBlogCategory";
import SideBar from "../../managerdashboard/Sidebar";
import { ContentHead } from "../../constant";
import Pagination from "../../Pagination";

const BlogPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [modalName, setModalName] = useState("edit");
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint when the component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/blog/get-category?page=${currentPage}&limit=${pageSize}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCategories(data.data); // Update the categories state with fetched data
        setTotalItems(data?.data?.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, [currentPage]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleClose = () => {
    setShowModal(false);
    setSelectedCategory(null); // Reset selected category when modal is closed
  };

  const handleEdit = (category) => {
    // Handle edit action
    setShowModal(true);
    setSelectedCategory(category); // Set the selected category
    setModalName("edit"); // Set modalName to "edit" when editing a category
   
  };

  const handleDelete = (categoryId) => {
    // Handle delete action, you can implement the logic here
    console.log("Deleting category:", categoryId);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-screen-2xl flex flex-row gap-x-5">
      <div className="sidebar bg-[#00388c] h-screen w-fit sticky top-0">
        <SideBar menu={ContentHead} />
      </div>
      <div className="w-full flex flex-col">
        <div className="flex my-4 mr-5">
          <div className="w-full flex-col">
            <div
              className="flex justify-end items-center"
              onClick={() => {
                setShowModal(true);
                setModalName("add"); // Set modalName to "add" when adding a category
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p>Add New Category</p>
            </div>
          </div>
        </div>
        <section>
          <div className="max-w-7xl overflow-hidden rounded-md my-5">
            <table class="w-full text-left text-xs">
              <thead class="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
                <tr>
                  <th scope="col" class="px-2 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-2 py-3">
                    Slug
                  </th>
                  <th scope="col" class="px-2 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {category.category_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {category.slug}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="">
                        <button
                          className="mr-2"
                          onClick={() => handleEdit(category)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                          </svg>
                        </button>
                        <button onClick={() => handleDelete(category.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="py-2">
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              pageSize={pageSize}
              setCurrentPage={handlePageChange}
            />
          </div>
        </section>
        {showModal && (
          <EditBlogModal
            visible={showModal}
            onClose={handleClose}
            modalName={modalName}
            id={selectedCategory ? selectedCategory._id : null}
          />
        )}
      </div>
    </div>
  );
  
};

export default BlogPage;
