import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
const SelectInput = ({ data, index, updateItemValue, sliderIds, selectedId }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const handleSelectChange = (event) => {
        const value = event.target.value;
        const exists = sliderIds.some(d => d.id == value);
        if (exists) {
            return toast.error('Already exist')
        }
        setSelectedValue(value);
        updateItemValue(index, value)
    };
    useEffect(() => {
        if (!selectedId) return
        setSelectedValue(selectedId)
    }, [selectedId])
    return (
        <div className='flex flex-col'>
            <select id="dropdown" value={selectedValue} onChange={handleSelectChange}>
                <option value="option1">select</option>
                {
                    data?.map(i => (
                        <option value={i._id}>{i.name}</option>
                    ))
                }
            </select>
        </div>
    );
};

export default SelectInput;
