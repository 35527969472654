import React, { useState } from 'react'
import { getToken } from '../../hook/getToken'
const orderStatusList = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Pending',
        value: 'pending'
    },
    {
        label: 'Order Placed',
        value: 'order_placed'
    },
    {
        label: 'Processing',
        value: 'processing'
    },
    {
        label: 'Out for Delivery',
        value: 'out_for_delivery'
    },
    {
        label: 'Delivered',
        value: 'delivered'
    },
    {
        label: 'Cancelled',
        value: 'cancelled'
    },
    {
        label: 'Cancel Request',
        value: 'cancel_request'
    },
    {
        label: 'Shipped',
        value: 'shipped'
    },
    {
        label: 'Returned',
        value: 'returned'
    },
    {
        label: 'Return Request',
        value: 'return_request'
    },
    {
        label: 'Return Item Received',
        value: 'return_picked_up'
    }
]
const paymentStatusList = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Paid',
        value: 'paid'
    },
    {
        label: 'Due',
        value: 'due'
    },
    {
        label: 'Refunded',
        value: 'refunded'
    },
    {
        label: 'Cancelled',
        value: 'cancelled'
    },
    {
        label: 'Processing',
        value: 'processing'
    }
]
const DownOrderReport = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        orderStatus: 'all',
        paymentStatus: 'all',
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDownload = async (e) => {
        e.preventDefault();
        const { startDate, endDate, paymentStatus, orderStatus } = formData;

        // Construct the API URL
        let api = `${process.env.REACT_APP_URL}/v1/order/get-report?startDate=${startDate}&endDate=${endDate}`;
        if (paymentStatus !== 'all') {
            api += `&payment_status=${paymentStatus}`;
        }
        if (orderStatus !== 'all') {
            api += `&order_status=${orderStatus}`;
        }

        console.log('API:', api);

        try {
            const res = await fetch(api, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${getToken()}`, // Corrected token type and header name
                },
            });
            if (!res.ok) {
                throw new Error(`Failed to fetch file: ${res.statusText}`);
            }
            const blob = await res.blob();

            // Create a link to download the file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // Set the file name (e.g., report.xlsx)
            a.download = `report-${startDate}-to-${endDate}.xlsx`;

            // Append the link to the document, click it, and remove it
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the object URL to free up memory
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <div className=" fixed inset-0 overflow-x-hidden overflow-y-scroll bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center my-auto p-4">
            <div className="w-[500px] flex flex-col bg-white rounded py-1 px-4">
                <div className="flex justify-end">
                    <button className=" float-right text-3xl leading-none font-semibold"
                        type="button"
                        onClick={() => onClose()}>
                        <span className="hover:scale-110 duration-300 bg-transparent text-[#50C4D9]  text-2xl block">
                            x
                        </span>
                    </button>
                </div>
                <form onSubmit={handleDownload} className='text-left'>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className="flex flex-col gap-2 mb-2">
                            <label htmlFor='startDate' className='text-base '>From</label>
                            <input
                                name='startDate'
                                className='py-2 px-4 rounded-lg border '
                                type="date"
                                value={formData.startDate}
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-2 mb-2">
                            <label htmlFor='endDate' className='text-base ' >To</label>
                            <input
                                id='endDate'
                                name='endDate'
                                className='py-2 px-4 rounded-lg border '
                                type="date"
                                value={formData.endDate}
                                onChange={(e) => handleChange(e)}
                                required
                            />
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className="flex flex-col gap-2 mb-2">
                            <label htmlFor='orderStatus' className='text-base '>Order status</label>
                            <select
                                id='orderStatus'
                                className='py-2 px-4 rounded-lg border '
                                name="orderStatus"
                                value={formData.orderStatus}
                                onChange={(e) => handleChange(e)}
                            >
                                {orderStatusList?.map((d) => (
                                    <option key={d.value} value={d.value}>
                                        {d.label}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div className="flex flex-col gap-2 mb-2">
                            <label htmlFor='paymentStatus' className='text-base '>Payment status</label>
                            <select
                                id='paymentStatus'
                                className='py-2 px-4 rounded-lg border '
                                name="paymentStatus"
                                value={formData.paymentStatus}
                                onChange={handleChange}
                            >
                                {paymentStatusList?.map((d) => (
                                    <option key={d.value} value={d.value}>
                                        {d.label}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>

                    <div className="flex justify-center items-center py-2">
                        <button type='submit' className="bg-blue-900 px-4 py-2 text-white rounded-lg" >
                            Download Report
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default DownOrderReport