import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const UtilityListModal = ({ visible, onClose, id, modalName }) => {
  const [logo, setLogo] = useState();
  const [appBanner, setAppBanner] = useState();
  const [utilityTags, setUtilityTags] = useState([]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (e.target.name === "logoInput") {
          setLogo(reader.result);
        } else if (e.target.name === "appBannerInput") {
          setAppBanner(reader.result);
        }
      };
    }
  };

  const updateData = async (url, requestBody) => {
    try {
      const response = await fetch(url, requestBody);
      const data = await response.json();
      if (data.success) {
        onClose();
        toast.success("Utility updated successfully")
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message)
    }
  };

  const [formData, setFormData] = useState({
    utility_name: "",
    image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const data = {
      utility_name: formData.utility_name,
      image: logo,
      utility_tag: utilityTags.join(","), // Convert array to a single string
      app_banner: appBanner,
    };
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(data),
    };
  
    const url =
      modalName === "edit"
        ? `${process.env.REACT_APP_URL}/v1/utility/edit/${id}`
        : `${process.env.REACT_APP_URL}/v1/utility/add`;
  
    updateData(url, requestOptions);
  
    setFormData({
      utility_name: "",
      image: "",
    });
    setUtilityTags([]); // Clear the tags after submit
  };
  

  useEffect(() => {
    if (modalName === "view" || modalName === "edit") {
      fetch(`${process.env.REACT_APP_URL}/v1/utility/get/${id}`)
        .then((res) => res.json())
        .then((data) => {
          const utilityData = data.utility;
          setFormData({
            utility_name: utilityData.utility_name,
            image: "",
          });
          if (typeof utilityData.utility_tag === "string") {
            setUtilityTags([utilityData.utility_tag]);
          } else {
            setUtilityTags(utilityData.utility_tag);
          }
          setLogo(utilityData.image);
          setAppBanner(utilityData.app_banner);
          console.log(data);
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [id, modalName]);

  if (!visible) return null;

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="mx-auto w-2/5 h-96 bg-white rounded  overflow-y-auto no-scrollbar">
      <div className="px-5 py-2 border-b">
      <h1 className="text-lg text-start font-semibold">Utility Update</h1>
      </div>
        <form onSubmit={handleSubmit} className="w-full flex flex-col justify-start  space-y-4 ">
          <div className=" px-5">
            <p className="text-sm text-start text-gray-900 py-1 uppercase">Utility Name</p>
            <textarea
              onChange={handleChange}
              name="utility_name"
              value={formData.utility_name}
              className="h-8 w-full p-2 outline-none border border-gray-200 resize-none rounded-md"
              disabled={modalName === "view"}
              placeholder="Enter utility name"
              required
            />
          </div>
          <div className="px-5">
            <p className="text-sm text-start text-gray-900 py-1 uppercase">Utility Tags</p>
            <input
              onChange={(e) => setUtilityTags(e.target.value.split(","))}
              value={utilityTags.join(",")}
              disabled={modalName === "view"}
              type="text"
              placeholder="Enter utility tags separated by commas"
              className="h-8 w-full p-2 outline-none border border-gray-200 resize-none rounded-md"
            />
          </div>
          <div className="flex flex-col justify-start items-start px-5">
            <p className="text-sm text-gray-900 py-1 uppercase">Logo</p>
            <input
              onChange={handleFileInputChange}
              disabled={modalName === "view"}
              type="file"
              name="logoInput"
              className="border items-start p-2"
            />
            {logo && (
              <div className="mt-2">
                <p>Logo Preview:</p>
                <img
                  src={logo}
                  alt="Logo Preview"
                  className="w-24 h-24 object-cover"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col items-start px-5">
            <p className="text-sm text-gray-900 py-1 uppercase">App Banner</p>
            <input
              onChange={handleFileInputChange}
              disabled={modalName === "view"}
              type="file"
              name="appBannerInput"
              className="border p-2"
            />
            {appBanner && (
              <div className="mt-2">
                <p>App Banner Preview:</p>
                <img
                  src={appBanner}
                  alt="App Banner Preview"
                  className="w-48 h-24 object-cover"
                />
              </div>
            )}
          </div>

          <div className="flex justify-center space-x-2 border-t p-3">
            <div
              onClick={onClose}
              className="py-2 px-4 bg-white text-red-600 rounded cursor-pointer"
            >
              Cancel
            </div>
            {modalName !== "view" && (
              <button
                className="py-2 px-10 bg-[#00388c] text-white rounded uppercase cursor-pointer"
                type="submit"
              >
                {modalName === "edit" ? "Edit" : "Add New"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UtilityListModal;
