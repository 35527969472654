'use client'
import React, { useState } from 'react'
import RemoveModal from './RemoveModal'
const Remove = ({ index }) => {
    const [isClicked, setIsClicked] = useState()

    return (
        <>
            <button
                type='button'
                onClick={() => setIsClicked(true)}
                className='border p-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700 ">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

            </button>
            {
                isClicked && <RemoveModal
                    visible={isClicked}
                    onClose={setIsClicked}
                    index={index}
                />
            }
        </>
    )
}

export default Remove