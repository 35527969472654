import React from "react";
import { useState, useEffect } from "react";
import AddSeodataModal from "./AddSeodataModal";
import TableRow from "./TableRow";
import { getToken } from "../../hook/getToken";
const ManageSeodata = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/v1/seo/get`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setData(data.seoData)
                }
            });
    }, [isModalOpen]);
    return (
        <div className="pr-6 py-10 text-sm font-semibold">
            <div>
                <div className="flex items-left items-center py-3">
                    {" "}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <p className="text-2xl">Manage Seodata</p>
                </div>
                <div className="flex px-4 text-[#0056fe] tex-xs"></div>
            </div>

            <div className="max-w-6xl mx-auto flex justify-end items-center mt-5">
                <div className="flex justify-start items-center my-4">
                    <div className="flex-col justify-center items-center">
                        <div className="flex justify-center items-center space-x-1 cursor-pointer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <p onClick={() => {
                                setIsModalOpen(true)
                            }}>Add Seo Data</p>
                            {isModalOpen && (
                                <AddSeodataModal visible={isModalOpen} onClose={() => setIsModalOpen(false)} />
                            )}
                        </div>
                    </div>
                </div>

            </div>

            <section>
                <div className="max-w-6xl mx-auto  rounded-t-xl my-5">
                    {
                        data.length === 0 ? (
                            <div className="flex justify-center items-center">
                                <p className="text-xl">No data found</p>
                            </div>
                        ) : null
                    }
                    {
                        data && data.length > 0 ? (
                            <table className="table min-w-full border  border-solid">
                                <thead className="bg-[#e5f2f4]">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-4 py-2 text-left text-sm font-medium text-gray-900"
                                        >
                                            Page Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-2 text-left text-sm font-medium text-gray-900"
                                        >
                                            Title
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-4 py-2 text-left text-sm font-medium text-gray-900"
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-2 text-left text-sm font-medium text-gray-900"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((items, index) => (
                                        <TableRow
                                            key={items._id}
                                            data={items}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        ) : null
                    }

                </div>
            </section>
        </div>
    );
};

export default ManageSeodata;
