'use client'
import React, { useEffect, useState } from 'react'
const SelectBrand = React.memo(({ id, name, value, onChange, index, brandList }) => {

    return (
        <div className="mt-2 ">
            <label htmlFor={id} className="text-left block mb-2 text-sm  text-gray-700 ">Redirect Link</label>
            <input
                name={name}
                id={id}
                value={value}
                onChange={(e) => onChange(index, e.target.value)}
                className='appearance-none block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm'
            />
        </div>
    )
})


export default SelectBrand