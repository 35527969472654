import React from 'react';
import { toast } from 'react-hot-toast';

const ConfirmationModal = ({ notifyType, show, onClose, userId }) => {
    if (!show) return null;

    const sendPushNotification = async () => {

        const body = {
            templateKey: notifyType === 'cart' ? 'cart_product' : notifyType === 'wishlist' ? 'wishlist_product' : 'new_offers',
            userId: userId,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/clevertap/send-push-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Push notification sent successfully');
                toast.success(data.message);
                onClose();
            } else {
                console.error('Failed to send push notification');
            }
        } catch (error) {
            console.error('Error sending push notification:', error);
        }
    };

    const handleProceed = () => {
        sendPushNotification();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Confirmation</h3>
                    <div className="mt-2">
                        <p>Are you sure you want to proceed?</p>
                    </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleProceed()}
                    >
                        Proceed
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;