// DraftTask.js
import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import { ContentWriter } from "../../../constant";
import { Link } from "react-router-dom";
import { getToken } from "../../../hook/getToken";
// Import the function to retrieve the access token

const DraftTask = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const accessToken = getToken(); // Retrieve the access token
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/content-head/get/blogs?status=draft&page=1&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Include the access token in the request headers
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blogs");
        }
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <main className="max-w-6xl flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className="flex-grow p-8">
        <h1 className="text-2xl font-bold mb-4">Draft Blogs</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {blogs.map((blog) => (
            <Link
              key={blog._id}
              to={{
                pathname: `/content-writer-dashboard/blog-post/${blog._id}`,
                search: `?blogCategoryId=${blog.blog_category_id}&blogContentId=${blog.blog_content_id}&type=draft`,
              }}
            >
              <div className="bg-white rounded-lg shadow-md p-4 cursor-pointer">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-48 object-cover mb-4 rounded-lg"
                />
                <h2 className="text-xl font-semibold">{blog.title}</h2>
                <p className="text-gray-600 mb-2">
                  Category: {blog.blog_category}
                </p>
                <p className="text-gray-600">Status: {blog.blog_status}</p>
                {/* <p className="text-gray-600">{blog.description}</p> */}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
};

export default DraftTask;
