import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { getToken } from '../../../hook/getToken';
const ManageAppovalModal = ({ status, id }) => {
    const [isClicked, setIsClicked] = useState(false)

    const handleStatus = async () => {
        const value = status == 'approved' ? 'declined' : 'approved'

        try {
            const res = await fetch(`${process.env.REACT_APP_URL}/v1/cms/approve-content-status?content_id=${id}`, {
                method: 'PUT', // Specify the POST method
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify({
                    approval_status: value,
                }),
            });
            if (res.ok) {
                window.location.reload()
            }
            if (!res.ok) {
                const data = await res.json();
                toast.error(data.message)
                throw new Error('Network response was not ok');
            }

            // Parse the JSON response

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className='flex justify-start'>
                <button onClick={() => setIsClicked(true)}
                    className='w-24 py-2  border rounded'
                >
                    {status === 'approved' ? "Decline" : "Approve"}
                </button>
            </div>
            {isClicked &&
                (
                    <div className='fixed z-40 inset-0 bg-black bg-opacity-30 flex justify-center items-center '>
                        <div className='flex flex-col w-[500px]  bg-white rounded-lg  overflow-hidden'>

                            <div className='px-5 py-2'>
                                <h5 className='text-xl font-medium '>Are you sure you want to change the status ?</h5>
                                <div className='flex space-x-2 justify-end items-center mt-5 px-5'>
                                    <button
                                        onClick={() => setIsClicked(false)}
                                        type="button"
                                        className="text-sm py-1.5 px-12 bg-red-500 rounded text-white"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        onClick={() => handleStatus()}
                                        type='button'
                                        className='text-sm py-1.5 px-12 bg-blue-500 rounded text-white'>
                                        Done
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }

        </>
    )
}

export default ManageAppovalModal