import { da } from "date-fns/locale";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Select from "react-select";


const PushNotificationModal = ({ userId, onClose }) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/products/get-products-cms?limit=400`
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data.productList);
          const productOptions = data.productList.map((product) => ({
            value: product._id,
            label: product.item_name,
          }));
          setProducts(productOptions);
        } else {
          console.error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const sendPushNotification = async () => {
    if (!selectedProduct || !imageUrl) {
      console.error('Product and image URL must be selected');
      return;
    }

    const body = {
      templateKey: "new_offers",
      userId: userId,
      productId: selectedProduct.value,
      background_image: imageUrl
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/clevertap/send-push-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Push notification sent successfully');
        toast.success(data.message);
        onClose();
      } else {
        console.error('Failed to send push notification');
      }
    } catch (error) {
      console.error('Error sending push notification:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendPushNotification();
    console.log({ title, message, imageUrl, selectedProduct });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white  rounded-lg shadow-lg max-w-md w-full ">
        <div className="flex justify-between p-3 border-b">
          <h1 className="text-start font-semibold">
            Create message for default{" "}
          </h1>
          <button
            onClick={onClose}
            className=" text-gray-500 hover:text-gray-700"
          >
            X
          </button>
        </div>
        <form className="p-3" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-start text-gray-700 text-sm font-bold mb-2">
              Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-start text-gray-700 text-sm font-bold mb-2">
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-start text-gray-700 text-sm font-bold mb-2">
              Image URL:
            </label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-start text-gray-700 text-sm font-bold mb-2">
              Product
            </label>
            <Select
              options={products}
              value={selectedProduct}
              onChange={setSelectedProduct}
              isSearchable
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-blue-800 hover:bg-blue-900 text-xs text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PushNotificationModal;
