import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Downblock = ({ orderData }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (orderData) {
      setIsLoading(false);
    }
  }, [orderData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {orderData && (
        <div className="flex space-x-4">
          <Link
            className="flex-1 pl-8 pr-14 py-4 text-left bg-white hover:bg-blue-100 border border-blue-300 rounded-md hover:shadow-2xl shadow-lg"
            to="/contactlist"
          >
            <div>
              <p className="text-lg">Contacts</p>
              <p className="text-xl font-bold">5</p>
            </div>
          </Link>

          <div className="flex-1 pl-8 pr-14 py-4 text-left bg-white hover:bg-blue-100 border border-blue-300 rounded-md hover:shadow-2xl shadow-lg">
            <Link to="/brands" className="text-lg">
              Brands
            </Link>
            <div className="flex justify-between items-end">
              <p className="text-xl font-bold">{orderData?.total_brands}</p>
              <img src="../assets/admin-panel/graph.svg" alt="Graph Icon" />
            </div>
          </div>
          {/* You can add more statistics here */}
        </div>
      )}
    </div>
  );
};
