import React, { useState, useEffect } from 'react';
import SideBar from '../../Sidebar';
import { ContentWriter } from '../../../constant';
import { getToken } from '../../../hook/getToken';
import toast from 'react-hot-toast';

const CwProfile = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    phone: '',
    alter_phone: '',
    cv_doc: null,
    portfolio_link: '',
    portfolio_doc: null,
    cost: '',
    payment_option: '',
    ac_no: '',
    ac_name: '',
    ifsc_code: '',
    upi_id: '',
    adhar_no: '',
    adhar_doc: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = getToken();

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/profile/get`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          const { _id,bloger_id,adhar_doc_public_id,cv_doc_public_id,portfolio_doc_public_id,createdAt,updatedAt,__v, ...profileData } = data.data; // Destructure to remove _id
          setFormData((prevFormData) => ({
            ...prevFormData,
            ...profileData // Assuming the response structure matches formData keys
          }));
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileDetails();
  }, [accessToken]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    for (const key in formData) {
      // Exclude _id from the payload
      if (key !== '_id') {
        data.append(key, formData[key]);
      }
    }
    console.log("Payload", formData);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/profile/update`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log("Result", result);
      toast.success(result.message);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      toast.error('There was a problem updating the profile.');
    }
  };

  if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <main className="flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className="p-4 w-full mx-4 border shadow">
        <h1 className="text-2xl font-bold mb-4">Content Writer Profile</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                placeholder="Name"
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="address"
                value={formData.address}
                placeholder="Address"
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <input
                type="tel"
                name="alter_phone"
                placeholder="Alternative Phone Number"
                value={formData.alter_phone}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="flex gap-x-4">
              <label className="block text-sm font-medium text-gray-700">CV Upload:</label>
              <input
                type="file"
                name="cv_doc"
                onChange={handleChange}
                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
              />
            </div>
            <div>
              <input
                type="url"
                name="portfolio_link"
                placeholder="Portfolio Link"
                value={formData.portfolio_link}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex">
              <label className="block text-sm font-medium text-gray-700">Portfolio Upload</label>
              <input
                type="file"
                name="portfolio_doc"
                onChange={handleChange}
                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
              />
            </div>
            <div>
              <input
                type="text"
                name="adhar_no"
                placeholder="Aadhar Number"
                value={formData.adhar_no}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="flex">
              <label className="block text-sm font-medium text-gray-700">Aadhar Card Upload:</label>
              <input
                type="file"
                name="adhar_doc"
                onChange={handleChange}
                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
              />
            </div>
            <div className='flex'>
              <label className='text-xs font-semibold' htmlFor="">Cost per word in paisa</label>
              <input
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleChange}
                placeholder="Cost per word"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="col-span-2 flex justify-start items-center space-x-2">
              <label className="block text-sm font-medium text-gray-700">Payment Option:</label>
              <div className="flex space-x-2">
                <div>
                  <input
                    type="radio"
                    id="netBanking"
                    name="payment_option"
                    value="netBanking"
                    checked={formData.payment_option === 'netBanking'}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <label htmlFor="netBanking" className="text-gray-700">Net Banking</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="upi"
                    name="payment_option"
                    value="upi"
                    checked={formData.payment_option === 'upi'}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <label htmlFor="upi" className="text-gray-700">UPI</label>
                </div>
              </div>
            </div>
            {formData.payment_option === 'netBanking' && (
              <div className="col-span-1 md:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Bank Account No</label>
                  <input
                    type="text"
                    name="ac_no"
                    value={formData.ac_no}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Bank Name</label>
                  <input
                    type="text"
                    name="ac_name"
                    value={formData.ac_name}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">IFSC Code</label>
                  <input
                    type="text"
                    name="ifsc_code"
                    value={formData.ifsc_code}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
              </div>
            )}
            {formData.payment_option === 'upi' && (
              <div className="col-span-1 md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">UPI ID</label>
                <input
                  type="text"
                  name="upi_id"
                  value={formData.upi_id}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default CwProfile;
