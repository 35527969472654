import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const PreviewModal = ({ onClose }) => {
  const [screenData, setScreenData] = useState([]);

  // Fetch splash screen data
  const fetchScreenData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/app/splash-screen/get?status=true`
      );
      const data = await response.json();
      setScreenData(data.data || ""); // Assuming the data structure contains the splash screens in 'data'
    } catch (error) {
      console.error("Error fetching splash screen data:", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchScreenData();
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-md max-w-sm w-full h-fit">
        <div className="flex justify-between items-center p-3">
          <h2 className="text-sm font-bold">Preview Splash Screens</h2>
          <button onClick={onClose} className="text-lg font-bold text-red-500">
            X
          </button>
        </div>

        {/* Swiper Slider */}
        {screenData.length > 0 ? (
          <Swiper
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper mt-4"
            style={{ paddingBottom: "40px" }}
          >
            {screenData.map((item) => (
              <SwiperSlide key={item._id}>
                <div className="flex flex-col bg-gray-50 items-center">
                  {/* Image */}
                  <img
                    src={item.image}
                    alt="Splash Screen"
                    className="w-4/5 h-60 object-contain  mb-4"
                  />

                  {/* Heading and Description */}
                  <div className="bg-white p-5 text-center rounded-tl-2xl rounded-tr-2xl">
                    <h3 className="text-lg font-semibold mb-2">
                      {item.heading}
                    </h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <style>
              {`
    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;
      background-color: #000;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: #007bff;
      width: 6px;
      height: 6px;
    }
  `}
            </style>
          </Swiper>
        ) : (
          <p className="text-center mt-4">No splash screens available.</p>
        )}
      </div>
    </div>
  );
};

export default PreviewModal;
