import React from "react";
import { useState } from "react";
import AddSeodataModal from "./AddSeodataModal";
const TableRow = ({ data }) => {
    const { pageName, title, description, keywords, _id } = data;
    const [editModal, setEditModal] = useState(false);

    const handleClose = () => {
        setEditModal(false);
    }
    console.log('data', data, editModal);
    return (
        <tr className="overflow-hidden border-b-2 text-left bg-white transition duration-300 ease-in-out">

            <td className="whitespace-nowrap px-4 py-2 text-xs font-light text-gray-900">
                {pageName}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-xs font-light text-gray-900">
                {title}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-xs font-light text-gray-900">
                {description.length > 80 ? description.slice(0, 80) + '...' : description}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-xs font-light text-gray-900">
                <div className="flex gap-2">
                    <div onClick={() => setEditModal(true)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                        </svg>
                    </div>
                    {editModal && <AddSeodataModal
                        visible={editModal}
                        onClose={handleClose}
                        data={data}
                        modalName='edit'
                    />}
                </div>
            </td>
        </tr>
    );
};

export default TableRow;
