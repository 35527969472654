import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const SubBannerUploadModal = ({ onClose, id,banner,mobileBanner }) => {
  const [desktopFile, setDesktopFile] = useState(null);
  const [desktopImageUri, setDesktopImageUri] = useState(banner || ""); // Set initial state with banner prop
  const [mobileFile, setMobileFile] = useState(null);
  const [mobileImageUri, setMobileImageUri] = useState(mobileBanner || ""); // Set initial state with mobile_banner prop

  useEffect(() => {
    // If banner or mobile_banner is passed, update the state
    if (banner) {
      setDesktopImageUri(banner);
    }
    if (mobileBanner) {
      setMobileImageUri(mobileBanner);
    }
  }, [banner, mobileBanner]);

  const handleFileChange = (event, isMobile = false) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width > img.height) {
          if (isMobile) {
            setMobileFile(selectedFile);
            setMobileImageUri(reader.result);
          } else {
            setDesktopFile(selectedFile);
            setDesktopImageUri(reader.result);
          }
        } else {
          alert("Please upload an image where the width is greater than the height.");
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!desktopImageUri || !mobileImageUri) {
      alert("Please select files for both desktop and mobile banners.");
      return;
    }

    const payload = {
      banner: desktopImageUri,
      mobile_banner: mobileImageUri,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/categories/banner-upload/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      toast.success("Images uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading images. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-10">
      <div className="bg-white rounded-lg max-w-xl">
        <div className="flex justify-start items-center border-b bg-gray-50 p-2">
          <h1 className="text-lg font-semibold">Upload Banners</h1>
        </div>
        <form className="space-x-8 items-center p-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 w-full mb-4">
            <div className="w-full">
              <label className="text-sm mb-1" htmlFor="desktop-banner">Choose Desktop Banner</label>
              <input
                className="border p-2 w-4/5 rounded mb-4"
                type="file"
                id="desktop-banner"
                onChange={(event) => handleFileChange(event)}
              />
              {desktopImageUri && (
                <div className="w-full mb-4">
                  <img
                    src={desktopImageUri}
                    alt="Desktop Banner Preview"
                    className="w-4/5 h-48 object-cover rounded-md shadow-md"
                  />
                </div>
              )}
            </div>

            <div className="w-full mb-4">
              <label className="text-sm mb-1" htmlFor="mobile-banner">Choose Mobile Banner</label>
              <input
                className="border p-2 w-4/5 rounded mb-4"
                type="file"
                id="mobile-banner"
                onChange={(event) => handleFileChange(event, true)}
              />
              {mobileImageUri && (
                <div className="w-full mb-4">
                  <img
                    src={mobileImageUri}
                    alt="Mobile Banner Preview"
                    className="w-4/5 h-48 object-cover rounded-md shadow-md"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-x-4">
            <button
              className="py-2 px-4 bg-red-400 text-white rounded hover:bg-red-500"
              onClick={onClose}
              type="button"
            >
              Cancel
            </button>
            <button
              className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
              type="submit"
            >
              Add Images
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubBannerUploadModal;
