import React from "react";
import Description from "../../Description";

const ContactListRow = ({ data, index }) => {
  return (
    <>

      <tr
        key={data._id}
        className="overflow-hidden border-b-2 rounded-b-3xl bg-white transition duration-300 ease-in-out"
      >
        <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
          {index + 1}
        </td>
        <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
          {data.name}
        </td>
        <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
          {data.phone}
        </td>
        <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
          {data.email}
        </td>
        <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
          {data.subject}
        </td>
        <td className="px-6 py-2 text-xs font-light text-gray-900">
          <Description description={data.message} />
        </td>
      </tr>
    </>
  );
};

export default ContactListRow;
