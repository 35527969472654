import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ value, onChange, name, isRequired }) => {
    const handleEditorChange = (content) => {
        onChange(name, content);
    };

    return (
        <div>
            <ReactQuill value={value} onChange={handleEditorChange} />
        </div>
    );
};

export default Editor;
