'use client'
import React from 'react'
import Module from './Module'
import { useCms } from '../../context/CmsProvider'
const AddModuleButton = () => {
    const { isAddModuleModal, setIsAddModuleModal } = useCms()
    const handleClick = () => {
        setIsAddModuleModal(true)
    }
    return (
        <>
            <button
                onClick={() => handleClick()}
                className='py-2 px-4 border border-gray-200 text-gray-500 font-semibold rounded-sm'>
                AddModuleButton
            </button>
            {
                isAddModuleModal && <Module />
            }
        </>
    )
}

export default AddModuleButton