import React, { useState, useEffect } from "react";
import getList from "../getList";
import { getToken } from "../../hook/getToken";
import { useNavigate } from "react-router-dom";
// import CmModal from './CmModal';
// import SellerModal from './SellerModal';
// import BrandModal from './BrandModal';
// import ProductModal from './ProductModal';
// import PendingProductModal from './PendingProduct';

const Stats = () => {
  const [statData, setStatData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_URL}/v1/content-head/stats`;
    const token = getToken();
    getList(url, token)
      .then((data) => {
        setStatData(data.stats);
        console.log(data.stats);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleItemClick = (itemName) => {
    switch (itemName) {
      case "TOTAL CWs":
        navigate("/content-head-dashboard/content-writers");
        break;
      case "Approved Blogs":
        navigate("/content-head-dashboard/approved-blogs");
        break;
      case "Declined Blogs":
        navigate("/content-head-dashboard");
        break;
      case "Total Content":
        navigate("/content-head-dashboard/blog-list");
        break;
      case "Total Assing Content":
        navigate("/content-head-dashboard");
        break;
      case "Pending Assigned Content":
        navigate("/content-head-dashboard");
        break;
      case "Total Blog Category":
        navigate("/content-head-dashboard/add-blog-category");
        break;
      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <img
          className="h-16 w-16"
          src="../../assets/admin-panel/loading.gif"
          alt=""
        />
      </div>
    );
  }

  return (
    <section className="grid grid-cols-5 gap-5 my-10">
      {statData?.map((item, index) => (
        <div
          key={item.id}
          className="ring-1 bg-white rounded-xl shadow-md hover:shadow-2xl p-5 cursor-pointer"
          onClick={() => handleItemClick(item.name)}
        >
          <div>
            <p className="text-base text-gray-500">{item.name}</p>
            <p className="text-3xl font-semibold">{item.count}</p>
          </div>
        </div>
      ))}

      {/* {showCmModal && (
        <CmModal visible={true} onClose={() => setShowCmModal(false)} />
      )} */}

      {/* {showSellerModal && (
        <SellerModal visible={true} onClose={() => setShowSellerModal(false)} />
      )}
      {showBrandModal && (
        <BrandModal visible={true} onClose={() => setShowBrandModal(false)} />
      )}
      {showProductModal && (
        <ProductModal visible={true} onClose={() => setShowProductModal(false)} />
      )}
      {showPendingModal && (
        <PendingProductModal visible={true} onClose={() => setShowPendingModal(false)} />
      )} */}
    </section>
  );
};

export default Stats;
