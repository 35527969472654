import React, { useState, useEffect, useCallback } from 'react';
import getList from '../getList';
import { getToken } from '../../hook/getToken';
import EyeButton from './EyeButton';
import { useNavigate } from "react-router-dom";
import AssignCWModal from './AssignCWModal';
import Pagination from '../../Pagination';
import AddRequiredBlogModal from './AddRequiredBlogModal';
import toast from 'react-hot-toast';
import Description from '../../Description';

// Utility function to format the date
const formatDate = (dateString) => {
    if (dateString === 'N/Y') {
        return 'N/Y';
    }
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};

const RequiredBlogTable = () => {
    const navigate = useNavigate()
    const [list, setList] = useState([]);
    const [showassignCWModal, setAssignCWModal] = useState(false);
    const [selectedBlogId, setSelectedBlogId] = useState(null);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(""); // State to hold selected brand ID
    const [selectedBlogType, setSelectedBlogType] = useState(""); // State to hold selected blog type ID
    const [selectedBlogStatus, setSelectedBlogStatus] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [showBlogModal, setShowBlogModal] = useState(false)
    const [modalName, setModalName] = useState("edit")


    const handleAddBlogRequirement = (blogId) => {
        setSelectedBlogId(blogId)
        setShowBlogModal(true);
    };
    const handleCloseBlogRequirement = () => {
        setShowBlogModal(false);
    };

    useEffect(() => {
        // Function to fetch data based on filters
        // Function to fetch data based on filters
        const fetchData = async () => {
            try {
                const token = getToken();
                let url = `${process.env.REACT_APP_URL}/v1/content-head/get/blog-requirement?page=${currentPage}&limit=${pageSize}`;
                const queryParams = [];
                if (selectedBrand) {
                    queryParams.push(`brand_id=${selectedBrand}`);
                }
                if (selectedBlogStatus) {
                    queryParams.push(`isAssing=${selectedBlogStatus === "Assigned"}`);
                }
                if (selectedBlogType) {
                    queryParams.push(`blog_type=${selectedBlogType}`);
                }
                if (queryParams.length > 0) {
                    url += `&${queryParams.join("&")}`;
                }
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setList(data.data);
                console.log("Data?>>",data.data)
                setTotalItems(data.pagination.total);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [selectedBrand, selectedBlogType, selectedBlogStatus, currentPage]);

console.log("Outside",list,currentPage)

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/v1/brand-registration/all-active-brand`);
                const data = await response.json();
                setBrands(data.brands);
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        };
        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL}/v1/blog/get-category`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setCategories(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleAssignContentWriter = (blogId) => {
        setSelectedBlogId(blogId);
        setAssignCWModal(true);
    };

    const handleCloseAssignCwModal = () => {
        setAssignCWModal(false);
    };

    // Function to handle blog status change
    // Function to handle blog status change
    const handleBlogStatusChange = (e) => {
        const status = e.target.value;
        setSelectedBlogStatus(status);
        console.log("Blog status>>>", selectedBlogStatus)
    };

    const handleDeassignContentWriter = async (contentId) => {
        try {
            const token = getToken();
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/deassign/content/${contentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to deassign content writer');
            }
            // const updatedList = list.map(item => {
            //     if (item._id === contentId) {
            //         return { ...item, isAssing: false };
            //     }
            //     return item;
            // });
            // setList(updatedList);
        } catch (error) {
            console.error('Error deassigning content writer:', error);
        }
    };



    const handleClick = useCallback((id, taskStatus) => {
        if (taskStatus === "Review" || taskStatus === "Done") {
            const url = `/content-head-dashboard/blog-preview/${id}?taskStatus=${taskStatus}`;
            navigate(url);
        } else {
            toast.error("You have not submitted the blog yet.");
        }
    }, [navigate]);

    const handleBrandChange = (e) => {
        setSelectedBrand(e.target.value);
    };

    const handleBlogTypeChange = (e) => {
        setSelectedBlogType(e.target.value);
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="relative overflow-hidden">
            <h4 className='text-left text-xl text-[#383E50] font-medium py-2'>List of Required Blogs </h4>
            <div className="flex justify-end items-center gap-x-4">
                <div className="flex justify-center items-center gap-x-2">
                    <h1 className='text-xs font-bold'>Filter By Blog Status</h1>
                    <select className="my-4 border rounded shadow-sm text-xs font-semibold" onChange={handleBlogStatusChange}>
                        <option value="">All</option>
                        <option value="Assigned">Assigned</option>
                        <option value="Not Assigned">Not Assigned</option>
                    </select>
                </div>
                <div className="flex justify-center items-center gap-x-2">
                    <h1 className='text-xs font-bold'>Filter By Blog Type</h1>
                    <select className="my-4 border rounded shadow-sm text-xs font-semibold" onChange={handleBlogTypeChange}>
                        <option value="">All</option>
                        {categories.map((category) => (
                            <option key={category._id} value={category._id}>{category.category_name}</option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-center items-center gap-x-2">
                    <h1 className='text-xs font-bold'>Filter By Brand</h1>
                    <select className="my-4 border rounded shadow-sm text-xs font-semibold" onChange={handleBrandChange}>
                        <option value="">All</option>
                        {brands.map((brand) => (
                            <option key={brand._id} value={brand._id}>{brand.brand_name}</option>
                        ))}
                    </select>
                </div>

            </div>

            <table className="w-full text-left text-xs">
                <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
                    <tr>
                        <th scope="col" className="px-4 py-2">
                            Sl. NO
                        </th>

                        <th scope="col" className="px-4 py-2">
                            Blog type
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Blog topic
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Brands
                        </th>

                        <th scope="col" className="px-4 py-2">
                            Blog Status
                        </th>

                        <th scope="col" className="px-4 py-2">
                            Working Status
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Submission Date
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Assign
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Assigned To
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Assigned Date
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Actions (View Details)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(list) &&
                        list.map((item, index) => {
                            const { _id, blog_type, blog_topic, brand_name, cw_name, isAssing, isDone, task_status,assign_date,submit_date } = item;

                            return (
                                <tr key={_id}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">{blog_type}</td>
                                    <td className="px-4 py-2">
                                        <Description description={blog_topic} /></td>
                                    <td className="px-4 py-2">{brand_name}</td>

                                    <td className="px-4 py-2">{isDone ? 'Done' : 'Pending'}</td>
                                    <td className="px-4 py-2">{task_status}</td>
                                    <td className="px-4 py-2">{formatDate(submit_date)}</td>
                                    <td className="px-4 py-2">{isAssing ? 'Assigned' : 'Not Assigned'}</td>
                                    <td className="px-4 py-2">{cw_name}</td>
                                    <td className="px-4 py-2">{formatDate(assign_date)}</td>
                                    <td className="px-4 py-2">
                                        <div className='flex gap-6 '>
                                            <div className="" onClick={() => handleAddBlogRequirement(_id)}> {/* Corrected */}
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                </svg>
                                            </div>
                                            {showBlogModal && <AddRequiredBlogModal isOpen={showBlogModal} onClose={handleCloseBlogRequirement} modalName={modalName} id={selectedBlogId} />}
                                            <div onClick={() => handleAssignContentWriter(_id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                </svg>
                                            </div>
                                            <div onClick={() => handleDeassignContentWriter(_id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                                </svg>

                                            </div>
                                            {showassignCWModal && <AssignCWModal isOpen={showassignCWModal} onClose={handleCloseAssignCwModal} contentId={selectedBlogId} />}
                                            <EyeButton id={_id} onClick={() => handleClick(_id, task_status)} />

                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
            <div className="py-2">
                <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    setCurrentPage={handlePageChange}
                />
            </div>
        </div>
    )
}

export default RequiredBlogTable;
