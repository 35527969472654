import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../hook/getToken";

const AddRequiredBlogModal = ({ isOpen, onClose, modalName, id }) => {
  console.log("ID>>>",id)
  const navigate = useNavigate();
  const token = getToken()
  const [formData, setFormData] = useState({
    blog_type: "",
    blog_topic: "",
    brand_id: "",
    product_id: "",
    keywords: [],
    description: "", // Add description field to formData
  });
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch data from the API endpoint when the component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/blog/get-category`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCategories(data.data); // Update the categories state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts


  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle keywords input changes
  const handleKeywordsChange = (e) => {
    const keywords = e.target.value
      .split(",")
    setFormData((prevData) => ({
      ...prevData,
      keywords: keywords,
    }));
  };
  // Function to handle description input changes
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      description: value,
    }));
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  // Function to fetch brands from the backend
  const fetchBrands = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/brand-registration/all-active-brand`);
      const data = await response.json();
      setBrands(data.brands); // Assuming data is an array of brand objects with properties like _id and name
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // Fetch products based on selected brand
  useEffect(() => {
    const fetchProducts = async () => {
      if (formData.brand_id) {
        try {
          const response = await fetch(`${process.env.REACT_APP_URL}/v1/products/get-all-products?page=1&limit=50&filter[brand_id][$eq]=${formData.brand_id}`);
          const data = await response.json();
          setProducts(data.productList);
        } catch (error) {
          console.error("Error fetching products:", error);
          // Handle error
        }
      }
    };

    fetchProducts();
  }, [formData.brand_id]);

  useEffect(() => {
    if (isOpen && modalName === "edit" && id) {
      // Fetch data for editing
      fetchBlogRequirement();
    }
  }, [isOpen, modalName, id]);

  const fetchBlogRequirement = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/get/blog-requirement/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch blog requirement");
      }
      const data = await response.json();
    // Filter out the extra fields from the fetched data
    const { _id, createdAt, brand_name, product_name,  cw_name, cw_id,task_status, ...filteredData } = data?.data;

    // Set the form data with the filtered values
    setFormData({
      blog_type: filteredData.blog_categories_id,
      blog_topic: filteredData.blog_topic,
      brand_id: filteredData.brand_id,
      product_id: filteredData.product_id || '',
      keywords : filteredData.keywords,
      description: filteredData.description,
    });
    } catch (error) {
      console.error("Error fetching blog requirement:", error);
      toast.error("Failed to fetch blog requirement");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform any validation or submit data to the server here
    console.log("Form Data Submitted:", formData);

    // Example validation for required fields
    if (!formData.blog_type || !formData.blog_topic || !formData.keywords.length || !formData.brand_id || !formData.description) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const url = modalName === "edit" ? `${process.env.REACT_APP_URL}/v1/content-head/edit/blog-requirement/${id}` : `${process.env.REACT_APP_URL}/v1/content-head/create/blog-requirement`;
      const method = modalName === "edit" ? "POST" : "POST" // Assuming you want to use POST for edit
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Assuming you have a token variable defined
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        navigate('/content-head-dashboard/blog-list');
        toast.success(data.message);
        setFormData({
          blog_type: "",
          blog_topic: "",
          brand_id: "",
          product_id: "",
          keywords: [],
          description: "", // Clear description field after successful submission
        });
        onClose()
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error submitting blog requirement:", error);
      toast.error("An error occurred while submitting the blog requirement.");
    }
  };


  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "block" : "hidden"}`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form className="" onSubmit={handleSubmit}>
            <h1 className="text-xl font-bold px-4 py-4">Add Blog Requirement</h1>
            <div className="grid grid-cols-1 gap-6">
              <div className="p-4">
                <div className="flex flex-col">
                  <label htmlFor="blog_type" className="block text-sm font-medium text-gray-700">
                    Blog Type:
                  </label>
                  <select
                    id="blog_type"
                    name="blog_type"
                    value={formData.blog_type}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  >
                    <option value="">Select Blog Type</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="blog_topic" className="block text-sm font-medium text-gray-700">
                    Blog Topic:
                  </label>
                  <input
                    id="blog_topic"
                    type="text"
                    name="blog_topic"
                    value={formData.blog_topic}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="brand_id" className="block text-sm font-medium text-gray-700">
                    Brand:
                  </label>
                  <select
                    id="brand_id"
                    name="brand_id"
                    value={formData.brand_id}
                    onChange={handleInputChange}
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    required
                  >
                    <option value="">Select Brand</option>
                    {brands.map((brand) => (
                      <option key={brand._id} value={brand._id}>
                        {brand.brand_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="product_id" className="block text-sm font-medium text-gray-700">
                    Product:
                  </label>
                  <select
                    id="product_id"
                    name="product_id"
                    value={formData.product_id}
                    onChange={handleInputChange}
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  >
                    <option value="">Select Product</option>
                    {products.map((product) => (
                      <option key={product._id} value={product._id}>
                        {product.item_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">
                    Keywords:
                  </label>
                  <input
                    id="keywords"
                    type="text"
                    name="keywords"
                    value={formData.keywords.join(",")}
                    onChange={handleKeywordsChange}
                    required
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter keywords separated by commas"
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description:
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleDescriptionChange}
                    required
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md "
                    placeholder="Enter description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg
-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddRequiredBlogModal;
