import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../../hook/getToken';
import SideBar from '../../Sidebar';
import { ContentWriter } from '../../../constant';
import PdfViewModal from './PdfViewModal';

const CwProfileDetails = () => {
  const [profileDetails, setProfileDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfModal, setPdfModal] = useState(false);
  const [cvModal, setCvModal] = useState(false);
  const accessToken = getToken();
  const router = useNavigate();

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/profile/get`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProfileDetails(data.data);
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileDetails();
  }, [accessToken]);

  const handleUpdateProfile = () => {
    router('/content-writer/profile');
  };

  const handleClosePdf = () => {
    setPdfModal(false);
    setCvModal(false);
  };

  return (
    <main className="flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className="p-4 flex-grow">
        <h1 className="text-2xl font-bold mb-4">Profile Details</h1>
        <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto">
          {loading ? (
            <div>Loading...</div>
          ) : profileDetails ? (
            <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Name :</p>
                <p>{profileDetails.name}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Email :</p>
                <p>{profileDetails.email}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Address :</p>
                <p>{profileDetails.address}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Phone :</p>
                <p>{profileDetails.phone}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Alternate Phone :</p>
                <p>{profileDetails.alter_phone}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Aadhar Number :</p>
                <p>{profileDetails.adhar_no}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Aadhar Doc :</p>
                <div className="w-full h-full p-2 cursor-pointer" onClick={() => setPdfModal(true)}>
                  <img
                    className="w-8 h-8 object-contain"
                    src="https://res.cloudinary.com/genx21/image/upload/v1694692016/zngnznqxvjtrpvn45jee.webp"
                    alt=""
                  />
                </div>
                {pdfModal && (
                  <PdfViewModal
                    visible={pdfModal}
                    CloseModal={handleClosePdf}
                    url={profileDetails.adhar_doc}
                  />
                )}
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">CV Document :</p>
                <div className="w-full h-full p-2 cursor-pointer" onClick={() => setCvModal(true)}>
                  <img
                    className="w-8 h-8 object-contain"
                    src="https://res.cloudinary.com/genx21/image/upload/v1694692016/zngnznqxvjtrpvn45jee.webp"
                    alt=""
                  />
                </div>
                {cvModal && (
                  <PdfViewModal
                    visible={cvModal}
                    CloseModal={handleClosePdf}
                    url={profileDetails.cv_doc}
                  />
                )}
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Portfolio Link :</p>
                <p>
                  <a href={profileDetails.portfolio_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                    {profileDetails.portfolio_link}
                  </a>
                </p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Cost :</p>
                <p>{profileDetails.cost}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">Payment Option :</p>
                <p>{profileDetails.payment_option}</p>
              </div>
              <div className="flex border-b gap-x-7">
                <p className="font-semibold">UPI ID :</p>
                <p>{profileDetails.upi_id}</p>
              </div>
            </div>
          ) : (
            <div>No profile details available.</div>
          )}
          <div className="mt-6 text-right">
            <button
              onClick={handleUpdateProfile}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CwProfileDetails;
