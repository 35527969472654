import React from 'react'
import { useMediaQuery } from 'react-responsive'
const ShowImage = ({ image, mobImage, alt, style }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
    const imageUrl = isMobile ? mobImage : image
    return (
        <div className={`${style}`}>
            <img className='w-full h-full object-cover' alt={alt} src={imageUrl} loading='lazy' />
        </div>
    )
}

export default ShowImage