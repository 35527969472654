import dashboard from "../assets/dashboard.png";
import orders from "../assets/order.png";
import product from "../assets/product.png";
import categories from "../assets/category.png";
import seller from "../assets/seller.png";
import coupons from "../assets/coupon.png";
import blog from "../assets/blog.png";
import generalSetting from "../assets/settings.png";
import emailSetting from "../assets/email.png";
import socialSetting from "../assets/social.png";
import manageUser from "../assets/manage_user.png";
import homepageSetting from "../assets/homepage.png";
import countryList from "../assets/countries.png";
import awardList from "../assets/awards.png";
import gst from "../assets/gst.svg";
import hotdeals from "../assets/hotdeals.svg";
import image from "../assets/Image.svg";
import b2b from "../assets/b2buser.svg";
import distributor from "../assets/distributor.svg";
import settings from "../assets/manageuser.svg";
const Menus = [
  {
    title: "Dashboard",
    src: dashboard,
    link: "dashboard",
  },
  {
    title: "Manage User",
    src: settings,
    link: "designation-list",
    subMenus: [
      {
        title: "Manage Sub-admin",
        src: "parent",
        link: "/designation-list",
      },
      {
        title: "Manage Roles/Designation",
        src: "sub",
        link: "/manage-roles",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  {
    title: "Lists",
    src: manageUser,
    link: "countrylist",
    subMenus: [
      {
        title: "Country List",
        src: countryList,
        link: "/countrylist",
      },
      {
        title: "Award List",
        src: awardList,
        link: "/awardlist",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  {
    title: "Orders",
    src: orders,
    link: "orders",
  },
  {
    title: "Pre-Orders",
    src: orders,
    link: "preorders",
  },
  {
    title: "Customers",
    src: seller,
    link: "customers",
  },
  {
    title: "Categories",
    src: categories,
    link: "category",
    subMenus: [
      {
        title: "Parent Categories",
        src: "parent",
        link: "/category/parentcategory",
      },
      {
        title: "Sub Categories",
        src: "sub",
        link: "/category/subcategory",
      },
      {
        title: "Child Categories",
        src: "child",
        link: "/category/childcategory",
      },
      {
        title: "Attribute",
        src: "child",
        link: "/category/attributes",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  {
    title: "Manage GST",
    src: gst,
    link: "ManageGst",
  },
  {
    title: "Brands",
    src: coupons,
    link: "brands",
  },
  {
    title: "Products",
    src: product,
    link: "allproduct",
    subMenus: [
      {
        title: "All Product list",
        src: "parent",
        link: "/allproduct",
      },
      {
        title: "Review Product List",
        src: "sub",
        link: "/reviewproduct",
      },
      {
        title: "Approve Product List",
        src: "child",
        link: "/approveproduct",
      },
      {
        title: "Product Settings",
        src: "child",
        link: "approve-product",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  {
    title: "Seller",
    src: seller,
    link: "seller/sellerlist",
    subMenus: [
      {
        title: "Seller List",
        src: "parent",
        link: "/seller/sellerlist",
      },
      {
        title: "Seller Verification",
        src: "sub",
        link: "/seller/sellerverification",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  {
    title: "Hot Deals",
    src: hotdeals,
    link: "hot-deal",
  },
  {
    title: "B2B User",
    src: b2b,
    link: "b2buser",
    isOpen: false, // Add isOpen property
  },
  {
    title: "Distributor",
    src: distributor,
    link: "distributorship",
    isOpen: false, // Add isOpen property
  },

  {
    title: "Coupons",
    src: coupons,
    link: "coupons",
    subMenus: [
      {
        title: "Admin Coupon",
        src: coupons,
        link: "/coupons",
      },
      {
        title: "Addition Coupon",
        src: coupons,
        link: "/addition-coupons",
      },
      {
        title: "Wallet Offer",
        src: coupons,
        link: "/wallet-offer",
      }
    ],
    isOpen: false,
  },
  {
    title: "Manage Cancellation",
    src: blog,
    link: "manage-cancellation",
  },
  {
    title: "Application Settings",
    src: image,
    link: "application",
    subMenus: [
      {
        title: "Splash Screen",
        src: image,
        link: "/application",
      },
      {
        title: "Shop Page Banner",
        src: image,
        link: "/application/shop",
      },
      {
        title: "Home Page Banner",
        src: image,
        link: "/application/home",
      },
      {
        title: "Wfh Page Banner",
        src: image,
        link: "/application/wfh",
      },
    ],
  },

  {
    title: "Banner-upload",
    src: image,
    link: "add-home-banner-1",
    subMenus: [
      {
        title: "Homepage Banners",
        src: "parent",
        link: "/add-home-banner-1",
      },
      {
        title: "Child Category Banner",
        src: "sub",
        link: "/child-banner",
      },
      {
        title: "Sub Category Banner",
        src: "sub",
        link: "/sub-banner",
      },
      {
        title: "Parent Category Banner",
        src: "sub",
        link: "/parent-banner",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  // {
  //   title: "General Settings",
  //   src: generalSetting,
  //   link: "general-setting",
  //   subMenus: [
  //     {
  //       title: "Logo",
  //       src: "parent",
  //       link: "category",
  //     },
  //     {
  //       title: "Fevicon",
  //       src: "sub",
  //       link: "category",
  //     },
  //     {
  //       title: "Loader",
  //       src: "sub",
  //       link: "category",
  //     },
  //   ],
  //   isOpen: false, // Add isOpen property
  // },
  {
    title: "Homepage Settings",
    src: homepageSetting,
    link: "homepage-setting",
    subMenus: [
      {
        title: "Utility",
        src: "parent",
        link: "/utility",
      },
      {
        title: "Sections",
        src: "sub",
        link: "category",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  // {
  //   title: "Email Settings",
  //   src: emailSetting,
  //   link: "email-setting",
  //   subMenus: [
  //     {
  //       title: "Email Template",
  //       src: "parent",
  //       link: "category",
  //     },
  //     {
  //       title: "Email Configuration",
  //       src: "sub",
  //       link: "category",
  //     },
  //     {
  //       title: "Group Email",
  //       src: "sub",
  //       link: "category",
  //     },
  //   ],
  //   isOpen: false, // Add isOpen property
  // },
  // {
  //   title: "Social Settings",
  //   src: socialSetting,
  //   link: "social-setting",
  //   subMenus: [
  //     {
  //       title: "Social Links",
  //       src: "parent",
  //       link: "category",
  //     },
  //     {
  //       title: "Facebook Login",
  //       src: "sub",
  //       link: "category",
  //     },
  //     {
  //       title: "Google login",
  //       src: "sub",
  //       link: "category",
  //     },
  //   ],
  //   isOpen: false, // Add isOpen property
  // },
  {
    title: "CMS Section",
    src: blog,
    link: "cms/faqs",
    subMenus: [
      {
        title: "Home Page Cms",
        src: "blog",
        link: "/home-cms/content/list",
      },
      {
        title: "Privacy Policy",
        src: "blog",
        link: "/cms/privacy-policy",
      },
      {
        title: "Terms & Conditions",
        src: "sub",
        link: "/cms/terms-conditions",
      },
      {
        title: "Refunds & cancellations",
        src: "sub",
        link: "/cms/refunds-cancellations",
      },
      {
        title: "Shipping Policy",
        src: "sub",
        link: "/cms/shipping-policy",
      },
      {
        title: "Warranty Policy",
        src: "sub",
        link: "/cms/warranty-policy",
      },
      {
        title: "FAQs",
        src: "sub",
        link: "/cms/faqs",
      },
      {
        title: "Manage Address",
        src: "sub",
        link: "/address",
      },
    ],
    isOpen: false, // Add isOpen property
  },
  {
    title: "Contacts",
    src: coupons,
    link: "contactlist",
  },
  {
    title: "Ratings & Reviews",
    src: coupons,
    link: "review-rating",
  },
  {
    title: "Seo Data",
    src: blog,
    link: "seo-data",
  }
];
export default Menus;
