import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import UploadIcon from './UploadIcon';

const UploadImage = ({ id, name, height, onChange, value, aspectRatio, requiredRatio, tolerance, index }) => {
    const [imagePreview, setImagePreview] = useState(value);
    useEffect(() => {
        setImagePreview(value);
    }, [value]);
    const handleImageChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            // Load the image to check aspect ratio
            const image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {

                // If the aspect ratio is within the acceptable range, proceed with the rest of your logic
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImagePreview(e.target.result);
                    onChange(index, reader.result);
                };
                reader.readAsDataURL(file);
            };
        }
    };

    return (
        <div className="flex items-center justify-center w-full">
            <label htmlFor={id} className={`${height} flex flex-col items-center justify-center w-full  border-2 border-teal-500 border-dashed rounded-lg cursor-pointer bg-gray-50`}>
                {imagePreview ? (
                    <img src={imagePreview} alt="Preview" className="w-full h-full object-cover" />
                ) : (
                    <>
                        <div className="flex flex-col items-center justify-center mt-5 pt-1 px-4 border border-teal-500">
                            <UploadIcon />
                            <p className='p-1 text-teal-500'>{requiredRatio}</p>
                        </div>
                        <p className="mb-2 text-sm text-teal-500">Click to add image</p>
                    </>
                )}
                <input
                    id={id}
                    type="file"
                    className="opacity-0"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                />
            </label>
        </div>
    );
};

export default UploadImage;