import React, { useState } from "react";
import { getToken } from "../../hook/getToken";
import toast from "react-hot-toast";

const PasswordChangeModal = ({onClose}) => {
    const token = getToken()
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/auth/admin/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to change password. Please try again.");
      }

      const data = await response.json();
      toast.success("Password changed successfully!");
      onClose()

      // Reset form
      setCurrentPassword("");
      setNewPassword("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      id="container"
      className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50"
    >
      <div className="w-96 bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-lg font-semibold mb-3 text-center">Change Password</h2>

        <div className="mb-3">
          <div className="flex flex-col space-y-3">
            <div className="flex flex-col items-start">
              <label htmlFor="current-password" className="text-sm font-medium">
                Current Password
              </label>
              <input
                id="current-password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex flex-col items-start">
              <label htmlFor="new-password" className="text-sm font-medium">
                New Password
              </label>
              <input
                id="new-password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {error && <p className="text-red-500 text-center mb-3">{error}</p>}

        <div className="flex gap-3 justify-center items-center mt-4">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
