import { Toaster } from "react-hot-toast";
import "./App.css";
import MainRoutes from "./Routing/Routes";
import { CmsProvider } from "./context/CmsProvider";
function App() {
  return (
    <CmsProvider>
      <div className="App">
        <Toaster />
        <MainRoutes />
      </div>
    </CmsProvider>
  );
}

export default App;