import React, { useState, useEffect, useMemo } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from './ProductCard';

const BrandTemplate1 = ({ data }) => {
  const { heading, sub_heading, ids } = data;
  const [selectedId, setSelectedId] = useState(ids[0]?.id)
  const brandIds = JSON.stringify(ids?.map(d => d.id))
  const [list, setList] = useState([])
  const [products, setProducts] = useState([])
  useEffect(() => {
    if (!data?.ids) return
    fetch(`${process.env.REACT_APP_URL}/v1/cms/get-filter-content?filter_type=brand&ids=${brandIds}`).then(res => res.json()).then(data => setList(data?.response.data))
  }, [data?.ids])

  useEffect(() => {
    if (!selectedId) return
    fetch(`${process.env.REACT_APP_URL}/v1/products/get-all-products?filter[brand_id][$eq]=${selectedId}&random=yes&page=1&limit=5`).then(res => res.json()).then(data => setProducts(data.productList))

  }, [selectedId])

  const handleChange = (id) => {
    setSelectedId(id)
  }
  const { aboutBanner, selectedName, slug } = useMemo(() => {
    if (!list && !selectedId) return {};
    const banner = list?.find((item) => item._id === selectedId);
    return { aboutBanner: banner?.about_banner, selectedName: banner?.name, slug: banner?.slug };
  }, [list, selectedId]);
  return (
    <div className='flex flex-col'>
      <Heading text={heading} />
      <SubHeading text={sub_heading} />
      <div className='home-heading w-full sm:justify-center'>
        {
          list?.map(c => (
            <BrandHeadingCard
              data={c}
              id={selectedId}
              handleChange={handleChange}
            />
          ))
        }
      </div>
      <div className='home-product-section'>
        <div className='h-52 overflow-hidden md:h-96 2xl:h-[30rem]'>
          <img
            src={aboutBanner}
            alt='Banner'
            className='aspect-square h-full w-full object-contain'
          />
        </div>

        {products &&
          products.length !== 0 &&
          products.map((product, index) => {
            return (
              <ProductCard
                key={product._id}
                product={product}
                style={'w-full h-52 md:h-96 2xl:h-[30rem]'}
                iconStyle={`w-5 h - 5 md: w - 7 md: h - 7`}
                textSize={`text - xs md: text - sm`}
              />
            );
          })}

      </div>
    </div>
  )
}

export default BrandTemplate1

const BrandHeadingCard = ({ handleChange, id, data }) => {
  const { _id, name, image } = data;
  return (
    <div
      className={`relative h-16 w-16 shrink-0 transform cursor-pointer overflow-hidden rounded-full transition-transform duration-300 ease-in-out hover:scale-110 hover:opacity-90 hover:shadow-lg xl:h-20 xl:w-20 ${id == _id ? 'ring-2 ring-gray-400 ' : 'grayscale filter border'} overflow-hidden`}
      onMouseOver={() => handleChange(_id)}
    >
      <div className='h-full w-full'>
        <img
          loading='lazy'
          width={100}
          height={100}
          src={image}
          alt={name}
          className='h-full w-full object-cover'
        />
      </div>
    </div>
  )
}