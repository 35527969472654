import React, { useEffect, useState } from "react";
import OrdersTableRow from "./OrdersTableRow";
import { User } from "../user/User";
import Pagination from "../../Pagination";
import { getToken } from "../../hook/getToken";
import DownOrderReport from "./DownOrderReport";

const status = [
  { fieldName: "order_status", name: "cancelled" },
  { fieldName: "order_status", name: "processing" },
  { fieldName: "order_status", name: "delivered" },
  { fieldName: "order_status", name: "shipped" },
  { fieldName: "payment_status", name: "paid" },
  { fieldName: "payment_status", name: "due" },
];

const Orders = () => {
  const [isDownloadClicked, setIsDownloadClicked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size set to 10
  const [totalItems, setTotalItems] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [filterValues, setFilterValues] = useState({
    order_status: [],
    payment_status: [],
    date_range: "",
    sort_amount: "",
    sort_date: "",
  });
  const [orderList, setOrderList] = useState([]);

  const handleDelete = () => setIsClicked((prev) => !prev);

  async function fetchOrderData() {
    const token = getToken(); // Get token if required
    const { order_status, payment_status, date_range, sort_amount, sort_date } =
      filterValues;
    const query = new URLSearchParams({
      page: currentPage,
      limit: pageSize, // Use the selected pageSize
      order_status: order_status.join(","),
      payment_status: payment_status.join(","),
      date_range, // Use date_range instead of start_date and end_date
      sort_amount,
      sort_date,
    }).toString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/order/get-orders?${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setOrderList(data.data);
      setTotalItems(data.totalCount);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setFilterValues((prev) => ({
      ...prev,
      [name]: checked
        ? [...prev[name], value]
        : prev[name].filter((val) => val !== value),
    }));
  };

  const handleDateRangeChange = (e) => {
    const { value } = e.target;
    setFilterValues((prev) => ({ ...prev, date_range: value }));
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value)); // Update the page size
    setCurrentPage(1); // Reset to the first page
  };

  useEffect(() => {
    fetchOrderData();
  }, [isClicked, filterValues, currentPage, pageSize]);

  return (
    <>
      <main>
        <div className="pr-6 text-xs">
          <section>
            <div className="w-full flex justify-between py-5">
              <p className="text-2xl text-gray-900 font-semibold">Orders</p>
              <div className="flex gap-x-10">
                <form className="flex items-center">
                  <div className="flex items-center px-2 py-1 gap-x-1 bg-gray-100 rounded-2xl ">
                    <div className=" bg-white rounded-full p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </div>
                    <input
                      className="w-52 py-1 px-1 bg-gray-100 outline-0"
                      type="text"
                    />
                  </div>
                </form>
                <User />
              </div>
            </div>
          </section>

          <section>
            <div className="flex flex-col mt-5">
              <div className="flex justify-between">
                <form>
                  <div className="flex flex-wrap items-center gap-x-2 py-5">
                    {status.map((item, index) => (
                      <div key={index} className="flex items-center gap-x-2">
                        <input
                          name={item.fieldName}
                          value={item.name}
                          id={`checkbox-${index}`}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-400 focus:outline-none"
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor={`checkbox-${index}`}
                          className="mr-2 text-xs capitalize font-normal text-gray-900"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </form>

                {/* Date Range Selection */}
                <div className="flex gap-x-2 justify-center items-center">
                  <div>
                    <label
                      htmlFor="pageSize"
                      className="mr-2 text-xs font-normal"
                    >
                      Items per page:
                    </label>
                    <select
                      id="pageSize"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                      className="py-1 px-2 border border-gray-300 rounded-full"
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  <select
                    className="py-1 px-2 ring-1 outline-none bg-gray-100 border border-solid rounded-full"
                    value={filterValues.date_range}
                    onChange={handleDateRangeChange}
                  >
                    <option value="">Select Date Range</option>
                    <option value="7days">Last 7 Days</option>
                    <option value="15days">Last 15 Days</option>
                    <option value="1month">Last 30 Days</option>
                    <option value="1year">Last 1 Year</option>
                  </select>
                  <div onClick={() => setIsDownloadClicked(true)}>
                    <button className="py-2 px-6 rounded-full bg-blue-500 text-white">Download Report</button>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section>
            <div className="relative overflow-x-auto">
              <table className="w-full text-left text-xs">
                <thead className="bg-gray-100 text-xs uppercase text-[#666666]">
                  <tr>
                    <th scope="col" className="px-2 py-3">
                      Order Id
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Order Date
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Order Details
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Product Details
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Shipping
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Discount
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Amount
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Actions
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Cancel Order
                    </th>
                    <th scope="col" className="px-2 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {orderList?.map((item) => (
                    <OrdersTableRow
                      key={item._id}
                      data={item}
                      onDelete={handleDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end items-center py-5">
              {/* Page Limit Selector */}

              <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </section>
        </div>
      </main>
      {
        isDownloadClicked && <DownOrderReport
          onClose={() => setIsDownloadClicked(false)}
          isOpen={isDownloadClicked}
        />
      }
    </>
  );
};

export default Orders;
