import React, { useState } from "react";
import { ContentHead } from "../../constant";

import SideBar from "../Sidebar";
import NavigateBack from "../NavigateBack";
import LogOutModal from "../Logout";

import CwTable from "./CwTable";
import AddCWModal from "./AddCWModal";

const ContentWriters = () => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showAddCwModal, setShowAddCwModal] = useState(false); // New state for controlling the "Add Content Writer" modal

    const handleLogout = () => {
        setShowLogoutModal(true);
    };
    const handleCloseLogoutModal = () => {
        setShowLogoutModal(false);
    };

    const handleAddContentWriter = () => {
        setShowAddCwModal(true);
    };
    const handleCloseAddCwModal = () => {
        setShowAddCwModal(false);
    };

    return (
        <main className="w-full flex flex-row gap-x-5">
            <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
                <SideBar menu={ContentHead} />
            </div>
            <div className="flex-1 mr-5">
                <section className="flex flex-row justify-between py-5 items-center">
                    <div className="flex space-x-2 items-center">
                        <NavigateBack />
                        <h1 className=" text-2xl text-[#383E50] font-semibold leading-10 text-left">
                            Content Head Dashboard
                        </h1>
                    </div>

                    <div className="flex flex-row gap-5">
                        <div className="flex items-center" onClick={handleLogout}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                />
                            </svg>
                        </div>

                        {showLogoutModal && (
                            <LogOutModal visible={showLogoutModal} onClose={handleCloseLogoutModal} />
                        )}

                        <input
                            type="search"
                            placeholder="Search"
                            className="w-60 border border-solid border-[#EEE] rounded-xl p-2"
                        />
                    </div>
                </section>

                <div className="flex justify-end items-center " >
                <div className="flex cursor-pointer" onClick={handleAddContentWriter}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <span >Add Content Writer</span>
                    </div>
                </div>
                {showAddCwModal && ( // Render the "Add Content Writer" modal when showAddCwModal is true
                        <AddCWModal isOpen={showAddCwModal} onClose={handleCloseAddCwModal} />
                    )}

                <section className="flex flex-col">
                    <CwTable />
                </section>
            </div>
        </main>
    );
};

export default ContentWriters;
