import React, { useState, useEffect } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from './ProductCard';

import { Virtual, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const CountryTemplate1 = ({ data }) => {
    const { heading, sub_heading, ids } = data;
    const [selectedId, setSelectedId] = useState(ids ? ids[0].id : '')
    const countryIds = JSON.stringify(ids?.map(d => d.id))
    const [list, setList] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!data?.ids) return
        fetch(`${process.env.REACT_APP_URL}/v1/cms/get-filter-content?filter_type=country&ids=${countryIds}`).then(res => res.json()).then(data => {
            setList(data.response.data)
        })
    }, [data.ids])

    useEffect(() => {
        if (!selectedId) return
        fetch(`${process.env.REACT_APP_URL}/v1/products/get-all-products?filter[country_id][$eq]=${selectedId}&random=yes&page=1&limit=12`).then(res => res.json()).then(data => setProducts(data.productList))
    }, [selectedId])



    return (
        <div className='flex flex-col'>
            <Heading text={heading} />
            <SubHeading text={sub_heading} />
            <div className='w-full '>
                <Swiper
                    modules={[Virtual, Navigation]}
                    slidesPerView={6}
                    // centeredSlides={true}
                    spaceBetween={0}
                    navigation={true}
                    virtual
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {
                        list?.map(c => (
                            <SwiperSlide key={c._id}>
                                <CountryCard
                                    country={c}
                                    length={ids.length}
                                    style='shrink-0 h-40 sm:h-44 md:h-60 overflow-hidden w-full'
                                    selectedId={selectedId}
                                    setSelectedId={setSelectedId}
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className='w-full mt-5'>
                <Swiper
                    modules={[Virtual, Navigation]}
                    slidesPerView={6}
                    // centeredSlides={true}
                    spaceBetween={20}
                    navigation={true}
                    virtual
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {products &&
                        products.length !== 0 &&
                        products.map((product, index) => {
                            return (
                                <SwiperSlide key={product._id + index} virtualIndex={index}>
                                    <ProductCard
                                        key={product._id}
                                        isSwiper={true}
                                        product={product}
                                        style={'w-full h-80'}
                                        iconStyle={`w-7 h-7`}
                                        textSize={`text-sm`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
        </div >
    )
}

export default CountryTemplate1

const CountryCard = ({ country, style, length, selectedId, setSelectedId }) => {
    const { country_name, image, country_banner, used_country, _id } = country;
    const cardStyle = {
        backgroundImage: `url(${country_banner})`,
        backgroundSize: selectedId == _id && '200%', // Corrected
        transition: selectedId == _id && 'backgroundSize 0.5s', // Corrected
    };

    return (
        <>
            <div
                onMouseEnter={() => setSelectedId(_id)}
                className={`${style} ${selectedId == _id ? ' ' : 'grayscale filter'} relative flex cursor-pointer items-center justify-center bg-cover bg-center bg-no-repeat`}
                style={cardStyle}
            >
                <div className='relative z-10 flex flex-col text-xl font-bold text-white sm:text-2xl md:text-3xl'>
                    <p>{country_name}</p>
                    <p className='text-center'>{used_country}</p>
                </div>
                <div className='absolute inset-0 bg-black opacity-30' />
            </div>
        </>
    );
};