import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { User } from "../user/User";
import UserDetailsCard from "./UserDetailsCard";
import revenue from "../../assets/Revenue.png";
import { useEffect } from "react";
import { getToken } from "../../hook/getToken";
import { useState } from "react";

import wishlist from "../../assets/wishlist.png";
import cart from "../../assets/cart.png";
import orders from "../../assets/orders.png";
import profile from "../../assets/contact.png";
import WishlistTable from "./WishlistTable";
import CartTable from "./CartTable";
import VisitHistoryTable from "./VisitHistoryTable";
import OrdersTableRow from "./OrdersTableRow";
import ProductHistory from "./ProductHistory";
import PushNotificationModal from "./PushNotificationModal";
import ConfirmationModal from "./ConfirmationModal";
const CustomersDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [personalDetails, setPersonalDetails] = useState([]);
  const [address, setAddress] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishListItems] = useState([]);
  const [recentView, setRecentView] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [visits, setVisits] = useState([]);
  const [notifyModal, setNotifyModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [notifyType, setNotifyType] = useState("custom");

  const handleCloseModal = () => {
    setNotifyModal(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    const fetchSingleCustomer = async () => {
      const token = getToken();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/customer/get/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setData(data);
          setPersonalDetails(data.personal_details);
          setVisits(data.rectentViewCount);
          setAddress(data.address || []); // Set address array or empty array if undefined
          setCartItems(data?.cartItems);
          setWishListItems(data?.wishListItems);
          setOrderItems(data?.orderItems);
        } else {
          console.error("Error fetching data", data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchSingleCustomer();
  }, [id]);

  const fetchRecentView = async () => {
    const token = getToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/recent-activity/admin/get-recent-product/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // Add other headers if needed
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Assuming you want to store the data in a state variable, use setData or similar
        setRecentView(data);
        console.log("Recent view data:", data);
      } else {
        console.error("Error fetching recent view data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchRecentView();
  }, []);

  return (
    <main className="bg-gray-50 p-2 min-h-full -ml-1/4">
      <section>
        <div className="max-w-6xl mx-auto flex justify-between py-5">
          <Link
            to="/customers"
            className="text-2xl text-gray-900 font-semibold"
          >
            Customers
          </Link>
          <User />
        </div>
      </section>

      {/* // status section */}
      <section>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row gap-5 py-5 overflow-x-scroll no-scrollbar">
            <div className="max-w-xs flex flex-col justify-start items-start h-20 p-3 rounded-xl bg-white">
              <p className="text-gray-500 uppercase text-xs">Account Info</p>
              <div className="flex gap-x-4 pt-1">
                <img
                  src={personalDetails?.avatar}
                  className="w-8 h-8 rounded-full"
                  alt=""
                />
                <div className="flex flex-col justify-start items-start">
                  <p className="text-gray-900 text-xs">
                    {personalDetails?.name}
                  </p>
                  <p className="text-gray-500 text-xs">
                    {personalDetails?.email}
                  </p>
                </div>
              </div>
            </div>
            <div className="max-w-xs flex flex-col justify-start items-start h-20 p-2 rounded-xl bg-white">
              <p className="text-gray-500 uppercase text-xs">Contact Info</p>
              <p className="text-xs text-gray-500 pt-1">
                <span className="text-xs font-semibold">Email:</span>{" "}
                {personalDetails?.email}
              </p>
              <p className="text-xs text-gray-500">
                <span className="text-xs font-semibold">Phone:</span>
                {personalDetails?.phone}
              </p>
            </div>

            {/* Address components */}
            {Array.isArray(address) &&
            address.length > 0 &&
            address[0].message ? (
              <div className="w-fit grow flex flex-col justify-start items-start h-20 bg-white p-2 rounded-xl">
                <p className="text-xs text-gray-500">{address[0].message}</p>
              </div>
            ) : Array.isArray(address) && address.length > 0 ? (
              address.map((addr, index) => (
                <div
                  key={index}
                  className="w-fit grow flex flex-col justify-start items-start h-20 bg-white p-2 rounded-xl"
                >
                  <p className="text-gray-500 uppercase text-xs ">Address</p>
                  <div className="flex flex-wrap gap-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                    <p className="text-xs text-gray-500 ">
                      {addr.addressLine1},
                    </p>
                    <p className="text-xs text-gray-500 ">
                      {addr.addressLine2}, {addr.city}, {addr.state}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-fit grow flex flex-col justify-start items-start h-20 bg-white p-2 rounded-xl">
                <p className="text-xs text-gray-500">No address available.</p>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center items-center h-24 p-2 rounded-xl bg-white">
            <div className="flex gap-x-12 ">
              <div className="border-r-2 pr-4">
                <div className="flex gap-x-4">
                  <img src={profile} alt="" />
                  <div className="flex flex-col">
                    <p className="text-gray-500 uppercase text-xs">
                      No of times visit
                    </p>
                    <p className="text-xs text-start text-gray-900 font-semibold">
                      {visits}
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-r-2 pr-4">
                <div className="flex gap-x-4">
                  <img src={wishlist} alt="" />

                  <div className="flex flex-col">
                    <p className="text-gray-500 uppercase text-xs">
                      Items in wishlist
                    </p>
                    <div className="flex justify-between items-center">
                      <p className="text-xs text-start text-gray-900 font-semibold">
                        {data?.wishListCount}
                      </p>
                      <button onClick={() =>{ 
                        setNotifyType("wishlist")
                        setConfirmModal(true)}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="yellow"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-yellow-400 "
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                          />
                        </svg>
                      </button>
                      {confirmModal && (
                        <ConfirmationModal notifyType={notifyType} show={confirmModal} onClose={handleCloseModal} userId={id} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-r-2 pr-4">
                <div className="flex gap-x-4">
                  <img src={orders} alt="" />
                  <div className="flex flex-col">
                    <p className="text-gray-500 uppercase text-xs">Orders</p>
                    <p className="text-xs text-start text-gray-900 font-semibold">
                      {data?.orderItemsCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-r-2 pr-4">
                <div className="flex gap-x-4">
                  <img src={cart} alt="" />
                  <div className="flex flex-col">
                    <p className="text-gray-500 uppercase text-xs ">
                      Items in cart
                    </p>
                    <div className="flex justify-between items-center">
                      <p className="text-xs text-start text-gray-900 font-semibold">
                        {data?.cartItemsCount}
                      </p>
                      <button onClick={() => {
                        setConfirmModal(true)
                        setNotifyType("cart")
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="yellow"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-yellow-400 "
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" ">
                <button
                  onClick={() => setNotifyModal(true)}
                  className="text-xs bg-slate-400 px-2 py-1 text-white rounded"
                >
                  Custom Notification
                </button>
              </div>
              {notifyModal && (
                <PushNotificationModal userId={id} onClose={handleCloseModal} />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Wishlist , Cart and Number of visit section */}
      <section>
        <div className="grid grid-cols-2 gap-12 mt-4">
          <WishlistTable wishListItems={wishlistItems} />
          <CartTable cartItems={cartItems} />
        </div>
      </section>
      <div className="flex ">
        {/* Product History */}
        <div className="flex-1">
          <ProductHistory recentView={recentView} />
        </div>

        {/* Order table Section */}
        <section className="flex-1">
          <h1 className="text-xs font-semibold text-start">Order History</h1>
          <div className="relative h-[30rem] mt-5 overflow-y-auto no-scrollbar">
            <div className="overflow-x-auto">
              <table className="w-full text-left text-xs border rounded-md bg-gray-100">
                <thead className="bg-gray-200 sticky top-0 z-10">
                  <tr>
                    <th scope="col" className="px-2 py-3">
                      Product Image
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Order ID
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Date/Time
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Total
                    </th>
                    <th scope="col" className="px-2 py-3">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(orderItems) &&
                  orderItems.length > 0 &&
                  orderItems[0]?.message !== "No order data found" ? (
                    orderItems.map((item) => (
                      <OrdersTableRow key={item._id} data={item} />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        {orderItems[0]?.message || "No orders found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default CustomersDetails;
