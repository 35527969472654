'use client';
import { Link } from 'react-router-dom';
import React, { useState, useCallback, useMemo } from 'react';
const ProductCard = ({
    product,
    style,
    iconStyle,
    textSize,
    quickViewWidth,
    quickViewHeight,
    isSwiper,
}) => {
    const {
        product_images,
        item_slug,
        item_name,
        max_retail_price,
        list_price,
        _id,
        variation_group_id,
        country,
        brand,
        awards,
        parent_category,
        sub_category,
        child_category,
        inventory,
    } = product;


    const images = useMemo(() => {
        if (!product_images) return [];
        return Object.values(product_images).filter((image) => image);
    }, [product_images]);
    const [bgImg, setBgImg] = useState(
        images[Math.floor(Math.random() * images.length + 1)] ||
        product_images?.main_img
    );


    const award = awards ? awards : [];
    const [isHover, setIsHover] = useState(false);
    const [isClicked, setIsClicked] = useState(false);


    const handleMouseEnter = useCallback(() => {
        setBgImg(
            images[Math.floor(Math.random() * images.length + 1)] ||
            product_images?.main_img
        );
        setIsHover(true);
    }, []);
    const handleMouseLeave = useCallback(() => {
        setBgImg(
            images[Math.floor(Math.random() * images.length + 1)] ||
            product_images?.main_img
        );
        setIsHover(false);
    }, []);
    const handleRedirect = (redirectLink) => {
        // Open the link in a new tab
        window.open(redirectLink, '_blank');

        // Optionally, you can also navigate to the link using router.push()
        // router.push(redirectLink);
    };
    const redirectLink = `https://www.21genx.com/product/${item_slug}/buy/${_id}`;

    return (
        <div
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            className={`${style} relative overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat`}
        // style={{
        //     backgroundImage: `url(${bgImg})`,

        // }}
        >
            <img
                loading='lazy'
                src={bgImg}
                alt={item_name}
                width={500}
                height={500}
                className='h-full w-full object-cover'
            />
            <div
                onClick={() => handleRedirect(redirectLink)}
                className={`${isHover ? 'block' : 'hidden'} absolute inset-0 cursor-pointer bg-black opacity-30`}
                style={{
                    transition: 'background-color 0.5s ease-in-out 0.5s', // Add the transition with a 0.2s delay
                }}
            />


            <div
                className={` ${isHover ? 'flex flex-col duration-500' : 'hidden'} absolute inset-x-2 bottom-2 z-10`}
            >
                {Array.isArray(awards) ? (
                    <div
                        className={`awards-shape flex w-fit flex-row gap-2 rounded-tl-lg rounded-tr-lg bg-[#fff] px-2 py-1 ${awards?.length ? 'block' : 'hidden'} `}
                    >
                        {award?.slice(0, 3).map((award) => (
                            <img
                                loading='lazy'
                                key={award._id}
                                src={award.image}
                                className={`${iconStyle} rounded-full`}
                            />
                        ))}

                        {award?.length ? <div className={`h-4 w-4`} /> : null}
                    </div>
                ) : null}
                <div className='flex flex-col rounded-b-lg rounded-tr-lg bg-[#fff] px-2 py-1 shadow'>
                    <div className='relative flex flex-row items-center justify-between'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <Link target='_blank' to={`https://www.21genx.com/brand/${brand?.slug}`}>
                                    {brand?.brand_name}
                                </Link>
                                <Link target='_blank' to={`https://www.21genx.com/brand/${brand?.slug}`}>
                                    <img
                                        loading='lazy'
                                        src={brand?.brand_logo_url}
                                        className={`${iconStyle} rounded-full object-contain`}
                                    />
                                </Link>

                                <img
                                    loading='lazy'
                                    src={country?.image}
                                    className={`${iconStyle} rounded-full`}
                                />
                            </div>
                            <Link
                                target='_blank'
                                to={redirectLink}
                                className={`${textSize} py-0 text-gray-900 md:py-2`}
                            >
                                {item_name}
                            </Link>
                        </div>

                        <div className='mt-6 flex flex-col items-center justify-center space-y-1 md:mt-0'>
                            <p className={`${textSize} whitespace-nowrap font-bold`}>
                                ₹ {inventory?.list_price}{' '}
                            </p>
                            {inventory?.max_retail_price == inventory?.list_price ? null : (
                                <p
                                    className={`${textSize} whitespace-nowrap text-gray-500 line-through`}
                                >
                                    ₹ {inventory?.max_retail_price}
                                </p>
                            )}
                        </div>

                    </div>
                    {/* // icon buttom */}
                </div>
            </div>


        </div>
    );
};

export default ProductCard;