import React, { useState, useEffect } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from './ProductCard';

import { Virtual, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const UtilityTemplate1 = ({ data }) => {
    const { heading, sub_heading, ids } = data;
    const [selectedId, setSelectedId] = useState()
    const awardIds = JSON.stringify(ids?.map(d => d.id))
    const [list, setList] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!data?.ids) return
        fetch(`${process.env.REACT_APP_URL}/v1/cms/get-filter-content?filter_type=utility&ids=${awardIds}`).then(res => res.json()).then(data => {
            console.log('utility data', data)
            setSelectedId(data.response?.data[0]?.name)
            setList(data.response.data)
        })
    }, [data.ids])

    useEffect(() => {
        if (!selectedId) return
        fetch(`${process.env.REACT_APP_URL}/v1/products/get-all-products?filter[utility_tags][$eq]=${selectedId}&random=yes&page=1&limit=12`).then(res => res.json()).then(data => setProducts(data.productList))

    }, [selectedId])

    const handleChange = (id) => {
        setSelectedId(id)
    }

    return (
        <div className='flex flex-col'>
            <Heading text={heading} />
            <SubHeading text={sub_heading} />
            <div className='w-full flex flex-row overflow-x-scroll gap-2 md:gap-5 no-scrollbar'>
                {
                    list?.map(c => (
                        <UtilityHeadingCard
                            data={c}
                            id={selectedId}
                            handleChange={handleChange}
                        />
                    ))
                }
            </div>
            <div className='w-full'>
                <Swiper
                    modules={[Virtual, Navigation]}
                    slidesPerView={6}
                    // centeredSlides={true}
                    spaceBetween={20}
                    navigation={true}
                    virtual
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {products &&
                        products.length !== 0 &&
                        products.map((product, index) => {
                            return (
                                <SwiperSlide key={product._id + index} virtualIndex={index}>
                                    <ProductCard
                                        key={product._id}
                                        isSwiper={true}
                                        product={product}
                                        style={'w-full h-80'}
                                        iconStyle={`w-7 h-7`}
                                        textSize={`text-sm`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
        </div >
    )
}

export default UtilityTemplate1

const UtilityHeadingCard = ({ handleChange, id, data }) => {
    const { _id, name, image } = data;
    return (
        <div
            onMouseEnter={() => handleChange(name)}
            onClick={() => handleChange(name)}
            className='flex flex-col'
        >
            <div
                className={`w-60  shrink-0 cursor-pointer overflow-hidden rounded-lg shadow  ${id === name ? 'border-2 border-solid border-blue-500' : 'grayscale filter'}`}
            >
                <img
                    src={image}
                    alt={name}
                    width={400}
                    height={400}
                    loading=''
                    className='h-full w-full object-cover'
                />
            </div>
            <h2 className='py-2 text-center text-sm font-bold md:text-base lg:text-xl'>
                <span className='text-[#FFAE5D]'>{name}</span>
            </h2>
        </div>
    )
}