import React, { useState, useEffect, useRef } from 'react';
import componentRegistry from './ComponentRegistry';
import { getToken } from '../../../hook/getToken';
import { useParams, Link, useLocation } from 'react-router-dom';
import NavigateBack from '../../../managerdashboard/NavigateBack';

const HomeCmsPreview = () => {
    const { id } = useParams();
    const [isMobile, setIsmobile] = useState(false)
    const [previewData, setPreviewData] = useState([]);
    useEffect(() => {
        if (!id) return
        const apiUrl = `${process.env.REACT_APP_URL}/v1/cms/get-single-content?content_id=${id}`;
        fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setPreviewData(data?.response?.data);
            });
    }, [id]);

    return (
        <>
            <div className='flex justify-start items-center p-5 '>
                <NavigateBack text='Back' />

                {/* <div className='flex space-x-2 items-center text-black'>
                    <button
                        onClick={() => setIsmobile(false)}
                        className={`${isMobile ? '' : 'border'} p-1 rounded-full`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                        </svg>
                    </button>
                    <button
                        onClick={() => setIsmobile(true)}
                        className={`${isMobile ? 'border' : ''} p-1 rounded-full`}

                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>

                    </button>
                </div> */}
            </div>
            <div className='flex flex-row relative'>
                <div className={`${isMobile ? 'w-[520px]' : 'w-full]'} text-left  max-w-7xl mx-auto flex flex-col gap-y-10 p-5`}>
                    {previewData?.map((item, index) => {
                        const ComponentToRender = componentRegistry[item.component];
                        const sectionId = item.title?.replace(/ /g, '_');
                        if (ComponentToRender) {
                            return (
                                <div key={index} id={sectionId}>
                                    <ComponentToRender data={item.data} />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        </>
    );
};

export default HomeCmsPreview;
