import React from 'react'
import UpArrow from './UpArrow'
import DownArrow from './DownArrow'
import Remove from './Remove'
const TopBar = ({ name, index }) => {
    return (
        <div className='flex justify-between items-center border pl-4'>
            <div>
                <h1 className='text-xl text-black font-semibold '>Standard {name}</h1>
            </div>
            <div className='flex' >
                <UpArrow index={index} />
                <DownArrow index={index} />
                <Remove index={index} />
            </div>
        </div>
    )
}

export default TopBar