import React, { useState, useEffect, useCallback } from 'react';
import getList from '../getList';
import { getToken } from '../../hook/getToken';
import EyeButton from './EyeButton';
import { useNavigate } from "react-router-dom";
import Pagination from '../../Pagination';

const CwTable = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState({});
    const token = getToken();

    useEffect(() => {
        const url = `${process.env.REACT_APP_URL}/v1/content-head/get/cwlist?page=${currentPage}&limit=${pageSize}`;
        getList(url, token).then((data) => {
            setList(data?.data);
            setTotalItems(data?.metadata?.total);

            // Initialize selectedStatus state with the current list of writers' statuses
            const initialStatus = data?.data.reduce((acc, writer) => {
                acc[writer._id] = writer.status ? "active" : "deactivated";
                return acc;
            }, {});
            setSelectedStatus(initialStatus);
        });

    }, [currentPage, pageSize, token]);

    const handleClick = useCallback((id,name) => {
        navigate(`/content-head-dashboard/associate-blog/${id}/${name}`);
    }, [navigate]);

    const handleClick2 = useCallback((id) => {
        navigate(`/content-head/content-writers-payment/${id}`);
    }, [navigate]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleChange = async (id) => {
        // Make the API call
        const url = `${process.env.REACT_APP_URL}/v1/category-manager/sub-admin/status/${id}`;
        console.log(url);

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            // Toggle the status for the specific writer
            setSelectedStatus((prevStatus) => {
                const newStatus = prevStatus[id] === "active" ? "deactivated" : "active";
                return {
                    ...prevStatus,
                    [id]: newStatus
                };
            });

            // Handle success if needed
        } else {
            // Handle error
            console.error('Error updating status:', response.statusText);
        }
    };

    return (
        <div className="relative overflow-hidden">
            <h4 className='text-left text-xl text-[#383E50] font-medium py-2'>List of Content Writers</h4>
            <table className="w-full text-left text-xs">
                <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
                    <tr>
                        <th scope="col" className="px-4 py-2">
                            Sl. NO
                        </th>
                        <th scope="col" className="px-4 py-2">
                            EmpCode
                        </th>

                        <th scope="col" className="px-4 py-2">
                            CW (username)
                        </th>

                        <th scope="col" className="px-4 py-2">
                            Contact No
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Mail Id
                        </th>

                        <th scope="col" className="px-4 py-2">
                            Status
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Writer Status
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Actions 
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Payment 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(list) &&
                        list.map((item, index) => {
                            const { _id, emp_id, name, email, userName, phone, status } = item;

                            return (
                                <tr key={_id}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">{emp_id}</td>
                                    <td className="px-4 py-2">{name} ({userName})</td>
                                    <td className="px-4 py-2">{phone}</td>
                                    <td className="px-4 py-2">{email}</td>
                                    <td className="px-4 py-2">
                                        <div className="">
                                            {status ? (
                                                <div className="">Assigned</div>
                                            ) : (
                                                <div className="">Not Assigned</div>
                                            )}
                                        </div>
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="flex gap-2">
                                            <select
                                                className={`rounded outline-none p-1 ${
                                                    selectedStatus[_id] === "active" ? "bg-green-200" : "bg-red-200"
                                                }`}
                                                value={selectedStatus[_id]}
                                                onChange={() => handleChange(_id)}
                                            >
                                                <option value="active">Activate</option>
                                                <option value="deactivated">Deactivate</option>
                                            </select>
                                        </div>
                                    </td>

                                    <td className="px-4 py-2">
                                        <div className='flex justify-center'>
                                            <EyeButton
                                                id={_id}
                                                name={name}
                                                onClick={handleClick}
                                            />
                                        </div>
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className='flex justify-center'>
                                            <EyeButton
                                                id={_id}
                                                onClick={handleClick2}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <div className="py-2">
                <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    setCurrentPage={handlePageChange}
                />
            </div>
        </div>
    );
};

export default CwTable;
