import React, { useEffect, useState, useCallback } from 'react';
import { format } from 'date-fns';
import MergeButton from './MergeButton';
import EyeButton from './EyeButton';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../hook/getToken';
import toast from 'react-hot-toast';
import Pagination from '../../Pagination';
import Description from '../../Description';

// Utility function to format the date
const formatDate = (dateString) => {
  if (dateString === 'N/Y') {
    return 'N/Y';
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
};

const AssociateBlogTable = ({ cmName, id }) => {
  console.log("ID>>>", id);
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [statusFilter, setStatusFilter] = useState(''); // State variable for selected status filter
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve the access token from local storage
        const accessToken = getToken();
  
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/content-head/cw/asingn/task/${id}?status=${statusFilter}&page=${currentPage}&limit=${pageSize}`, // Include status filter in the API request
          {
            headers: {
              'Content-Type': 'application/json',
              // Include the access token in the Authorization header
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        // Reset apiData to the newly fetched data
        setApiData(data?.data || []);
        setTotalItems(data?.totalCount || 0);
        console.log('Fetched data:', data?.data); // Debugging line
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [statusFilter, currentPage, pageSize, id]);
  




  const handleClick = useCallback((id, status) => {
    console.log("status>>>", status);
    const url = `/content-head-dashboard/blog-preview/${id}`;
    console.log('url', url);
    if (status === 'Done' || status === 'Review') {
      navigate(url); // Only navigate on 'Done' or 'Review' status
    } else {
      toast.error('Blog has not been submitted yet.');
    }
  }, [navigate]);

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value); // Update status filter when dropdown value changes
  };

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update current page
  };

  return (
    <div className="relative w-full">
      {/* Dropdown for status filter */}
      <div className="flex justify-end items-center gap-x-4">
        <h1>Filter By Status</h1>
        <select value={statusFilter} onChange={handleStatusChange} className="my-4 border rounded">
          <option value="">All</option>
          <option value="Pending">Pending</option>
          <option value="Review">Review</option>
          <option value="Done">Done</option>
        </select>
      </div>
      <table className="w-full overflow-x-scroll text-left text-xs">
        {/* Table headers */}
        <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
          <tr>
            <th scope="col" className="px-4 py-2">Sl. No</th>
            <th scope="col" className="px-4 py-2">Blog type</th>
            <th scope="col" className="px-4 py-2">Blog name</th>
            <th scope="col" className="px-4 py-2">Brand Name</th>
            <th scope="col" className="px-4 py-2">Product Name</th>
            <th scope="col" className="px-4 py-2">Assigned Date</th>
            <th scope="col" className="px-4 py-2">Submission Date</th>
            <th scope="col" className="px-4 py-2">Status</th>
            <th scope="col" className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody>
          {Array.isArray(apiData) &&
            apiData.map((item, index) => {
              const {
                _id,
                blog_type,
                blog_topic,
                brand_name,
                product_name,
                assign_date,
                submit_date,
                status,
                blog_content_id,
              } = item;

              return (
                <tr key={_id}>
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="px-4 py-2">{blog_type}</td>
                  <td className="px-4 py-2"><Description description={blog_topic} /></td>
                  <td className="px-4 py-2">{brand_name}</td>
                  <td className="px-4 py-2">{product_name}</td>
                  <td className="px-4 py-2">{formatDate(assign_date)}</td>
                  <td className="px-4 py-2">{formatDate(submit_date)}</td>
                  <td className="px-4 py-2">{status}</td>
                  <td className="px-4 py-2">
                    <div className="flex gap-x-2 px-4">
                      <EyeButton id={_id} onClick={() => handleClick(blog_content_id, status)} />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>

      </table>
      <div className="py-2">
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          setCurrentPage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default AssociateBlogTable;
