import React, { useState } from 'react'
import UploadImage from './UploadImage'
import TopBar from './TopBar';
const HomepageBanner = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        image: initialValues?.image || '',
        mob_image: initialValues?.mob_image || '',
        redirect_url: initialValues?.redirect_url || '',
        mob_image_public_id: initialValues?.mob_image_public_id || '',
        image_public_id: initialValues?.image_public_id || ''
    });
    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };
    return (
        <div className='w-full flex flex-col border'>
            <TopBar name='Home Page Banner' index={index} />
            <div className='grid grid-cols-3 gap-2'>
                <div className='col-span-2'>
                    <UploadImage
                        id={`home-page-banner-${index}`}
                        height={`h-80`}
                        name={`image`}
                        aspectRatio={1920 / 500}
                        requiredRatio={`1920/500`}
                        tolerance={0.1}
                        value={formData?.image}
                        onChange={handleFieldChange}
                        title='Upload desktop banner'
                    />
                </div>
                <div>
                    <UploadImage
                        id={`home-page-mob-banner-${index}`}
                        height={`h-80`}
                        name={`mob_image`}
                        aspectRatio={400 / 500}
                        requiredRatio={`400/500`}
                        tolerance={0.1}
                        value={formData?.mob_image}
                        onChange={handleFieldChange}
                        title='Upload phone banner'
                    />
                </div>
            </div>
            <div className='my-2 px-5'>
                <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Redirect Url</label>
                <input
                    type="text"
                    id="left-image-with-text-headline"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}
                    value={formData?.redirect_url}
                    onChange={(e) => handleFieldChange('redirect_url', e.target.value)}
                />
            </div>
        </div>
    )
}

export default HomepageBanner