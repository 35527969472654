import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import getList from '../getList';
import { getToken } from '../../hook/getToken';
import EyeButton from './EyeButton';
import AssignCWModal from './AssignCWModal';
import Pagination from '../../Pagination';
import AddRequiredBlogModal from './AddRequiredBlogModal';

// Utility function to format the date
const formatDate = (dateString) => {

    if (dateString === 'N/A') {
        return 'N/A';
    }

    if (dateString === 'N/P') {
        return 'N/P';
    }
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};

const ApprovedBlogTable = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [showassignCWModal, setAssignCWModal] = useState(false);
    const [selectedBlogId, setSelectedBlogId] = useState(null);
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedBlogType, setSelectedBlogType] = useState("");
    const [selectedBlogStatus, setSelectedBlogStatus] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [showBlogModal, setShowBlogModal] = useState(false);
    const [modalName, setModalName] = useState("edit");

    const handleAddBlogRequirement = () => {
        setShowBlogModal(true);
    };
    const handleCloseBlogRequirement = () => {
        setShowBlogModal(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getToken();
                let url = `${process.env.REACT_APP_URL}/v1/blog/get?page=${currentPage}&limit=${pageSize}`;

                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setList(data.data);
                setTotalItems(data?.totalData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [selectedBrand, selectedBlogType, selectedBlogStatus, currentPage]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/v1/brand-registration/all-active-brand`);
                const data = await response.json();
                setBrands(data.brands);
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        };
        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL}/v1/blog/get-category`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setCategories(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleAssignContentWriter = (blogId) => {
        setSelectedBlogId(blogId);
        setAssignCWModal(true);
    };

    const handleCloseAssignCwModal = () => {
        setAssignCWModal(false);
    };

    const handleClick = useCallback((id) => {
        const url = `/content-head-dashboard/blog-preview/${id}`;
        navigate(url);
    }, [navigate]);

    const handlePostBlog = async (contentId) => {
        try {
            const token = getToken();
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/blog/post/${contentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to post blog');
            }
            console.log('Blog posted successfully');
            // Optionally, update the state or UI to reflect the successful posting
            setList(list.map(item => item.blog_content_id === contentId ? { ...item, isPosted: true } : item));
        } catch (error) {
            console.error('Error posting blog:', error);
        }
    };

    const handleUnpostBlog = async (contentId) => {
        try {
            const token = getToken();
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/blog/post/${contentId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to unpost blog');
            }
            console.log('Blog unposted successfully');
            // Optionally, update the state or UI to reflect the successful unposting
            setList(list.map(item => item.blog_content_id === contentId ? { ...item, isPosted: false } : item));
        } catch (error) {
            console.error('Error unposting blog:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="relative overflow-hidden">
            <h4 className='text-left text-xl text-[#383E50] font-medium py-2'>List of Approved Blogs</h4>
     
            <table className="w-full text-left text-xs">
                <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
                    <tr>
                        <th scope="col" className="px-4 py-2">
                            Sl. NO
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Blog type
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Blog Title
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Approved Date
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Post Date
                        </th>
                        <th scope="col" className="px-4 py-2">
                            Actions (View Details)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(list) &&
                        list.map((item, index) => {
                            const { _id, blog_category, title,approved_date,postDate, blog_category_id, blog_content_id, isPosted } = item;

                            return (
                                <tr key={_id}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">{blog_category}</td>
                                    <td className="px-4 py-2">{title}</td>
                                    
                                    <td className="px-4 py-2">{formatDate(approved_date)}</td>
                                    <td className="px-4 py-2">{formatDate(postDate)}</td>
                                    <td className="px-4 py-2">
                                        <div className='flex gap-6'>
                                            <div className="" onClick={() => handleClick(blog_content_id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                </svg>
                                            </div>
                                            <div className="">
                                                {isPosted ? (
                                                    <button
                                                        className='text-white px-4 py-2 bg-green-500 rounded'
                                                        onClick={() => handleUnpostBlog(blog_content_id)}
                                                    >
                                                        Unpost
                                                    </button>
                                                ) : (
                                                    <button
                                                        className='text-white px-4 py-2 bg-red-500 rounded'
                                                        onClick={() => handlePostBlog(blog_content_id)}
                                                    >
                                                        Post
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <div className="py-2">
                <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    setCurrentPage={handlePageChange}
                />
            </div>
        </div>
    );
}

export default ApprovedBlogTable;
