import React, { useEffect, useState } from 'react';
import SideBar from '../Sidebar';
import { ContentHead } from '../../constant';
import { getToken } from '../../hook/getToken';
import UpoladModal from './UpoladModal';
import Pagination from '../../Pagination';

const ImageUpload = () => {
  const [fileData, setFileData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const token = getToken();

  useEffect(() => {
    // Fetch file data when the component mounts
    fetchFileData();
  }, [currentPage]);

  const fetchFileData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/get/files?page=${currentPage}&limit=${pageSize}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch file data');
      }
      const data = await response.json();
      setFileData(data?.files);
      setTotalItems(data?.totalCount)
    } catch (error) {
      console.error('Error fetching file data:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url); // Copy URL to clipboard
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
};


  return (
    <div className="flex h-screen">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentHead} />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-end mb-4 mr-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white text-base font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2"
            onClick={() => setShowModal(true)}
          >
            Upload Image/Video
          </button>
        </div>
        {showModal && <UpoladModal visible={showModal} onClose={handleCloseModal} />}
        <div className="overflow-auto">
          <table className="w-full text-left text-xs">
            <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
              <tr>
                <th scope="col" className="px-4 py-2">
                  Sl. NO
                </th>
                <th scope="col" className="px-4 py-2">
                  Image
                </th>
                <th scope="col" className="px-4 py-2">
                  File Type
                </th>
                <th scope="col" className="px-4 py-2">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {fileData.map((item, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">
                    <img src={item.fileUrl} alt={`Image ${index + 1}`} className="h-10 w-10" />
                  </td>
                  <td className="border px-4 py-2">{item.fileType}</td>
                  <td className="border px-4 py-2">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleCopyUrl(item.fileUrl)}
                    >
                      Copy URL
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="py-2">
                <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    setCurrentPage={handlePageChange}
                />
            </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
