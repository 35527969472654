import React, { createContext, useState, useContext, useMemo } from 'react';

export const CmsContext = createContext();

export const CmsProvider = ({ children }) => {
    const [isAddModuleModal, setIsAddModuleModal] = useState(false);
    const [elements, setElements] = useState([]);
    const [contentName, setContentName] = useState('');
    const [contentDesc, setContentDesc] = useState('')
    const [isEdit, setIsEdit] = useState(false);

    const addElement = (newElement) => {
        setElements([...elements, newElement]);
        setIsAddModuleModal(false);
    };

    const removeElement = (index) => {
        const newArray = [...elements];
        newArray.splice(index, 1);
        setElements(newArray);
    };

    const moveUp = (index) => {
        if (index > 0) {
            const newArray = [...elements];
            const temp = newArray[index];
            newArray[index] = newArray[index - 1];
            newArray[index - 1] = temp;
            setElements(newArray);
        }
    };

    const moveDown = (index) => {
        if (index < elements.length - 1) {
            const newArray = [...elements];
            const temp = newArray[index];
            newArray[index] = newArray[index + 1];
            newArray[index + 1] = temp;
            setElements(newArray);
        }
    };

    const updateElements = (newElements) => {
        setElements(newElements);
    };

    const handleForm = () => {
        console.log('elements-form data', elements);
    };

    const memoizedValue = useMemo(() => ({
        isAddModuleModal,
        setIsAddModuleModal,
        elements,
        addElement,
        removeElement,
        moveUp,
        moveDown,
        updateElements,
        handleForm,
        contentName,
        setContentName,
        isEdit,
        setIsEdit,
        setContentDesc,
        contentDesc
    }), [isAddModuleModal, elements, contentName, isEdit, contentDesc]);

    return (
        <CmsContext.Provider value={memoizedValue}>
            {children}
        </CmsContext.Provider>
    );
};

export const useCms = () => {
    const contextValue = useContext(CmsContext);
    return useMemo(() => contextValue, [contextValue]);
};