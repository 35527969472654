import React, { useState, useEffect } from "react";
import ManageStats from "./ManagerStats";

import SideBar from "../Sidebar";
import Layout from "../../Routing/Layout";
import { ContentWriter } from "../../constant";

import LogOutModal from "../Logout";
import CmStats from "./CwStats";
import OnboardedSellers from "../cm/OnboardedSellers";
import { getToken } from "../../hook/getToken";

const ContentWriterDashboard = () => {
  const [approvedSellers, setApprovedSellers] = useState();
  const [pendingSellers, setPendingSellers] = useState();
  const [approvedcount, setApprovedCount] = useState();
  const [pendingCount, setPendingCount] = useState();
  const token = getToken();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const fetchData = async () => {
    const url =
      process.env.REACT_APP_URL +
      "/v1/category-manager/seller-list?status=approved&page=1&limit=10";
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      setApprovedSellers(data.approvedseller);
      setApprovedCount(data.count);
    } else {
      console.error("Failed to fetch");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchSellers = async () => {
    const url =
      process.env.REACT_APP_URL +
      "/v1/category-manager/seller-list?status=pending&page=1&limit=10";
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      setPendingSellers(data.pendingSeller);
      setPendingCount(data.count);
    } else {
      console.error("Failed to fetch");
    }
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  const handleLogout = () => {
    setShowLogoutModal(true);
  };
  const handleClose = () => {
    setShowLogoutModal(false);
  };

  return (
    <main className="max-w-full flex">
      <div className="sidebar bg-[#00388c] h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className="max-w-full grow mx-4">
        <section className="flex flex-row justify-between py-5 items-center">
          <div>
            <h1 className=" text-2xl md:text-3xl text-[#383E50] font-semibold leading-10">
              CW Dashboard
            </h1>
            <p className="text-sm leading-6 font-normal">
              Whole data about your business here
            </p>
          </div>
          <div className="flex gap-5">
            <div className="flex items-center" onClick={handleLogout}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
            </div>
            {showLogoutModal && (
              <LogOutModal visible={showLogoutModal} onClose={handleClose} />
            )}

            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                />
              </svg>
            </div>
          </div>
        </section>
        <CmStats />
      </div>
    </main>
  );
};

export default ContentWriterDashboard;
