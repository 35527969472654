import React, { useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { getToken } from "../../hook/getToken";
export const WalletOfferForm = () => {
    const [id, setId] = useState(null)
    const [formData, setFormData] = useState({
        offerName: "",
        description: "",
        validityStart: "",
        validityEnd: "",
        thresholds: [
            { label: "", min: "", max: "", extraMoney: "" },
        ],
        isActive: false,
        termsAndConditions: [""],
    });

    const handleFieldChange = (fieldName, value) => {
        console.log('field value', fieldName, value);
        setFormData(prevState => ({ ...prevState, [fieldName]: value }));
    };


    // Handlers for offers
    const handleOfferChange = (index, field, value) => {
        const updatedOffers = [...formData.thresholds];
        if (field == 'extraMoney') {
            if (Number(value) > Number(updatedOffers[index]['min'])) {
                return toast.error("Extra amount can not be more than Adding amount")
            }
        }
        updatedOffers[index][field] = value;
        setFormData({ ...formData, thresholds: updatedOffers });
    };

    const removeOffer = useCallback(
        (index) => {
            setFormData((prevData) => {
                const updatedOffers = prevData.thresholds.filter((_, i) => i !== index);
                return { ...prevData, thresholds: updatedOffers };
            });
        },
        [setFormData]
    );

    const addOffer = useCallback(() => {
        setFormData((prevData) => ({
            ...prevData,
            thresholds: [...prevData.thresholds, { label: "", min: "", max: "", extraMoney: "" }],
        }));
    }, []);

    // Handlers for terms
    const handleTermChange = (index, value) => {
        setFormData((prevFormData) => {
            const updatedTerms = [...prevFormData.termsAndConditions];
            updatedTerms[index] = value;
            return { ...prevFormData, termsAndConditions: updatedTerms };
        });
    };

    const addTerm = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            termsAndConditions: [...prevFormData.termsAndConditions, ""],
        }));
    };

    const removeTerm = (index) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            termsAndConditions: prevFormData.termsAndConditions.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form from refreshing the page
        console.log("Submitted Data:", formData);
        const url = id ? `${process.env.REACT_APP_URL}/v1/wallet/update-wallet-offer/${id}` : `${process.env.REACT_APP_URL}/v1/wallet/create-wallet-offer`
        const method = id ? "PUT" : "POST"

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json', // Ensure headers are properly formatted
                    'Authorization': `Bearer ${getToken()}` // Capitalize 'Bearer'
                },
                body: JSON.stringify(formData), // Convert form data to JSON string
            });

            const resData = await response.json(); // Parse response JSON

            if (response.ok) {
                // Reset form if submission is successful
                const message = id ? "Offer updated successfully" : "Offer Added Successfully"
                toast.success(message)
                window.location.reload()
            } else {
                // Handle HTTP errors
                console.error("Error submitting form:", resData.message || "Unknown error");
                toast.error(resData.error)
            }
        } catch (error) {
            // Handle fetch/network errors
            console.error("An error occurred:", error.message);
        }
    };


    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/v1/wallet/get-wallet-offer`).then(res => res.json()).then(data => {
            if (data.success) {
                setId(data.data._id)
                // Remove unwanted fields
                delete data.data._id;
                delete data.data.createdAt;
                delete data.data.updatedAt;
                delete data.data.__v;
                data.data.validityStart = new Date(data.data.validityStart).toISOString().split("T")[0];
                data.data.validityEnd = new Date(data.data.validityEnd).toISOString().split("T")[0];
                data.data.thresholds.map(d =>
                    delete d._id
                )
                setFormData(data.data)
            }
        })
    }, [])

    return (
        <div className="w-11/12 text-start">
            <form onSubmit={(e) => handleSubmit(e)}>
                {/* Main Fields */}
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700">
                        Offer Name
                    </label>
                    <input
                        type="text"
                        value={formData.offerName}
                        required
                        onChange={(e) => handleFieldChange("offerName", e.target.value)}
                        className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700">
                        Description
                    </label>
                    <textarea
                        value={formData.description}
                        onChange={(e) => handleFieldChange("description", e.target.value)}
                        className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        rows="3"
                    ></textarea>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Start Date
                        </label>
                        <input
                            type="date"
                            value={formData.validityStart}
                            required
                            onChange={(e) => handleFieldChange("validityStart", e.target.value)}
                            className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            End Date
                        </label>
                        <input
                            type="date"
                            value={formData.validityEnd}
                            required
                            onChange={(e) => handleFieldChange("validityEnd", e.target.value)}
                            className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>

                {/* Offers Section */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-4">Offers</h3>
                    {formData?.thresholds?.map((offer, index) => (
                        <div key={index} className="flex space-x-2 items-center mb-2">
                            <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Label
                                    </label>
                                    <input
                                        type="text"
                                        value={offer.label}
                                        required
                                        onChange={(e) =>
                                            handleOfferChange(index, "label", e.target.value)
                                        }
                                        className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Min
                                    </label>
                                    <input
                                        type="number"
                                        value={offer.min}
                                        required
                                        onChange={(e) =>
                                            handleOfferChange(index, "min", e.target.value)
                                        }
                                        className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Max
                                    </label>
                                    <input
                                        type="number"
                                        value={offer.max}
                                        required
                                        onChange={(e) =>
                                            handleOfferChange(index, "max", e.target.value)
                                        }
                                        className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Amount
                                    </label>
                                    <input
                                        type="number"
                                        value={offer.extraMoney}
                                        required
                                        onChange={(e) =>
                                            handleOfferChange(index, "extraMoney", e.target.value)
                                        }
                                        className="py-1 px-4 mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                            </div>
                            {index < formData?.thresholds?.length - 1 && (
                                <button
                                    type="button"
                                    onClick={() => removeOffer(index)}
                                    className=""
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </button>
                            )}
                            {
                                index == formData?.thresholds?.length - 1 ? (
                                    <button
                                        type="button"
                                        onClick={addOffer}
                                        className=""
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </button>
                                ) : null
                            }

                        </div>
                    ))}

                </div>

                {/* Terms Section */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-4">Terms</h3>
                    {formData?.termsAndConditions?.map((term, index) => (
                        <div key={index} className="flex items-center space-x-2 mb-2 ">
                            <input
                                type="text"
                                value={term}
                                onChange={(e) => handleTermChange(index, e.target.value)}
                                className="flex-1 py-1 px-4 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                            {index < formData?.termsAndConditions?.length - 1 && (
                                <button
                                    type="button"
                                    onClick={() => removeTerm(index)}
                                    className=""
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>

                                </button>
                            )}
                            {index == formData?.termsAndConditions?.length - 1 && (
                                <button
                                    type="button"
                                    onClick={addTerm}
                                    className=""
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>

                                </button>
                            )
                            }
                        </div>
                    ))}

                </div>
                <div className="flex space-x-5 items-center">
                    <div className="flex space-x-2 items-center">
                        <input
                            type="radio"
                            checked={Boolean(formData.isActive)}
                            onChange={(e) =>
                                handleFieldChange('isActive', true)
                            }
                            className="w-5 h-5 py-1 px-4 mt-1 block rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <label className="block text-sm font-medium text-gray-700">
                            Active
                        </label>
                    </div>
                    <div className="flex space-x-2 items-center">
                        <input
                            type="radio"
                            checked={!Boolean(formData.isActive)}
                            onChange={(e) =>
                                handleFieldChange('isActive', false)
                            }
                            className="w-5 h-5 py-1 px-4 mt-1 block rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        <label className="block text-sm font-medium text-gray-700">
                            InActive
                        </label>
                    </div>
                </div>

                {/* Submit */}
                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="submit"

                        className="py-2 px-12 bg-green-500 text-white rounded-md shadow "
                    >
                        {id ? "Update" : "Submit"}
                    </button>
                </div>
            </form>
        </div >
    )
}