import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import TextEditor from '../blog-category/TextEditor';
import { getToken } from '../../hook/getToken';
const AddSeodataModal = ({ data, visible, onClose }) => {
    const { _id, pageName, title, description, keywords, canonicalUrl, heading, content, ogImageUrl } = data || {};
    const [tags, setTags] = useState(keywords || []);
    const [metaTitle, setMetaTitle] = useState(title);
    const [metaDesc, setMetaDesc] = useState(description);
    const [canonical, setCanonical] = useState(canonicalUrl);
    const [htmlContent, setHtmlContent] = useState(content);
    const [h1, setH1] = useState(heading);
    const [ogImage, setOgImage] = useState(ogImageUrl);
    const [pageTitle, setPageTitle] = useState(pageName);
    useEffect(() => {
        if (keywords && keywords.length > 0) {
            setTags(keywords);
        }
    }, [keywords]);

    const handleTagsChange = (e) => {
        const tagsArray = e.target.value.split(',').map(tag => tag.trim());
        setTags(tagsArray);
    };

    const handleTitleChange = (e) => {
        setMetaTitle(e.target.value);
    };

    const handleDescChange = (e) => {
        setMetaDesc(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            _id: _id,
            title: metaTitle,
            description: metaDesc,
            keywords: tags,
            canonicalUrl: canonical,
            content: htmlContent,
            heading: h1,
            ogImageUrl: ogImage,
            pageName: pageTitle
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/seo/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`,
                },
                body: JSON.stringify(payload),
            });
            if (response.ok) {
                // Handle successful response
                toast.success('Meta tags added successfully');
                onClose();
            } else {
                // Handle error response
                console.error('Failed to add meta tags');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto ${visible ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl h-[35rem] overflow-y-auto sm:w-full overflow-hidden">
                    <form onSubmit={handleSubmit} className='relative'>
                        <h1 className="sticky top-0 text-xl font-bold px-4 py-4 bg-white">Add and Edit Seo Data</h1>
                        <div className="grid grid-cols-1 gap-2">
                            <div className="px-4">
                                <div className="flex flex-col">
                                    <label htmlFor="pageName" className="block text-sm font-medium text-gray-700">
                                        Page Name
                                    </label>
                                    <input
                                        id="pageName"
                                        type="text"
                                        name="pageName"
                                        value={pageTitle}
                                        onChange={(e) => setPageTitle(e.target.value)}

                                        className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter Page heading"
                                    />
                                </div>
                            </div>
                            <div className="px-4">
                                <div className="flex flex-col">
                                    <label htmlFor="heading" className="block text-sm font-medium text-gray-700">
                                        Heading
                                    </label>
                                    <input
                                        id="heading"
                                        type="text"
                                        name="heding"
                                        value={h1}
                                        onChange={(e) => setH1(e.target.value)}

                                        className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter Page heading"
                                    />
                                </div>
                            </div>

                            <div className="px-4">
                                <div className="flex flex-col">
                                    <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
                                        Meta Title:
                                    </label>
                                    <input
                                        id="metaTitle"
                                        type="text"
                                        name="metaTitle"
                                        value={metaTitle}
                                        onChange={handleTitleChange}

                                        className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter meta title"
                                    />
                                </div>
                            </div>
                            <div className="px-4">
                                <div className="flex flex-col">
                                    <label htmlFor="metaDesc" className="block text-sm font-medium text-gray-700">
                                        Meta Description:
                                    </label>
                                    <textarea
                                        id="metaDesc"
                                        type="text"
                                        name="metaDesc"
                                        value={metaDesc}
                                        onChange={handleDescChange}

                                        className="mt-1 p-1 border-gray-300 text-black block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter meta description"
                                    />
                                </div>
                            </div>
                            <div className="px-4 pb-4">
                                <div className="flex flex-col">
                                    <label htmlFor="canonical" className="block text-sm font-medium text-gray-700">
                                        Canonical Link
                                    </label>
                                    <input
                                        id="canonical"
                                        type="text"
                                        name="canonical"
                                        value={canonical}
                                        onChange={(e) => setCanonical(e.target.value)}

                                        className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter canonical link"
                                    />
                                </div>
                            </div>
                            <div className="px-4 pb-4">
                                <div className="flex flex-col">
                                    <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                                        Meta Tags:
                                    </label>
                                    <input
                                        id="tags"
                                        type="text"
                                        name="tags"
                                        value={tags.join(', ')}
                                        onChange={handleTagsChange}

                                        className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter tags separated by commas"
                                    />
                                </div>
                            </div>
                            <div className="px-4">
                                <div className="flex flex-col">
                                    <label htmlFor="ogImage" className="block text-sm font-medium text-gray-700">
                                        OgImage URL
                                    </label>
                                    <input
                                        id="ogImage"
                                        type="text"
                                        name="ogImage"
                                        value={ogImage}
                                        onChange={(e) => setOgImage(e.target.value)}

                                        className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                                        placeholder="Enter Page heading"
                                    />
                                </div>
                            </div>
                            <div className='p-1'>
                                <TextEditor
                                    htmlContent={htmlContent}
                                    onChange={setHtmlContent}
                                />
                            </div>

                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={onClose}
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddSeodataModal;
