import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import { ContentWriter } from "../../../constant";
import { getToken } from "../../../hook/getToken";
import { Link } from "react-router-dom";
import DeclineReasonModal from "../DeclineReasonModal";

const DeclinedTask = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const limit = 10; // Number of tasks per page
  const accessToken = getToken(); // Replace with your access token
  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    const accessToken = getToken();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/content-head/get/blogs?status=declined&page=1&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Replace YOUR_ACCESS_TOKEN with the actual access token
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setBlogs(data.data);
      } else {
        console.error("Failed to fetch blogs:", data.message);
      }
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    }
  };
  console.log("Blogs data>>>>", blogs);

  // Handle pagination
  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const openModal = (blogId) => {
    setSelectedBlogId(blogId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBlogId(null);
  };

  return (
    <main className="flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentWriter} />
      </div>
      <div className="w-full p-2 pt-8 text-xs">
        <div className="w-full">
          <table className="w-full  border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2">Sl. No</th>
                <th className="px-4 py-2">Blog Type</th>
                <th className="px-4 py-2">Blog Topic</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Reason for Decline</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((task, index) => (
                <tr key={task._id} className="hover:bg-gray-100">
                  <td className="border-t px-4 py-2">{index + 1}</td>
                  <td className="border-t px-4 py-2">{task.blog_category}</td>
                  <td className="border-t px-4 py-2 text-center">{task.title} </td>

                  <td className="border-t px-4 py-2">{task.status}</td>
                  <td className="border-t px-4 py-2">
                    <div className="flex justify-center"  onClick={() => openModal(task.blog_content_id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    </div>
                  </td>
                  <td className="border-t px-4 py-2">
                    <Link
                      key={task._id}
                      to={{
                        pathname: `/content-writer/blog-preview/${task.blog_content_id}`,
                      }}
                      target="_blank" // Add this line
                      rel="noopener noreferrer" // Add this line for security
                      className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                    >
                      Preview
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {openModal && (
            <DeclineReasonModal 
            isOpen={isModalOpen}
            onClose={closeModal}
            id={selectedBlogId}
            />
          )}
          {/* Pagination */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePagination(page)}
                className={`mx-1 px-3 py-1 rounded-md ${currentPage === page
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
              >
                {page}
              </button>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default DeclinedTask;
