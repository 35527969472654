import React from "react";
import SideBar from "../Sidebar";
import { ContentHead } from "../../constant";
import PaymentDetailsTable from "./PaymentDetailsTable";

const PaymentDetails = () => {
  return (
    <main className="w-full flex flex-row gap-x-5">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentHead} />
      </div>
      <div className="w-full">
        <PaymentDetailsTable />
      </div>
    </main>
  );
};

export default PaymentDetails;
