import React, { useState, useEffect } from "react";
import { getToken } from "../../hook/getToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faBox,
  faTruck,
  faCheckCircle,
  faSpinner,faTimes,faUndo,faDollarSign
} from "@fortawesome/free-solid-svg-icons"; // Import icons

const ViewModal = (props) => {
  const { visible, onClose, id } = props;
  const [orderData, setOrderData] = useState(null);
  const [trackingData, setTrackingData] = useState(null); 

  useEffect(() => {
    const token = getToken();
    const fetchOrderData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/order/get-order/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        setOrderData(data.data); // Set full order data
        
        // Extract and set tracking data
        if (data.data?.traking) {
          setTrackingData(data.data.traking);
        } else {
          console.warn("Tracking data is missing.");
          setTrackingData([]); // Set empty array to avoid errors
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };
  
    fetchOrderData();
  }, [id]);

  const getOrderStatus = (currentStatus) => {
    // Safeguard for missing or undefined data
    if (!trackingData || trackingData.length === 0) {
      console.error("Tracking data is missing or empty.");
      return <p>No tracking updates available.</p>;
    }
  
    const statusOrder = [
      { key: "order_placed", icon: faBoxOpen, label: "Order Placed" },
      { key: "in_progress", icon: faSpinner, label: "In Progress" },
      { key: "shipped", icon: faBox, label: "Shipped" },
      { key: "out_for_delivery", icon: faTruck, label: "Out For Delivery" },
      { key: "delivered", icon: faCheckCircle, label: "Delivered" },
      { key: "return_request", icon: faUndo, label: "Return Request" },
      { key: "pickup", icon: faBox, label: "Pickup" },
      { key: "returned", icon: faBoxOpen, label: "Returned" },
      { key: "refunded", icon: faDollarSign, label: "Refunded" },
      { key: "cancelled", icon: faTimes, label: "Cancelled" },
    ];
  
    // Extract the tracking labels from the API data
    const apiStatuses = trackingData.map(status => status.label);
  
    // Filter the statuses based on what is present in the API data
    const filteredStatuses = statusOrder.filter(status => apiStatuses.includes(status.label));
  
    // Find the index of the current status in the filtered list
    const currentIndex = filteredStatuses.findIndex(step => step.key === currentStatus);
  
    // Handle unknown current status
    if (currentIndex === -1) {
      return <p>Unknown status: {currentStatus}</p>;
    }
  
    const relevantStatuses = filteredStatuses.slice(0, currentIndex + 1);
  
    const getColorStyles = (key, isCompleted) => {
      switch (key) {
        case "order_placed":
          return isCompleted ? "bg-yellow-500 text-yellow-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "in_progress":
          return isCompleted ? "bg-purple-500 text-purple-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "shipped":
          return isCompleted ? "bg-orange-500 text-orange-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "out_for_delivery":
          return isCompleted ? "bg-green-500 text-green-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "delivered":
          return isCompleted ? "bg-blue-500 text-blue-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "cancelled":
          return isCompleted ? "bg-red-500 text-red-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "return_request":
          return isCompleted ? "bg-teal-500 text-teal-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "pickup":
          return isCompleted ? "bg-indigo-500 text-indigo-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "returned":
          return isCompleted ? "bg-pink-500 text-pink-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        case "refunded":
          return isCompleted ? "bg-cyan-500 text-cyan-700 shrink-0" : "bg-gray-400 text-gray-500 shrink-0";
        default:
          return "bg-gray-400 text-gray-500 shrink-0";
      }
    };
  
    return (
      <ol className="flex items-center w-full">
        {relevantStatuses.map((step, index) => {
          const isCompleted = index <= currentIndex;
          const lineStyle = index !== relevantStatuses.length - 1
            ? `after:w-full after:border-b ${isCompleted ? "after:border-blue-500" : "after:border-gray-200"}`
            : "";
  
          const iconStyle = getColorStyles(step.key, isCompleted);
  
          return (
            <li key={index} className={`flex items-center w-full text-center ${lineStyle}`}>
              <span className={`flex items-center justify-center w-10 h-10 rounded-full ${iconStyle}`}>
                <FontAwesomeIcon icon={step.icon} className="text-white" />
              </span>
              <span className="text-xs ml-2">{step.label}</span>
              {index !== relevantStatuses.length - 1 && (
                <div className={`flex-1 h-1 ${isCompleted ? "bg-blue-900" : "bg-gray-200"}`} />
              )}
            </li>
          );
        })}
      </ol>
    );
  };
  
  
  
  if (visible && orderData)
    return (
      <div className="fixed inset-0 overflow-x-hidden bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center my-auto">
        <div className="flex no-scrollbar overflow-y-scroll h-4/5 flex-col bg-white rounded-md max-w-screen-lg w-full">
          {/* Close Button */}
          <div className="flex items-start justify-end px-4 py-2 border-solid border-slate-200 rounded-t">
            <button
              className="text-2xl fixed leading-none font-semibold hover:text-[#50C4D9] transition duration-300"
              type="button"
              onClick={() => onClose()}
            >
              x
            </button>
          </div>

          {/* Order Details */}
          <div className="p-4">
            <h1 className="text-lg text-left font-semibold mb-4">
              Order Details
            </h1>

            {/* Order Info */}
            <section className="mb-4 border-b pb-4">
              <h2 className="text-lg text-left font-semibold mb-2">
                Order #{orderData.user_order_id}
              </h2>
              <div className="flex gap-5 items-center">
                <p className="font-semibold">
                  Payment Method:{" "}
                  <span
                    className={
                      orderData.payment_method === "cash"
                        ? "bg-green-100 p-1 rounded"
                        : "bg-red-100 p-1 rounded"
                    }
                  >
                    {orderData.payment_method === "cash"
                      ? "Cash on delivery"
                      : "Other"}
                  </span>
                </p>
                <p className="">
                  Payment Status:{" "}
                  <span
                    className={
                      orderData.payment_status === "due"
                        ? "bg-yellow-100 p-1 rounded"
                        : "bg-blue-100 p-1 rounded"
                    }
                  >
                    {orderData.payment_status === "due" ? "Pending" : "Paid"}
                  </span>
                </p>
              </div>
              <p className="text-right text-blue-500 text-xl font-semibold">
                ₹{orderData.total_order_amount}
              </p>
            </section>

            {/* Connected Progress Bar with Icons */}
            <section className="mb-4 ">
              <div className="flex justify-center pl-28 mb-2">
              {getOrderStatus(orderData?.order_status)}
              </div>
            </section>

            {/* Rest of the component code remains the same */}
            <section className="mb-4">
              <h2 className="text-sm text-left font-semibold mb-2">
                Customer Info
              </h2>
              <div className="flex gap-16">
                {/* Shipping Address */}
                <div className="w-1/2 p-2 text-left bg-gray-100">
                  <h3 className="text-md font-semibold mb-1">
                    Shipping Address
                  </h3>
                  <p>Name: {orderData.usersInfo.name}</p>
                  <p>Mobile: {orderData.usersInfo.mobile}</p>
                  <p>
                    Address: {orderData.usersInfo.house_address},{" "}
                    {orderData.usersInfo.area_address},{" "}
                    {orderData.usersInfo.city}, {orderData.usersInfo.state},
                    Pincode: {orderData.usersInfo.pincode}
                  </p>
                </div>

                {/* Billing Address */}
                <div className="w-1/2 p-2 text-left bg-gray-100">
                  <h3 className="text-md font-semibold mb-1">
                    Billing Address
                  </h3>
                  <p>Name: {orderData.usersInfo.name}</p>
                  <p>Mobile: {orderData.usersInfo.mobile}</p>
                  <p>
                    Address: {orderData.usersInfo.house_address},{" "}
                    {orderData.usersInfo.area_address},{" "}
                    {orderData.usersInfo.city}, {orderData.usersInfo.state},
                    Pincode: {orderData.usersInfo.pincode}
                  </p>
                </div>
              </div>
            </section>

            {/* Product Details Table */}
            <section className="mb-4 text-left">
              <h2 className="text-sm  font-semibold mb-2">Product Details</h2>
              <table className="w-full border border-gray-200 rounded-md shadow-md">
                <thead>
                  <tr className="bg-gray-100 border-b">
                    <th className="border px-4 py-2 text-left">
                      Product Image
                    </th>
                    <th className="border px-4 py-2 text-left">Product Name</th>
                    <th className="border px-4 py-2 text-left">Variation</th>
                    <th className="border px-4 py-2 text-left">Quantity</th>
                    <th className="border px-4 py-2 text-left">List Price</th>
                    <th className="border px-4 py-2 text-left">Discount</th>
                    <th className="border px-4 py-2 text-left">
                      Discounted Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.orders.map((item, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                    >
                      <td className="border px-4 py-2">
                        <img
                          src={item.product_images}
                          alt={item.product_name}
                          className="w-8 h-18 object-cover rounded-md"
                        />
                      </td>
                      <td className="border px-4 py-2">{item.product_name}</td>
                      <td className="border px-4 py-2">
                        {item.variation_name1 && item.variation_value1
                          ? `${item.variation_name1}: ${item.variation_value1}`
                          : ""}
                        {item.variation_name2 && item.variation_value2
                          ? `, ${item.variation_name2}:${item.variation_value2}`
                          : ""}
                      </td>

                      <td className="border px-4 py-2">{item.order_qty}</td>
                      <td className="border px-4 py-2">₹{item.list_price}</td>
                      <td className="border px-4 py-2">
                        ₹{item.coupon_discount}
                      </td>
                      <td className="border px-4 py-2">₹{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>

            {/* Seller Info */}
            <section className="text-left">
              <h2 className="text-sm font-semibold mb-2">Seller Information</h2>
              <div className="mb-2 p-2 rounded-md items-center">
                <p>Seller Name: {orderData.seller_name}</p>
                <p>Seller Order ID: {orderData.seller_order_id}</p>
                <p>User Order ID: {orderData.user_order_id}</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    );

  return null;
};

export default ViewModal;
