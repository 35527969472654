import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

const SplashScreenModal = ({ modalName, onClose, id }) => {
  const [image, setImage] = useState(''); // Store base64 image string
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  // Fetch data when modal is in edit mode and id is available
  useEffect(() => {
    if (modalName === 'edit' && id) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_URL}/v1/app/splash-screen/get/${id}`);
          if (response.ok) {
            const fetchedData = await response.json();

            // Update state with fetched data
            setImage(fetchedData.data.image || ''); // Assuming the response contains base64 image data
            setTitle(fetchedData.data.heading || '');
            setDescription(fetchedData.data.description || '');
          } else {
            console.error("Failed to fetch splash screen data:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching splash screen data:", error);
        }
      };

      fetchData();
    }
  }, [modalName, id]);

  // Convert image to Base64 string when user selects a file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set base64 encoded image string
      };
      reader.readAsDataURL(file); // Read the file as Data URL (base64 string)
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // Handle Add or Edit based on modalName
  const handleSave = async () => {
    const payload = {
      imageData: image, // Send the base64 image string as part of the body
      heading: title,
      description: description,
    };

    const endpoint = modalName === 'edit'
      ?`${process.env.REACT_APP_URL}/v1/app/splash-screen/edit/${id}` 
      : `${process.env.REACT_APP_URL}/v1/app/splash-screen/add`;

    try {
      const method = 'POST'
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json()
      if (response.ok) {
        toast.success(`${modalName === 'edit' ? data?.message : 'Splash screen added'} successfully!`);
        onClose(); // Close the modal on success
      } else {
        console.error(`Failed to ${modalName === 'edit' ? 'update' : 'add'} splash screen:`, response.statusText);
      }
    } catch (error) {
      console.error(`Error ${modalName === 'add' ? 'adding' : 'updating'} splash screen:`, error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/5">
        <h2 className="text-sm font-semibold mb-4">
          {modalName === 'edit' ? 'Edit Splash Screen' : 'Add Splash Screen'}
        </h2>

        {/* Image Input */}
        <div className="mb-4">
          <label className="block text-sm text-left font-medium text-gray-700">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            className="mt-2 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-gray-100 file:text-gray-700 hover:file:bg-gray-200"
            onChange={handleImageChange}
          />
        </div>

        {/* Image Preview */}
        {image && (
          <div className="mb-4">
            <label className="block text-sm text-left font-medium text-gray-700">Image Preview</label>
            <img src={image} alt="Preview" className="w-full h-32 object-cover mt-2 rounded-lg" />
          </div>
        )}

        {/* Title Input */}
        <div className="mb-4">
          <label className="block text-left text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="mt-2 p-2 border border-gray-300 rounded-lg w-full text-xs"
            placeholder="Enter title"
          />
        </div>

        {/* Description Input */}
        <div className="mb-4">
          <label className="block text-left text-sm font-medium text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            className="mt-2 p-2 border border-gray-300 rounded-lg w-full text-xs"
            placeholder="Enter description"
            rows={4}
          ></textarea>
        </div>

        {/* Save and Cancel Buttons */}
        <div className="flex justify-end">
          <button className="bg-gray-200 text-gray-700 py-2 px-4 rounded mr-2 text-xs" onClick={onClose}>
            Cancel
          </button>
          <button className="bg-blue-900 text-white py-1 px-3 rounded text-xs" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SplashScreenModal;
