import React, { useState, useEffect } from 'react';
import { getToken } from '../../hook/getToken';

const EditBlogModal = ({ visible, onClose, modalName, id }) => {
  console.log("Id>>>",id)
  // State variables to hold form data
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const token = getToken();

  useEffect(() => {
    // Fetch category name when component mounts or id changes
    if (modalName === 'edit') {
      fetchCategoryName();
    } else {
      setName(''); // Reset name if modalName is not 'edit'
    }
  }, [id, modalName]);

  const fetchCategoryName = async () => {
    try {
      if(modalName === "edit") {
        const response = await fetch(`${process.env.REACT_APP_URL}/v1/blog/get-category/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const responseData = await response.json();
        console.log("Response Data>>",responseData)
        if (!response.ok) {
          throw new Error(responseData.message || 'Failed to fetch category name');
        }
  
        setName(responseData?.data?.category_name);
      }



    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Failed to fetch category name');
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      // Define API endpoint based on modalName
      let apiUrl = '';
      if (modalName === 'edit') {
        apiUrl = `${process.env.REACT_APP_URL}/v1/blog/edit-category/${id}`;
      } else {
        apiUrl = `${process.env.REACT_APP_URL}/v1/blog/add-category`;
      }

      // Make POST request using fetch
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ category_name: name }),
      });

      // Handle response
      const responseData = await response.json();

      if (!response.ok) {
        // If response is not OK, throw an error
        throw new Error(responseData.message || 'Failed to submit form');
      }

      // Handle successful response
      console.log('Response:', responseData);
      // window.location.reload();
      // Close the modal after successful submission
      modalName("")
      onClose();
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
      setError(error.message || 'Failed to submit form. Please try again.'); // Set error state
    }
  };

  return (
    <div className={`fixed z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center ${visible ? '' : 'hidden'}`}>
      <div className="w-1/3 mx-auto bg-white rounded ">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div className="flex p-2 gap-5">
            <div className="w-full flex flex-col">
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">Name</p>
              <input className='border rounded shadow-sm' type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>

         

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-2">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBlogModal;
