import React, { useState } from "react";
import { ContentHead } from "../../constant";

import SideBar from "../Sidebar";
import NavigateBack from "../NavigateBack";
import LogOutModal from "../Logout";
import Stats from "./Stats";
import CwTable from "./CwTable";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordChangeModal from "./PasswordChangeModal";

const ContentHeadDashboard = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleLogout = () => {
    setShowLogoutModal(true);
  };
  const handleClose = () => {
    setShowProfileModal(false)
    setShowLogoutModal(false);
  };

  return (
    <main className="w-full flex flex-row gap-x-5">
      <div className="sidebar bg-[#335381] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentHead} />
      </div>
      <div className="flex-1 mr-5">
        <section className="flex flex-row justify-between py-5 items-center">
          <div className="flex space-x-2 items-center">
            <NavigateBack />
            <h1 className=" text-2xl text-[#383E50] font-semibold leading-10 text-left">
              CH Dashboard
            </h1>
          </div>
          <div className="flex flex-row gap-5">
            <div className="flex items-center" onClick={handleLogout}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
            </div>
            {showLogoutModal && (
              <LogOutModal visible={showLogoutModal} onClose={handleClose} />
            )}
            <div className="cursor-pointer" onClick={() =>setShowProfileModal(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </div>
            {showProfileModal && (
              <PasswordChangeModal
                onClose={handleClose}
              />
            )}
          </div>
        </section>
        <Stats />
        <section className="flex flex-col">
          <CwTable />
        </section>
      </div>
    </main>
  );
};

export default ContentHeadDashboard;
