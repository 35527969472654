import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Blocks = ({ orderData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [topCategories, setTopCategories] = useState([]);

  // Update `isLoading` based on `orderData`
  useEffect(() => {
    if (orderData) {
      setIsLoading(false);
    }
  }, [orderData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/categories/get/count`
        );
        if (response.ok) {
          const jsonData = await response.json();
          setTopCategories(jsonData);
        } else {
          console.error("Failed to fetch data from the API.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchData();
  }, []);

  // Loading state or display content
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="flex space-x-4">
        <div className="flex-1 pl-8 pr-14 py-4 text-left bg-white hover:bg-blue-100 border border-blue-300 rounded-md hover:shadow-2xl shadow-lg">
          <Link to="/b2buser" className="text-lg">
            B2B user
          </Link>
          <div className="flex gap-2">
            <p className="text-xl font-bold">{orderData?.b2b_active_count || 0}</p>
            {/* SVG Icon */}
          </div>
        </div>
        
        <div className="flex-1 pl-8 pr-14 py-4 text-left bg-white hover:bg-blue-100 border border-blue-300 rounded-md hover:shadow-2xl shadow-lg">
          <Link to="/category" className="text-lg">
            Categories
          </Link>
          <div className="flex gap-2">
            <p className="text-xl font-bold">{topCategories?.child || 0}</p>
            {/* SVG Icon */}
          </div>
        </div>
        
        <div className="flex-1 pl-8 pr-14 py-4 text-left bg-white hover:bg-blue-100 border border-blue-300 rounded-md hover:shadow-2xl shadow-lg">
          <Link to="/seller/sellerlist" className="text-lg">
            Sellers
          </Link>
          <div className="flex gap-2">
            <p className="text-xl font-bold">{orderData?.seller_count || 0}</p>
            {/* SVG Icon */}
          </div>
        </div>
        
        <div className="flex-1 pl-8 pr-14 py-4 text-left bg-white hover:bg-blue-100 border border-blue-300 rounded-md hover:shadow-2xl shadow-lg">
          <Link to="/coupons" className="text-lg">
            Coupons
          </Link>
          <div className="flex gap-2">
            <p className="text-xl font-bold">{orderData?.coupon_count || 0}</p>
            {/* SVG Icon */}
          </div>
        </div>
      </div>
    </div>
  );
};
