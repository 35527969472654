import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getList from "../getList";
import { getToken } from "../../hook/getToken";

const CmStats = () => {
  const [statData, setStatData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_URL}/v1/content-head/cw/stats`;
    const token = getToken();

    getList(url, token)
      .then((data) => {
        // Extracting stats from the response
        const stats = data.stats.reduce((acc, cur) => {
          acc[cur.name.replace(/ /g, "").toLowerCase()] = cur.count;
          return acc;
        }, {});
        setStatData(stats);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <img
          className="h-16 w-16"
          src="../../assets/admin-panel/loading.gif"
          alt="loading"
        />
      </div>
    );
  }

  return (
    <section className="grid grid-cols-5 gap-5 my-10">
      <Card title="Completed Tasks" count={statData?.totalapprovedblogs} />
      <Card title="Declined Tasks" count={statData?.totaldeclinedblogs} />
      <Card title="Pending Tasks" count={statData?.totalpendingtask} />
      <Card title="Draft Tasks" count={statData?.totaldraftblogs} />
      <Card title="Review Tasks" count={statData?.totalreviewtask} />
    </section>
  );
};

// Card component to display statistics
const Card = ({ title, count }) => {
  const navigate = useNavigate();

  const handleItemClick = (itemName) => {
    switch (itemName) {
      case "Completed Tasks":
        navigate("/content-writer/completed-task");
        break;
      case "Declined Tasks":
        navigate("/content-writer/declined-task");
        break;
      case "Pending Tasks":
        navigate("/content-writer/pending-task");
        break;
      case "Draft Tasks":
        navigate("/content-writer/draft-task");
        break;
      case "Review Tasks":
        navigate("/content-writer/reviewed-task");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="ring-1 bg-white rounded-xl shadow-md hover:shadow-2xl p-5 cursor-pointer"
      onClick={() => handleItemClick(title)}
    >
      <p className="text-base text-gray-500">{title}</p>
      <p className="text-3xl font-semibold">{count}</p>
    </div>
  );
};

export default CmStats;
