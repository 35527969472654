import React, { useState, useEffect } from "react";
import { getToken } from "../../../hook/getToken";
import toast from "react-hot-toast";

const CompanyAddress = () => {
  const [editedDetails, setEditedDetails] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      const accessToken = getToken();
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/company-details/get`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch company details");
      }

      const responseData = await response.json();
      setEditedDetails(responseData.data.map((detail) => ({ ...detail })));
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditCompany = (index) => {
    setFormData(editedDetails[index]);
    setIsEditing(true);
    setEditingIndex(index);
  };

  const handleSaveCompany = async () => {
    try {
      const accessToken = getToken();
      const url = isEditing
        ? `${process.env.REACT_APP_URL}/v1/company-details/edit/${editedDetails[editingIndex]._id}`
        : `${process.env.REACT_APP_URL}/v1/company-details/add`;

      // Exclude fields that are not allowed in the request body
      const { _id, createdAt, updatedAt, __v, ...formDataToSend } = formData;

      const response = await fetch(url, {
        method: isEditing ? "POST" : "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formDataToSend),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to ${isEditing ? "edit" : "add"} company details`
        );
      }

      toast.success(
        `Successfully ${isEditing ? "edited" : "added"} company details`
      );
      setIsEditing(false);
      fetchCompanyDetails();
    } catch (error) {
      console.error(
        `Error ${isEditing ? "editing" : "adding"} company details:`,
        error
      );
    }
  };

  const handleToggleActive = async (companyId, isActive) => {
    try {
      const accessToken = getToken();
      const url = `${process.env.REACT_APP_URL}/v1/company-details/status-update/${companyId}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ isActive: !isActive }),
      });

      if (response.ok) {
        toast.success(
          `Successfully ${isActive ? "deactivated" : "activated"} company`
        );
        fetchCompanyDetails();
      } else {
        throw new Error(
          `Failed to ${isActive ? "deactivate" : "activate"} company`
        );
      }
    } catch (error) {
      console.error("Error toggling company status:", error);
    }
  };

  return (
    <>
      <h1 className="text-2xl pt-8 pb-8 text-center">
        Company Address Management
      </h1>

      <div className="flex justify-center">
        <div className="max-w-lg w-full">
          {editedDetails.map((company, index) => (
            <div
              key={company._id}
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <h2 className="text-xl mb-4 font-bold">Company Details</h2>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Company Name:
                </label>
                <input
                  type="text"
                  name="company_name"
                  value={
                    isEditing && editingIndex === index
                      ? formData.company_name
                      : company.company_name
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Phone 1:
                </label>
                <input
                  type="text"
                  name="phone1"
                  value={
                    isEditing && editingIndex === index
                      ? formData.phone1
                      : company.phone1
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Phone 2:
                </label>
                <input
                  type="text"
                  name="phone2"
                  value={
                    isEditing && editingIndex === index
                      ? formData.phone2
                      : company.phone2
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email 1:
                </label>
                <input
                  type="text"
                  name="email1"
                  value={
                    isEditing && editingIndex === index
                      ? formData.email1
                      : company.email1
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email 2:
                </label>
                <input
                  type="text"
                  name="email2"
                  value={
                    isEditing && editingIndex === index
                      ? formData.email2
                      : company.email2
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Address Line 1:
                </label>
                <input
                  type="text"
                  name="address_line1"
                  value={
                    isEditing && editingIndex === index
                      ? formData.address_line1
                      : company.address_line1
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Address Line 2:
                </label>
                <input
                  type="text"
                  name="address_line2"
                  value={
                    isEditing && editingIndex === index
                      ? formData.address_line2
                      : company.address_line2
                  }
                  onChange={handleInputChange}
                  readOnly={!isEditing || editingIndex !== index}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex items-center justify-between">
                {!isEditing || editingIndex !== index ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => handleEditCompany(index)}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleSaveCompany}
                  >
                    Save
                  </button>
                )}
                <label className="cursor-pointer">
                  <input
                    type="checkbox"
                    checked={company.isActive}
                    onChange={() =>
                      handleToggleActive(company._id, company.isActive)
                    }
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-gray-700">Active</span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CompanyAddress;
