import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Reorder } from 'framer-motion'

const ManageContentModal = ({ isModalOpen, setIsModalOpen, componentType, data, updateAll, sliderIds }) => {
    const [items, setItems] = useState([])
    useEffect(() => {
        if (!sliderIds) return
        const prevItems = sliderIds.map(d => d.id)
        setItems(prevItems)
    }, [sliderIds])

    if (!isModalOpen) return null

    const handleChange = (d) => {
        setItems(prevItems => {
            // If the item is already in the list, remove it (uncheck), else add it (check)
            if (prevItems.includes(d._id)) {
                return prevItems.filter(item => item !== d._id)
            } else {
                return [...prevItems, d._id]
            }
        })
    }

    const getName = (id) => {
        const result = data?.find(d => d._id == id);
        return result ? result.name : null;
    }
    const handleDone = () => {
        if (items.length < 5) {
            return toast.error('Minimum Allowed is 5')
        }
        const updateList = items.map(d => ({ id: d }));
        updateAll(updateList);
        setIsModalOpen(false)
    };
    const handleSelectAll = () => {
        if (items.length === data.length) {
            setItems([]) // Deselect all
        } else {
            const all = data.map(d => d._id) // Select all
            setItems(all)
        }
    }
    const isAllSelected = data?.length > 0 && items.length === data.length

    return (
        <div className='fixed z-40 inset-0 bg-black bg-opacity-30 flex justify-center items-center '>
            <div className='flex flex-col w-[600px] h-[26rem] bg-white rounded-lg  overflow-hidden'>
                <div className='flex justify-between items-center bg-gray-50 px-2 mb-1'>
                    <span className='font-medium text-xl'>Manage {componentType}</span>
                    <span
                        onClick={() => setIsModalOpen(false)}
                        className='p-2 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                </div>
                <div className='grid grid-cols-2 gap-2 h-80 px-5'>
                    <div className='border-r h-full space-y-1 overflow-y-scroll overflow-container'>
                        <div className='flex items-center space-x-2'>
                            <input
                                type='checkbox'
                                checked={isAllSelected}
                                onChange={() => handleSelectAll()}
                            />
                            <label>All</label>
                        </div>
                        {data?.map(d => (
                            <div key={d._id} className='flex items-center space-x-2'>
                                <input
                                    type='checkbox'
                                    checked={items.includes(d._id)}
                                    onChange={() => handleChange(d)}
                                />
                                <label>{d.name}</label>
                            </div>
                        ))}
                    </div>
                    <div className='overflow-container overflow-y-scroll'>
                        <Reorder.Group values={items} onReorder={setItems}>
                            {
                                items?.map((item, index) => (
                                    <Reorder.Item value={item} key={item}>
                                        <div className='flex justify-between items-center shadow p-1'>
                                            <div>
                                                <span className='mr-2'>{index + 1}</span>  <span> {getName(item)}</span>
                                            </div>
                                            <span className='cursor-grabbing '>
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                                            </span>
                                        </div>
                                    </Reorder.Item>
                                ))
                            }
                        </Reorder.Group>
                    </div>
                </div>
                <div className='flex justify-end items-center mt-2 px-5'>
                    <button
                        type='button'
                        onClick={() => handleDone()}
                        className='py-1 px-12 bg-blue-500 rounded-lg text-white'>Done</button>
                </div>
            </div>
        </div>
    )
}

export default ManageContentModal
