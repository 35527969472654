import React, { useState } from 'react';
import { getToken } from '../../hook/getToken';
import toast from 'react-hot-toast';

const DeclineReasonModal = ({ visible,id,onClose }) => {
    const token = getToken();
    const [reason, setReason] = useState('');

    const handleDeclineBlog = async () => {
        try {
            // Send the decline request with the blog ID and reason in the request body
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/content-head/blog/decline/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    reason: reason, // Include the reason from the text area
                }),
            });
            const data = await response.json()
            if (response.ok) {
                toast.success(data.message);
                onClose()
            } else {
                throw new Error('Failed to decline blog');
            }

            // Update UI or perform any necessary actions after declining the blog
        } catch (error) {
            console.error('Error declining blog:', error);
            toast.error('Error declining blog');
        }
    };

    return (
        <div id="container" className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
            <div className="w-1/2 bg-white p-5 rounded-xl">
                <h2 className="text-xl font-bold mb-4">Decline Blog</h2>
                
                <div className="mb-4">
                    <textarea
                        className="w-full h-24 border p-2"
                        placeholder="Enter the reason for declining the blog..."
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </div>
                <div className="flex gap-x-4 justify-center items-center">
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={handleDeclineBlog}
                >
                    Submit Decline Reason
                </button>
                <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={onClose}
                >
                    Close
                </button>
                </div>
            </div>
        </div>
    );
};

export default DeclineReasonModal;
